import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { STATE } from '../../constants';
import { ApiGet, ApiPost, ApiPut } from '../../helper/API/ApiData';
import { userPermission } from '../../helper/permission';
import { RootState } from '../../redux/store';
import { PERMISSION_TYPE } from './../../helper/permission';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import permissionData from '../../permission.json'
import { decodeToken } from '../../helper/util';
function Dashboard() {
    const [activeTab, setActiveTab] = useState("pending");
    const [state, setState] = useState(STATE.DEFAULT_STATE);
    const [loader, setLoader] = useState(true);
    const [permission, setPermission] = useState<any>({});
    const [countData, setCountData] = useState<any>([]);
    const user = useSelector((state: RootState) => state?.authUser?.userData?.user);
    const [totalRecords, setTotalRecords] = useState(0);
    const [dashboardData, setDashboardData] = useState<any>();
    const [searchValue, setSearchValue] = useState("");
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [paymentStatuses, setPaymentStatuses] = useState(null);
    const [financers, setFinancers] = useState(null);
    const [dsaName, setDsaNames] = useState(null);
    const [products, setProducts] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: "",
        filters: {
      Bank_Name: { value: [], matchMode: FilterMatchMode.IN },
      DSA: { value: [], matchMode: FilterMatchMode.IN },
      Product: { value: [], matchMode: FilterMatchMode.IN },
      Disburesement_Month: { value: [], matchMode: FilterMatchMode.IN },
      Payment_Date:{ value: [], matchMode: FilterMatchMode.IN },
      Status:{ value: [], matchMode: FilterMatchMode.IN }
    },
    });
    const [userPayload,setUserPayload] = useState(null);

    
    const getCountData = async () => {
        const tempPermission = {
            [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
        }
        setPermission(tempPermission);
        await ApiGet(`dashboard/get-dashboard-statistics`)
            .then((res: any) => {
                setCountData(res?.data?.result[0] ?? [])
            }).catch((error) => {
                setCountData([])
            })
    }

    const getDashboardData = async () => {
        const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
        delete modifiedLazyState.rows;
        delete modifiedLazyState['multiSortMeta'];
        delete modifiedLazyState['sortField'];
        delete modifiedLazyState['sortOrder'];

        // Function to replace empty arrays in the "value" field with null
        const replaceEmptyValueArrays = (obj) => {
            for (const key in obj) {
                if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                    obj[key] = null;
                } else if (typeof obj[key] === 'object') {
                    replaceEmptyValueArrays(obj[key]);
                }
            }
            return obj;
        };

        // Call the function to replace empty arrays in the "value" field with null
        const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
        finalModifiedLazyState.page++;
        if (userPayload?.user?.role=='admin') {
            await ApiPost(`dashboard/get-admin-dashboard`, finalModifiedLazyState)
                .then((res: any) => {
                    setTotalRecords(Number(res.data.count[0].count));
                    let temp = res.data.result.map((item: any, index: number) => {
                        return {
                            id: item?._id,
                            SRNO: (lazyState.first) + index + 1,
                            Borrower_Name: item?.borrower_name ?? "-",
                            Loan_Account_Number: item?.lan_no ?? "-",
                            Bank_Name: item?.bank_name ?? "-",
                            Product: item?.product ?? "-",
                            ProductId: item?.product?._id ?? "",
                            Disburesement_Month: item?.disbursement_date ? item?.disbursement_date : "-",
                            Loan_Amount: item?.loan_amount ?? "-",
                            Location: (item?.city && item?.state) ? `${item?.city} - ${item?.state}` : '-',
                            Contact_Person: item?.borrower_contact_person_name ?? "-",
                            Mobile: item?.borrower_contact_person_number ?? "-",
                            DSA: item?.dsa_name ?? "-",
                            Payout_dsa: item?.rate ?? "-",
                            Gross_Payout: item?.gross_payout ?? "-",
                            Adjustment: item?.adjustment_amount ?? "-",
                            Less_TDS: item?.tds_amount ?? "-",
                            Add_GST: item?.gst_amount ?? "-",
                            Net_Amount_Payable: item?.net_amount_payable ?? "-",
                            Connector_name: item?.connector_name ?? "-",
                            Payout_Connectore: item?.connector_rate ?? 0,
                            Gross_Payout_Amount: item?.connector_gross_payout ?? 0,
                            Less_TDS_Connector: item?.connector_tds_amount ?? 0,
                            Add_GST_Connector: item?.connector_gst_amount ?? 0,
                            Net_Amount_Payable_Connector: item?.connector_net_amount_payable ?? 0,
                            Status: item?.status ?? "-",
                            BankPayout: item?.bank_payout ?? '-',
                            Payment_Date: item?.pymt_date ? item?.pymt_date : "-",
                            Utr_no: item?.utr_no ?? "-",
                            subvention:item.subvention,
                            co_status:item?.co_status,
                        }
                    });

                    setDashboardData(temp);
                    const tempPermission = {
                        [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
                    }
                    setPermission(tempPermission)
                }).catch((error) => {
                    setDashboardData([])
                    const tempPermission = {
                        [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
                    }
                    setPermission(tempPermission);
                });
        } else if(userPayload){
            await ApiPost(`dashboard/get-staff-dsa-dashboard?status=${activeTab}`, finalModifiedLazyState)
                .then((res: any) => {
                    setTotalRecords(Number(res.data.count[0].count));
                    let temp = res.data.result.map((item: any, index: number) => {
                        return {
                            id: item?._id,
                            SRNO: (lazyState.first) + index + 1,
                            Case_Name: item?.borrower_name ?? "-",
                            Loan_Amount: item?.loan_amount ?? "-",
                            Bank_Name: item?.bank_name ?? "-",
                            Product: item?.product ?? "-",
                            Gross_Payout: item?.gross_payout ?? "-",
                            Utr_no: item?.utr_no ? item?.utr_no : "-",
                            Status: item?.status ?? "-",
                            Connector_Gross_payout: item?.connector_gross_payout,
                            subvention:item.subvention,
                        }
                    });
                    // console.log('Mapped Data:', temp);
                    setDashboardData(temp);
                  
                    const tempPermission = {
                        [PERMISSION_TYPE.DSA]: userPermission(user, PERMISSION_TYPE.DSA),
                        [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(user, PERMISSION_TYPE.BOOKING_PARTNER),

                    }
                    setPermission(tempPermission)

                    setState({
                        ...state,
                        totalItems: res.data.count[0][0].count
                    })
                }).catch((error) => {
                    // setDashboardData([])
                    const tempPermission = {
                        [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
                    }
                    setPermission(tempPermission)
                });
        }
    }

    const convertToObjectArray = (obj) => {
        return Object.keys(obj).map((key) => ({
          label: obj[key]?.toString(),
          value: key,
        }));
      };
    const getOptionsData = () => {
        setLoader(true);
        ApiGet(`options/disbursement`).then((res: any) => {
          const financers = convertToObjectArray(res.data.financers);
          const products = convertToObjectArray(res.data.products);
          const dsaName = convertToObjectArray(res.data.dsa_name);
          const paymentStatuses = convertToObjectArray(res.data.payment_status);

          setFinancers(financers);
          setDsaNames(dsaName);
          setProducts(products);
          setPaymentStatuses(paymentStatuses);

        });
      };
    
    const onPage = (event) => {
        let filter = { ...lazyState };
        filter = event;
        filter["search"] = lazyState.search;
        setlazyState(filter);
    };

    const financersFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options?.value || []}
            options={financers || []}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
      const dsaNameFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options.value || []}
            options={dsaName}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
    
      const paymentStatusesFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options?.value || []}
            options={paymentStatuses}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
      const productsFilterTemplate = (options) => {
        return (
          <MultiSelect
            value={options?.value || []}
            options={products || []}
            onChange={(e) => options.filterCallback(e.value)}
            optionLabel="label"
            placeholder="Select"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: "14rem" }}
            filter
            filterPlaceholder="Search"
          />
        );
      };
      const months = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ];
      const monthMap: Record<string, string> = {};
        months.forEach((month) => {
            monthMap[month.value] = month.label;
        });
    
        // Generate an array of years from 2020 to 2030
        const years = Array.from({ length: 11 }, (_, index) => {
            const yearValue = 2020 + index;
            return { value: yearValue, label: yearValue.toString() };
        });
    
       
      const DismonthFilterTemplate = (options) => {
        
        const years=[];
        let currentYear;
        let currentMonth = new Date().getMonth();
                  if(currentMonth <= 6){
                     currentYear = new Date().getFullYear();
                  }
                  else{
                     currentYear = new Date().getFullYear()+1;
                  }  
                  for (let i = currentYear; i >= 2018; i--) {
                    years.push({ label: i.toString(), value: i });
                  }  
    
        const onApplyFilter = () => {
          options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
        };
    
        const onClearFilter = () => {
          setSelectedMonth(null);
          setSelectedYear(null);
          options.filterApplyCallback({ month: null, year: null });
        };
    
        const isBothSelected = selectedMonth && selectedYear;
        return (
          <div style={{ minWidth: "14rem" }}>
            <div className="mb-2">
              <MultiSelect
                optionLabel="label"
                optionValue="value"
                value={selectedMonth}
                options={months}
                onChange={(e) =>{ 
                  
                  setSelectedMonth(e.value)}}
                placeholder="Select Month"
                className="p-column-filter"
                filter
              />
            </div>
            <div className="mb-2">
              <MultiSelect
                optionLabel="label"
                optionValue="value"
                value={selectedYear}
                options={years}
                onChange={(e) => setSelectedYear(e.value)}
                placeholder="Select Year"
                className="p-column-filter"
                filter
              />
            </div>
            <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
              <Button
                label="Clear"
                onClick={onClearFilter}
                className="p-button p-component p-button-outlined p-button-sm"
              />
              <Button
                label="Apply"
                onClick={onApplyFilter}
                className="p-button p-component p-button-sm"
                disabled={!isBothSelected}
              />
            </div>
          </div>
        );
      };

      const PaymentDateFilterTemplate = (options) => {
        
        const years=[];
        let currentYear;
        let currentMonth = new Date().getMonth();
                  if(currentMonth <= 6){
                     currentYear = new Date().getFullYear();
                  }
                  else{
                     currentYear = new Date().getFullYear()+1;
                  }  
                  for (let i = currentYear; i >= 2018; i--) {
                    years.push({ label: i.toString(), value: i });
                  }  
    
        const onApplyFilter = () => {
          options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
        };
    
        const onClearFilter = () => {
          setSelectedMonth(null);
          setSelectedYear(null);
          options.filterApplyCallback({ month: null, year: null });
        };
    
        const isBothSelected = selectedMonth && selectedYear;
        return (
          <div style={{ minWidth: "14rem" }}>
            <div className="mb-2">
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedMonth}
                options={months}
                onChange={(e) =>{ 
                  
                  setSelectedMonth(e.value)}}
                placeholder="Select Month"
                className="p-column-filter"
                filter
              />
            </div>
            <div className="mb-2">
              <Dropdown
                optionLabel="label"
                optionValue="value"
                value={selectedYear}
                options={years}
                onChange={(e) => setSelectedYear(e.value)}
                placeholder="Select Year"
                className="p-column-filter"
                filter
              />
            </div>
            <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
              <Button
                label="Clear"
                onClick={onClearFilter}
                className="p-button p-component p-button-outlined p-button-sm"
              />
              <Button
                label="Apply"
                onClick={onApplyFilter}
                className="p-button p-component p-button-sm"
                disabled={!isBothSelected}
              />
            </div>
          </div>
        );
      };
      const renderHeader = () => {
        return (
          <div className="flex justify-end justify-space-between">           
              <span>
                <InputText
                  className="mr-2 ml-3"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Keyword Search"
                />
                <Button icon="pi pi-search" onClick={handleSearchClick} />
              </span>
            </div>
        );
      };
    
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          onGlobalFilterChange(searchValue);
        }
      };
    
      const handleSearchClick = () => {
        onGlobalFilterChange(searchValue);
      };
    
      const handleInputChange = (event) => {
        setSearchValue(event.target.value);
      };
    
      const onGlobalFilterChange = (e) => {
        const value = e;
        let filter = { ...lazyState };
    
        filter["search"] = value;
    
        setlazyState(filter);
      };
    
      const header = renderHeader();
      const onFilter = (event) => {
        event["first"] = 0;
        event["page"] = 0;
        const dismonthValue = event.filters?.Disburesement_Month?.value;
        const pyt_dtmonthValue = event.filters?.Payment_Date?.value;

        if (
          dismonthValue &&
          dismonthValue.month === null &&
          dismonthValue.year === null
        ) {
          event.filters.Disburesement_Month.value = null;
        }

        if (
          pyt_dtmonthValue &&
          pyt_dtmonthValue.month === null &&
          pyt_dtmonthValue.year === null
        ) {
          event.filters.Payment_Date.value = null;
        }
    
        setlazyState(event);
      };

      useEffect(() => {
        setUserPayload(decodeToken(localStorage.getItem('token')));
    }, []);

    useEffect(() => {
        Promise.all([
            getCountData(),
            getDashboardData(),
            getOptionsData(),
        ]).then(() => {
            setTimeout(() => { setLoader(false) }, 250)
        }).catch((error) => {
            setLoader(false)
        })
    }, [lazyState, activeTab,userPayload]);

    return (
        <div className="overflow-x-hidden p-6">
            {/* START::MAIN CONTENT */}
            <div className="p-4 sm:p-9 space-y-5">
                {/* START::WIDGET */}
                {!(userPayload?.user?.role =='connector') && <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4">
                    <div className="dashboard-widget">
                        <h4 className="text-2xl text-[#CC99C9]">{countData?.total_loan_amount_current_month ? countData?.total_loan_amount_current_month : 0}</h4>
                        <p className="text-xs text-light-gray">Total Loan Amount Current Month</p>
                    </div>
                    <div className="dashboard-widget">
                        <h4 className="text-2xl text-[#9EC1CF]">{countData?.total_loan_amount_last_month ? countData?.total_loan_amount_last_month : 0}</h4>
                        <p className="text-xs text-light-gray">Total Loan Amount Last Month</p>
                    </div>
                    <div className="dashboard-widget">
                        <h4 className="text-2xl text-[#9EE09E]">{countData?.total_loan_amount_last_quater ? countData?.total_loan_amount_last_quater : 0}</h4>
                        <p className="text-xs text-light-gray">Total Loan Amount Last Quarter</p>
                    </div>
                    <div className="dashboard-widget">
                        <h4 className="text-2xl text-[#D5D538]">{countData?.total_records_current_month ? countData?.total_records_current_month : 0}</h4>
                        <p className="text-xs text-light-gray">Total Records Current Month</p>
                    </div>
                    <div className="dashboard-widget">
                        <h4 className="text-2xl text-[#FEB144]">{countData?.total_records_last_month ? countData?.total_records_last_month : 0}</h4>
                        <p className="text-xs text-light-gray">Total Records Last Month</p>
                    </div>
                    <div className="dashboard-widget">
                        <h4 className="text-2xl text-[#FF6663]">{countData?.total_records_last_quater ? countData?.total_records_last_quater : 0}</h4>
                        <p className="text-xs text-light-gray">Total Records Last Quarter</p>
                    </div>
                </div>}
                {/* END::WIDGET */}

                {/* START::DASHBOARD HEADER */}
                <div className="flex flex-wrap gap-5 items-center justify-between">
                    <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                        <h1 className="text-[22px] text-light-gray font-semibold">{(userPayload?.user?.role=='admin') ? "Master MIS" : "Dashboard"}</h1>
                        {!(userPayload?.user?.role=='admin') &&
                            <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "pending" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("pending")}>Payment Pending</button>
                                </li>
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "mismatch" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("mismatch")}>Re-Submission Case</button>
                                </li>
                                <li>
                                    <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "completed" && "bg-white rounded-[18px] text-primary"}`} onClick={() => setActiveTab("completed")}>Payment Completed</button>
                                </li>
                            </ul>
                        }
                    </div>
                    {/* END::DASHBOARD HEADER */}
                </div>
            </div>

            <>
                {
                     userPayload?.user?.role=='admin' ? (
                        <DataTable
                            value={dashboardData}
                            lazy
                            showGridlines
                            paginator
                            first={lazyState.first}
                            filters={lazyState.filters}
                            onFilter={onFilter}

                            rows={lazyState.rows}
                            rowsPerPageOptions={[10, 20, 30, 40, 50]}
                            totalRecords={totalRecords}
                            loading={loader}
                            scrollable
                            header={header}
                            size={'small'}
                            onPage={onPage}
                            scrollHeight="600px"
                            className="custom-datatable"
                            emptyMessage="No records found."
                        >
                            <Column field="SRNO" header="SR.NO." frozen />
                            <Column field="Borrower_Name" header="Borrower Name" frozen />
                            <Column field="Loan_Account_Number" header="Loan Account Number" />
                            <Column field="Bank_Name" header="Bank/NBFC Name" 
                            showFilterMatchModes={false}
                            filterMenuStyle={{ minWidth: "14rem" }}
                            filter
                            filterElement={financersFilterTemplate}
                            />
                            <Column field="Product" header="Product" 
                            showFilterMatchModes={false}
                            filterMenuStyle={{ minWidth: "14rem" }}
                            filter
                            filterElement={productsFilterTemplate}
                            />
                            <Column field="Disburesement_Month" header="Disbursement Month" 
                            showFilterMatchModes={false}
                            showApplyButton={false}
                            showClearButton={false}
                            filterMenuStyle={{ minWidth: "14rem" }}
                            filter
                            filterElement={DismonthFilterTemplate}
                            />
                            <Column field="Loan_Amount" header="Loan Amount" />
                            <Column field="Location" header="Location" />
                            <Column field="Contact_Person" header="Contact Person Name" />
                            <Column field="Mobile" header="Mobile Number" />
                            <Column field="DSA" header="DSA Name"                             
                            showFilterMatchModes={false}
                            filterMenuStyle={{ minWidth: "14rem" }}
                            filter
                            filterElement={dsaNameFilterTemplate}/>
                            <Column field="Payout_dsa" header="Payout in %(DSA)" />
                            <Column field="Adjustment" header="Adjustment +/-" />
                            <Column field='subvention' header='Subvention'></Column>
                            <Column field="Gross_Payout" header="Gross Payout in Amount(DSA)" />
                            <Column field="Less_TDS" header="Less:TDS @5%(DSA)" />
                            <Column field="Add_GST" header="Add:GST @18%(DSA)" />
                            <Column field="Net_Amount_Payable" header="Net Amount Payable (DSA)" />
                            <Column field="Connector_name" header="Connector Name" />
                            <Column field="Payout_Connectore" header="Payout in % (Connector)" />
                            <Column field="Gross_Payout_Amount" header="Gross Payout in Amount (Connector)" />
                            <Column field="Less_TDS_Connector" header="Less:TDS @5%(Connector)" />
                            <Column field="Add_GST_Connector" header="Add:GST @18%(Connector)" />
                            <Column field="Net_Amount_Payable_Connector" header="Net Amount Payable (Connector)" />
                            <Column field="Status" header="Status" 
                            showFilterMatchModes={false}
                            filterMenuStyle={{ minWidth: "14rem" }}
                            filter
                            filterElement={paymentStatusesFilterTemplate}
                            body={(rowData) => {
                              let color = 'black';
                              // console.log(rowData.co_status,typeof rowData.co_status);
                              switch(rowData.Status){
                                case "Completed":
                                  color = "green";
                                  break
                                case "Advance Completed":
                                    if (rowData.co_status===0) {
                                      console.log('hello');
                                      color = "purple";
                                    }else{
                                        color = "red";
                                      }
                                  break;
                                case "Billing Received":
                                  color = "blue";
                                  break;
                                case "Mismatch Case":
                                  color = "#E65100";
                                  break
                                case "Payment Pending":
                                  color = "#FBC02D";
                                  break
                              }
                              return <span style={{ color }}>{rowData.Status}</span>;
                            }}
                            />
                            <Column field="BankPayout" header="Bank Payout" />
                            <Column field="Payment_Date" header="Payment Date" 
                            showFilterMatchModes={false}
                            showApplyButton={false}
                            showClearButton={false}
                            filterMenuStyle={{ minWidth: "14rem" }}
                            filter
                            filterElement={PaymentDateFilterTemplate}
                            />
                            <Column field="Utr_no" header="	UTR No" />
                        </DataTable>
                    )
                        :
                        (
                            <DataTable
                                value={dashboardData}
                                lazy
                                showGridlines
                                paginator
                                filters={lazyState.filters}
                                onFilter={onFilter}
                                header={header}
                                first={lazyState.first}
                                rows={lazyState.rows}
                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                totalRecords={totalRecords}
                                loading={loader}
                                scrollable
                                size={'small'}
                                onPage={onPage}
                                scrollHeight="600px"
                                className="custom-datatable"
                                emptyMessage="No records found."
                            >
                                <Column field="SRNO" header="SR.NO." />
                                <Column field="Case_Name" header="Borrower Name" />
                                {
                                    activeTab === 'mismatch'
                                        ?
                                        <Column field="Billing_Status" header="Billing Status" />
                                        :
                                        null
                                }
                                <Column field="Loan_Amount" header="Loan Amount" />
                                <Column field="Bank_Name" header="Bank/NBFC Name" 
                                showFilterMatchModes={false}
                                filterMenuStyle={{ minWidth: "14rem" }}
                                filter
                                filterElement={financersFilterTemplate}
                                />
                                <Column field="Product" header="Product" 
                                showFilterMatchModes={false}
                                filterMenuStyle={{ minWidth: "14rem" }}
                                filter
                                filterElement={productsFilterTemplate}
                                />
                                {/* <Column field='subvention' header='Subvention'></Column> */}
                                {!(userPayload?.user?.role=='connector') && <Column field="Gross_Payout" header="Gross Payout" />}
                                { (userPayload?.user?.role=='connector') && <Column field="Connector_Gross_payout" header="Gross Payout" />}
                                {
                                    activeTab === 'completed'
                                        ?
                                        <Column field="Utr_no" header="UTR NO" />
                                        :
                                        null
                                }
                                <Column field="Status" header=" Payment Status" />
                            </DataTable>
                        )
                }
            </>
        </div >
    )
}

export default Dashboard;
