import {
  Checkbox,
  TableCell,
  TableRow,
  Tooltip,
  TextField,
  Stepper,
} from "@mui/material";
import React, { useRef, useState } from "react";
import TableComponent from "../../components/Tables";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { STATE } from "../../constants";
import axios from "axios";
import _ from "lodash";
import { decodeToken, isEmpty, numberFormat } from "../../helper/util";
import { getSlabValue } from "./../../helper/util";
import STORAGEKEY from "../../config/APP/app.config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Logout_Auth } from "../../redux/reducers/authReducer";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import CsvDownload from "react-json-to-csv";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Padding } from "@mui/icons-material";
import { ConfirmDialog } from "primereact/confirmdialog";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { RadioButton } from "primereact/radiobutton";
import moment from 'moment'
import Select from 'react-select';
import { OverlayPanel } from "primereact/overlaypanel";
import { StepperPanel } from 'primereact/stepperpanel';
import { toWords } from 'number-to-words';
import {ToWords} from 'to-words';
import { format, parseISO } from 'date-fns';
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
// import permissionData from '../../permission.json'
const DisbursementData = () => {
  const disbursementData = {
    borrower_name: "",
    lan_no: "",
    // pan_no: "",
    borrower_contact_person_name: "",
    loan_amount: "",
    borrower_pincode: "",
    logincodelist_id: "",
    financer_name: "",
    // product: "",
    disbursement_date: new Date(),
    borrower_contact_person_number: "",
    dsa_name_error:null,
    borrower_name_error: null,
    lan_no_error: null,
    loan_amount_error: null,
    pincode_error: null,
    financer_name_error: null,
    product_error: null,
    // pan_no_error: null,
    contact_person_name_error: null,
    month_of_disbursement_error: null,
    mobile_error: null,
    connector_id: null,
    user_id: '',
    is_connector: false,
    Userpayout: 0,
    connector_rate: null,
    connector_error: null,
    connector_rate_error: null,
    adjustment_amount: 0,
    reason: '',
    reason_error:null,
    state_error: null,
    city_error: null,
    state: "",
    city: "",
  };
  const data={
    // month:'',
    // year: '',
            file_sequence_num:'',
            // pymt_prod_type_code: '',
            // pymt_mode: '',
            // debit_acct_no: '',
            // beneficiary_name: '',
            // beneficiary_account_no: '',
            // bene_ifsc_code: '',
            amount: '',
            // debit_narration: '',
            // credit_narration: '' ,
            // mobile_number: '',
            // email_id: '',
            // remark: '',
            pymt_date: new Date(),
            // addl_info1: '',
            // status: '',
            // current_step: '',
            // file_name: '',
            acct_debit_date: new Date(),
            customer_ref_no: '',
            // utr_no: '',
            // month_error:null,
            // year_error:null,
            file_sequence_num_error:null,
            // pymt_prod_type_code_error:null,
            // pymt_mode_error:null,
            // debit_acct_no_error:null,
            // beneficiary_name_error:null,
            // beneficiary_account_no_error:null,
            // bene_ifsc_code_error:null,
            amount_error:null,
            // debit_narration_error:null,
            // credit_narration_error:null ,
            // mobile_number_error:null,
            // email_id_error:null,
            // remark_error:null,
            pymt_date_error:null,
            // addl_info1_error:null,
            // status_error:null,
            // current_step_error:null,
            // file_name_error:null,
            acct_debit_date_error:null,
            customer_ref_no_error:null,
            // utr_no_error:null

  };
  const[userPayload,setUserPayload] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const [loader, setLoader] = useState(true);
  const [invoiceloader, setInvoiceLoader] = useState(true);

  const [isconnector, setConnector] = useState(false);
  const [connectorList, setConnectorList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [activeTab, setActiveTab] = useState("disbursement");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");

  const [formData, setFormData] = useState<any>(disbursementData);
  const [paymentForm,setPaymentForm]=useState<any>(data);
  const [disbursementDetail, setDisbursementDetail] = useState([]);
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [l1l2Detail, setL1L2Detail] = useState([]);
  const [stateLevel, setStateLevel] = useState(STATE.DEFAULT_STATE);
  const [misMatchDetail, setMisMatchDetail] = useState([]);
  const [stateMisMatch, setStateMisMatch] = useState(STATE.DEFAULT_STATE);
  const [bankData, setBankData] = useState<any>({});
  const [monthData, setMonthData] = useState<string[]>([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [permission, setPermission] = useState<any>({});
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [selectedFile, setSelectedFile] = useState<File | string>();
  const [getAllData, setGetAllData] = useState<any>([]);
  const [getAccNo, setgetAccNo] = useState<any>();
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<any>({
    gross_payout: 0,
    product: "",
    bankname: "",
    payoutpercentage: 0,
  });
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [invoicetotalRecords, setInvoiceTotalRecords] = useState(0);

  const [advanceRequests, setAdvanceRequests] = useState(null);
  const [advanceStatuses, setAdvanceStatuses] = useState(null);
  const [financers, setFinancers] = useState(null);
  const [salesM, setSalesM] = useState(null);
  const [dsaName, setDsaNames] = useState(null);
  const [paymentStatuses, setPaymentStatuses] = useState(null);
  const [products, setProducts] = useState(null);
  const [disableField, setDisableField] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [paginator, setPaginator] = useState(true);
  const defaultLazyState ={
    first: 0,
    rows: 10,
    page: 0,
    pagination:'',
    search: "",
    filters: {
      Financer: { value: null, matchMode: FilterMatchMode.IN },
      DsaName: { value: null, matchMode: FilterMatchMode.IN },
      Product: { value: null, matchMode: FilterMatchMode.IN },
      dismonth: { value: null, matchMode: FilterMatchMode.IN },
      Paymentstatus: { value: null, matchMode: FilterMatchMode.IN },
      Advancerequest: { value: null, matchMode: FilterMatchMode.IN },
      Advancestatus: { value: null, matchMode: FilterMatchMode.IN },
      co_status: { value: null, matchMode: FilterMatchMode.IN },
      sales_name: { value: null, matchMode: FilterMatchMode.IN },

    },
  }
  const [lazyState, setlazyState] = useState(defaultLazyState);
  const [invoicelazyState, setInvoicelazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination:'',
    search: "",
    filters: {
        Created_at:{ value: null, matchMode: FilterMatchMode.IN },
        user_name: { value: null, matchMode: FilterMatchMode.IN },
        status:{ value: null, matchMode: FilterMatchMode.IN }    },
  });
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [selectedInvoiceMonth, setSelectedInvoiceMonth] = useState(null);
  const [selectedInvoiceYear, setSelectedInvoiceYear] = useState(null);
  const [invoiceStatusOptions, setInvoiceStatusOptions] =
    useState(null);
  const stepperRef = useRef(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [sendMailDialog, setSendMailDialog] = useState(false);
  let email_data={
    sm_manager_email:'',
    asm_manager_email:'',
    sm_manager_email_error:null,
    asm_manager_email_error:null,
  }
  const [emailData,setEmailData]=useState<any>(email_data)
  const op = useRef(null);
  const[exportType,setExportType]=useState('');
  const [approvePayment,setApprovePayment]=useState(false)
  const [viewProof,setViewProof]=useState(false)
  const [proofPath,setProofPath]=useState('')
  const [addInvoice,setAddInvoice]=useState(false)
  const [invoicDialog,setInvoiceDialog]=useState('dataform')
  let bankDisbursementFormData = {
    // user_id: null,
    selectedDate:new Date(),
    invoice_no: '',
    // user_id_error: null,
    selected_date_error: null,
    invoice_no_error: null,
    selectedMonth: "",
    selectedYear: "",
    selected_month_error: null,
    selected_year_error: null,
    firm_name:'',
    firm_name_error:null,
    address:"",
    address_error:null
};
    const [invoiceformData, setInvoiceFormData] = useState<any>(bankDisbursementFormData);
    const [invoicedisbursementDetail, setInvoiceDisbursementDetail] = useState([]);
    const [viewInvoice,setViewInvoice]=useState(false)
    const [invoicePath,setInvoicePath]=useState('')
    const[user,setUser]=useState(null);
    const toWords = new ToWords({
      localeCode: 'en-IN', // For Indian numbering format
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
      }
    });
  let date=new Date().toDateString();
  const [pdfData, setPdfData] = useState(null);
  const [otp, setOtp] = useState(null);
  const[bankName,setBankName]=useState('')
  const [dataModal,setDataModal]=useState(false);
  const [dsainfodata,setDsaInfoData]=useState(null);
  const [connectorinfodata,setConnectorInfoData]=useState(null);
    const location = useLocation();

  const [newdate,setDate]=useState(moment(date).format('DD-MM-YY'))
  useEffect(() => {
    if (!userPayload?.user?.profile_completed && userPayload?.user?.role === "dsa") {
      toast.error("Please validate your KYC before adding disbursement data.", {
        position: "top-right",
        theme: "colored",
      });
      navigate(`/`);
    }
    const tempPermission = {
      [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.DSA]: userPermission(userData, PERMISSION_TYPE.DSA),
      [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),

    };
    setPermission(tempPermission);
  }, [userData]);
  
  const getOptionsData = () => {
    setLoader(true);
    ApiGet(`options/disbursement`).then((res: any) => {
      const advanceRequests = convertToObjectArray(res.data.advance_request);
      const advanceStatuses = convertToObjectArray(res.data.advance_status);
      const financers = convertToObjectArray(res.data.financers);
      const paymentStatuses = convertToObjectArray(res.data.payment_status);
      const products = convertToObjectArray(res.data.products);
      const sales_name = convertToObjectArray(res.data.sales_name);

      setAdvanceRequests(advanceRequests);
      setAdvanceStatuses(advanceStatuses);
      setFinancers(financers);
      setPaymentStatuses(paymentStatuses);
      setProducts(products);
      setSalesM(sales_name)
    });
  };

  const getInvoiceCasesData = async () => {

          let error = { ...invoiceformData };


          if (invoiceformData.selectedMonth == "") {
            error = { ...error, selected_month_error: "Please choose a month" };
        }
        if (invoiceformData.selectedYear == "") {
            error = { ...error, selected_year_error: "Please choose a year" };
        }
        setInvoiceFormData({
          ...error
      });
      if (error.selected_month_error == null && error.selected_year_error == null && error.user_id_error == null && error.invoice_no_error == null) {              
      let payload={
        user_id: userPayload?.user?._id  ,
        month:invoiceformData.selectedMonth,
        year:invoiceformData.selectedYear
      }

                   ApiPost(`invoiceDetails/get-disbursement-invoice-data`, payload)
                      .then((res: any) => {
    let finalData = res.data.result.map((item: any, index: number) => {
      return {
        SRNO: (lazyState.first) + index + 1,
        id: item._id,              
        BorrowerName: item.borrower_name ?? "-",
        Loanamount: item.loan_amount ?? "-",
        Financer: item.bank_name ?? "-",
        Product: item?.product ?? "-",
        dismonth:ConvertDate(item?.disbursement_date) ?? "-",    
        ...item          
      };
    });
    setInvoiceDisbursementDetail(finalData);
    setUser(res.data.user)
    getBank(res.data.user.ifsc)
                  setInvoiceDialog('table')
                         
                      }).catch((error: any) => {
                          // toast.error(error?.error, {
                          //     position: "top-right",
                          //     theme: "colored"
                          // })
                      })

              
          }
      }
  const getInvoiceOptionsData = () => {
    if(userPayload?.user?.role=='dsa' || userPayload?.user?.role=='connector'){

    setLoader(true);
    ApiGet(`options/invoice`).then((res: any) => {
      const invoiceStatusOptions = convertToObjectArray(res.data.invoice_status);
  
      setInvoiceStatusOptions(invoiceStatusOptions);
    }).catch((error)=>{
      //  console.log(error)
    });
  };
}
  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key]?.toString(),
      value: key,
    }));
  };
  
  const getUser = () => {
    ApiGet(`user/option-users`)
      .then((res: any) => {
        const users = convertToObjectArray(res.data.users);

        setUserList(users);
        setDsaNames(users);
      })
      .catch((error) => {
        setUserList([]);
      });
  };

  const advanceRequestFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={advanceRequests}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const advanceStatusesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={advanceStatuses}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const financersFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={financers}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const dsaNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={dsaName}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const salesNameFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={salesM}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const paymentStatusesFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={paymentStatuses}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const productsFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={products}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const invoiceStatusFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options?.value || []}
        options={invoiceStatusOptions}
        onChange={(e) => options.filterCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };
  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  const monthMap: Record<string, string> = {};
    months.forEach((month) => {
        monthMap[month.value] = month.label;
    });

    // Generate an array of years from 2020 to 2030
    const years = Array.from({ length: 11 }, (_, index) => {
        const yearValue = 2020 + index;
        return { value: yearValue, label: yearValue.toString() };
    });

    const handleMonthChange = (selectedOption: any) => {
      setInvoiceFormData({
        ...invoiceformData,
        selectedMonth: selectedOption ? selectedOption.value : '',
        selected_month_error: null,
      });
    };

    const handleYearChange = (selectedOption: any) => {
      setInvoiceFormData({
        ...invoiceformData,
        selectedYear: selectedOption ? selectedOption.value : '',
        selected_year_error: null,
      });
    };
   
  const DismonthFilterTemplate = (options) => {
    
    const years=[];
    let currentYear;
    let currentMonth = new Date().getMonth();
              if(currentMonth <= 6){
                 currentYear = new Date().getFullYear();
              }
              else{
                 currentYear = new Date().getFullYear()+1;
              }  
              for (let i = currentYear; i >= 2018; i--) {
                years.push({ label: i.toString(), value: i });
              }  

    const onApplyFilter = () => {
      options.filterApplyCallback({ month: selectedMonth, year: selectedYear });
    };

    const onClearFilter = () => {
      setSelectedMonth(null);
      setSelectedYear(null);
      options.filterApplyCallback({ month: null, year: null });
    };

    const isBothSelected = selectedMonth && selectedYear;
    return (
      <div style={{ minWidth: "14rem" }}>
        <div className="mb-2">
          <MultiSelect
            optionLabel="label"
            optionValue="value"
            value={selectedMonth}
            options={months}
            onChange={(e) =>{ 
              
              setSelectedMonth(e.value)}}
            placeholder="Select Month"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="mb-2">
          <MultiSelect
            optionLabel="label"
            optionValue="value"
            value={selectedYear}
            options={years}
            onChange={(e) => setSelectedYear(e.value)}
            placeholder="Select Year"
            className="p-column-filter"
            filter
          />
        </div>
        <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
          <Button
            label="Clear"
            onClick={onClearFilter}
            className="p-button p-component p-button-outlined p-button-sm"
          />
          <Button
            label="Apply"
            onClick={onApplyFilter}
            className="p-button p-component p-button-sm"
            disabled={!isBothSelected}
          />
        </div>
      </div>
    );
  };

  const columns = [
    { id: "SRNO", label: "SR.NO." },
    userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation' || userPayload?.user?.role=='staff_sale'
      ? { id: 'sales_name', label: 'SM/BP Name' } : null,
      userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation' || userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner'
      ? { id: 'dsa_name', label: 'DSA Name' } : null,
    { id: "borrower_name", label: "Borrower Name" },
    { id: "borrower_contact_person_name", label: "Contact Person" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "bank_name", label: "Financer" },
    { id: "product", label: "Product" },
    { id: "disbursement_date", label: "Dis. Month" },
    { id: "connector_name", label: "Connector Name" },
    { id:`${userPayload?.user?.role=='connector' ? "connector_rate" : "rate"}`, label: "Grid Payout %" },
    userPayload?.user?.role=='connector' ?
     null :{ id: "connector_rate", label: "Connector Payout %" },
     userPayload?.user?.role=='connector' ?
     null :{ id: "adjusted_payout_per", label: "Adjusted Payout % " },
     userPayload?.user?.role=='connector' ?
     null :{ id: "adjustment_amount", label: "Adjustment Amount" },
     { id: "subvention_per", label: "Subvention %" },
     { id: "subvention", label: "Subvention" },
   { id: 'system_adjustment', label: 'System Adjustment' } ,
    { id: `${userPayload?.user?.role=='connector' ? "connector_gross_payout" :"gross_payout"}`, label: "Gross Payout" },
    { id:`${userPayload?.user?.role=='connector' ? "connector_tds_amount" :  "tds_amount"}`, label: "Tds Amount" },
    { id: `${userPayload?.user?.role=='connector' ? "connector_gst_amount" :"gst_amount"}`, label: "GST Amount" },
    { id:`${userPayload?.user?.role=='connector' ? "connector_net_amount_payable" :  "net_amount_payable"}`, label: "Net Payout" },
    { id: "status", label: "Payment Status" },
    { id: "advance_request", label: "Advance Request" },
    { id: "advance_status", label: "Advance Status" },
  ];

  const getImageData = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`disbursement/get-proof-path`, {
        path,
      }).then((res: any) => {
          setProofPath(res.data.result);
      });
    } else {
      toast.error("Doc not uploaded", {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  const ConvertDate=(date)=>{
    let ndate=new Date(date);
    
    return ndate.toISOString() ;
  }
  const getData = () => {
    setLoader(true);
     let  modifiedLazyState ;
     if(paginator) {
      modifiedLazyState={  ...lazyState,
        limit: lazyState.rows,
        pagination: true,
      }}
      else{
        modifiedLazyState={  ...lazyState,
          limit: lazyState.rows,
          pagination: false,
        }
      }
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };
    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    ApiPost(`disbursement/get-disbursement`, finalModifiedLazyState)
      .then((res: any) => {
        setTotalRecords(Number(res.data.count.count));
          let finalData = res.data.result.map((item: any, index: number) => {
            return {
              SRNO: (lazyState.first) + index + 1,
              id: item._id,
              DsaName: item.dsa_name ?? "-",
              BorrowerName: item.borrower_name ?? "-",
              Contact_Person_Name:
                item.borrower_contact_person_name == ""
                  ? "-"
                  : item.borrower_contact_person_name,
              Loanamount: item.loan_amount ?? "-",
              Financer: item.bank_name ?? "-",
              Product: item?.product ?? "-",
              dismonth:ConvertDate(item?.disbursement_date) ?? "-",
              Connectorname: item?.connector_name ?? "-",
              Grosspayout: item?.gross_payout ?? "0",
              Advancegross: item?.advance_gross,
              Tdspayout: item?.tds_amount ?? "0",
              Gstpayout: item?.gst_amount ?? "",
              Userpayout: item?.rate ?? "0",
              Netpayout: item?.net_amount_payable ?? "0",
              Advancerequest: item?.advance_request ?? "-",
              Advancestatus: item?.advance_status ?? "-",
              Paymentstatus: item?.status ?? '-',
              city: item?.city,
              state: item?.state,
              borrower_pincode: item?.borrower_pincode,
              borrower_contact_person_number:
                item?.borrower_contact_person_number ?? '-',
              logincodelist_id: item?.logincodelist_id,
              connector_id: item?.connector_id,
              connector_rate: item?.connector_rate ?? '-',
              is_connector: item?.is_connector,
              lan_no: item?.lan_no == "" ? '-' : item?.lan_no,
              adjustment_amount: item?.adjustment_amount,
              // disbursement_date:item?.disbursement_date,
              reason: item?.reason ?? '-',
              connector_gross_payout:item.connector_gross_payout,
              connector_gst_amount:item.connector_gst_amount ?? '',
              connector_net_amount_payable:item.connector_net_amount_payable,
              connector_tds_amount:item.connector_tds_amount,
              dsa_id:item.dsa_id,
              co_status:item.co_status,
              subvention:item.subvention,
              adjusted_payout_per:item.adjusted_payout_per,
              subvention_per:item.subvention_per,
              advance_proof_path:item.advance_proof_path ? item.advance_proof_path : '',
              system_adjustment:item.system_adjustment,
              sales_name: item.sales_name,
              dsa_invoice_id:item.dsa_invoice_id,
              connector_invoice_id:item.connector_invoice_id,
              ...item
            };
          });
          setDisbursementDetail(finalData);
          setState({
            ...state,
            totalItems: res.data.count.count,
          });
          if(!paginator)
            {
              if(exportType == 'pdf'){
          const data=res.data.result;
          const doc = new jsPDF("landscape", "mm", "a2");
    
          let width = doc.internal.pageSize.width;
          let height = doc.internal.pageSize.height;
      
          const img = new Image();
          img.src = "assets/logo.png";
          img.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.globalAlpha = 0.2;

           ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const watermarkBase64 = canvas.toDataURL("image/png");

            const maxWidth = width * 0.5;
            const maxHeight = height * 0.5; 
            let newWidth = img.width;
            let newHeight = img.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const widthRatio = maxWidth / newWidth;
              const heightRatio = maxHeight / newHeight;
              const scale = Math.min(widthRatio, heightRatio);
      
              newWidth = newWidth * scale;
              newHeight = newHeight * scale;
          }
      
          // Calculate the center position
          let xPos = (width - newWidth) / 2;
          let yPos = (height - newHeight) / 2;
            let column=columns.filter((col)=>{
               if(col != null)
                return col;
            })
            const title = "Disbursement list";
            doc.setFontSize(18);
            doc.setFont("helvetica");
            const textWidth = doc.getTextDimensions(title).w;
            doc.text(title, (width - textWidth) / 2, 10);
            // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');
            autoTable(doc, {
              head: [column.map((col) =>        
                     col.label
                  )],
              body: data.map((product, index) =>
                column.map((col) => {                  
                  if ( col.id === "SRNO" ) {
                    return index + 1;
                  } else if(col != null) {
                    return product[col.id];
                  }
                })
              ),
              styles: {
                fontSize: 10,
                cellPadding: 3,
                textColor: [0, 0, 0],
              },
              headStyles: {
                fillColor: [52, 152, 219],
                textColor: [255, 255, 255],
              },
              margin: { top: 15 },
              theme: "grid",
              didDrawPage: function (data) {
                doc.addImage(
                  watermarkBase64,
                  "PNG",
                  xPos, yPos, newWidth, newHeight
                );
              },
            });
            doc.save(`Disbursement_${newdate}.pdf`);
            
          };
          setPaginator(true);
        }
        else{
          const data = res.data.result;
          let column=columns.filter((col)=>{
            if(col != null)
             return col;
         })
          const modifiedData = data.map((product, index) => {
            // Create a row with custom logic for "SRNO"
            const row = column.map(col => {
              if (col.id === "SRNO") {
                return index + 1; // Add serial number
              } else if(col != null) {
                return product[col.id]; // Add product data
              }
            });
        
            return row;
          });
          const csvRows = [];
          csvRows.push(column.map(col => col.label));
          modifiedData.forEach(row => {
            csvRows.push(
              row
            );
          });
          const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `Disbursement_${newdate}.csv`);
          document.body.appendChild(link); // Required for FF
          link.click();
          document.body.removeChild(link);
        }
      
      }
        setLoader(false);      
  })
      .catch((error) => {
        setDisbursementDetail([]);
      });
  };
  const dt = useRef(null);

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };
  const onInvoicePage = (event) => {
    let filter = { ...invoicelazyState };
    filter = event;
    filter["search"] = invoicelazyState.search;
    setInvoicelazyState(filter);
  };
  const onInvoiceFilter = (event) => {
    event['first'] = 0;
    event['page'] = 0;
    const dismonthValue = event.filters?.Created_at?.value;

    if (
      dismonthValue &&
      dismonthValue.month === null &&
      dismonthValue.year === null
    ) {
      event.filters.Created_at.value = null;
    }
    setInvoicelazyState(event);
};

  const onFilter = (event) => {
    event["first"] = 0;
    event["page"] = 0;
    const dismonthValue = event.filters?.dismonth?.value;

    if (
      dismonthValue &&
      dismonthValue.month === null &&
      dismonthValue.year === null
    ) {
      event.filters.dismonth.value = null;
    }

    setlazyState(event);
  };
  const targetRef = useRef();

  const exportPdf = () => {
    setPaginator(false);
    getData();   
    setExportType('pdf');

  };

  const exportCSV = () => {
    setPaginator(false);
    getData();   
    setExportType('csv');
    
  };
  const dsaNameBodyTemplate = (rowData) => {
 return (
        <span
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => navigate(`/disbursementdetailmodal/${rowData.id}`,)}
        >
            {rowData.BorrowerName}
        </span>
    );
};
  const renderHeader = () => {
    return (
      <div className="flex justify-end justify-space-between">
        {activeTab == 'disbursement' &&<div>
          {/* <Button
            type="button"
            icon="pi pi-file-pdf"
            rounded
            onClick={exportPdf}
            data-pr-tooltip="PDF"
          /> */}
          {

          userPayload?.permission?.dsa_disbursement?.extend&&
          <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
        <OverlayPanel ref={op}>
                <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                    { (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='staff_operation') && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={() => exportCSV()}>Csv</option>
                                        )}
                                        </OverlayPanel>
        </Button>
        }
        </div>}
        <div>
          <span>
            <InputText
              className="mr-2 ml-3"
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Keyword Search"
            />
            <Button icon="pi pi-search" onClick={handleSearchClick} />
          </span>
        </div>
      </div>
    );
  };

  const renderInvoiceHeader = () => {
    return (
      <div className="flex justify-end justify-space-between">
          <span>
            <InputText
              className="mr-2 ml-3"
              value={searchValue}
              onChange={handleInvoiceInputChange}
              onKeyDown={handleInvoiceKeyDown}
              placeholder="Keyword Search"
            />
            <Button icon="pi pi-search" onClick={handleInvoiceSearchClick} />
          </span>
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  
  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const handleInvoiceKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterInvoiceChange(searchValue);
    }
  };

  const handleInvoiceInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleInvoiceSearchClick = () => {
    onGlobalFilterInvoiceChange(searchValue);
  };
  const onGlobalFilterInvoiceChange = (e) => {
    const value = e;
    let filter = { ...invoicelazyState };

    filter["search"] = value;

    setInvoicelazyState(filter);
  };

  const header = renderHeader();
  const invoiceHeader = renderInvoiceHeader();

  const menuRef = useRef(null);
  const selectedRowData = useRef(null);
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const accept = () => {
    handleDelete(selectedRowData.current);
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };
  
  const getDowloadProofLink = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`disbursement/download-proof`, {
        path,
      }).then((res: any) => {
        const linkElement = document.createElement("a");
        linkElement.href = res.data.result;
        linkElement.click();
      }).catch((error)=>{
        //  console.log(error)
      });
    } else {
      toast.error("Doc not uploaded", {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  const getInvoiceImageData = async (path: any) => {
      if (path != undefined) {
        await ApiPost(`disbursement/get-proof-path`, {
          path,
        }).then((res: any) => {
          setViewInvoice(true)
          setInvoicePath(res.data.result);
        });
      } else {
        toast.error("Doc not uploaded", {
          position: "top-right",
          theme: "colored",
        });
      }
    };
  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Edit") {
      handleEditClick(selectedRowData.current);
      if ((selectedRowData.current.Paymentstatus === "Billing Received") || (selectedRowData.current.Paymentstatus === "Payment Pending" && selectedRowData.current.Advancestatus === "approved" ) ) {
        setDisableField(true);
      }
      if (selectedRowData.current.Paymentstatus === "Completed" || selectedRowData.current.Paymentstatus === "Advance Completed" || (selectedRowData.current.dsa_invoice_id != null || selectedRowData.current.connector_invoice_id != null) || selectedRowData.current.system_adjustment != 0.0){
        setDisableEdit(true);

      }
      
      
    }
    if (itemLabel === "Advance Approval") {
      setSelectedId(selectedRowData.current.id);
      setItem({
        ...item,
        product: selectedRowData?.current.Product,
        gross_payout: selectedRowData?.current.Advancegross,
        bankname: selectedRowData?.current.Financer,
        payoutpercentage: selectedRowData?.current.Userpayout,
      });
      setOpen(true);
    }
    if (itemLabel === "Delete") {
      if (
        (selectedRowData.current.Paymentstatus === "Payment Pending" ||
        selectedRowData.current.Paymentstatus === "Mismatch Case") && (selectedRowData.current.dsa_invoice_id ==null && selectedRowData.current.connetor_invoice_id ==null)
      ) {
        setDeleteConfirmVisible(true);
      } else {
        toast.error("Disbursement cannot be deleted", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (itemLabel === "Add Utr Details") {

      if(selectedRowData.current.Paymentstatus == "Completed"){
        toast.error("Disbursement is Closed", {
          position: "top-right",
          theme: "colored",
        });
      }
      else if( (selectedRowData.current.dsa_gst_no == null ||((selectedRowData.current.dsa_gst_no != null || selectedRowData.current.dsa_gst_no != "" || selectedRowData.current.dsa_gst_no != " ") && selectedRowData.current.dsa_invoice_id != null)) && (selectedRowData.current.is_connector == false || ((selectedRowData.current.is_connector == true) && ( selectedRowData.current.connector_gst_no == null  || (selectedRowData.current.connector_gst_no != null || selectedRowData.current.connector_gst_no != '' || selectedRowData.current.connector_gst_no != ' ') && selectedRowData.current.connector_invoice_id != null))) ){
        setApprovePayment(true)
        setPaymentForm({...paymentForm,amount:selectedRowData.current.Netpayout})
      }
      else{
        toast.error("Disbursement cannot be Close as GST user", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    if (itemLabel === "View Proof") {
      if(selectedRowData.current.advance_proof_path != ''){
        setViewProof(true)
        getImageData(selectedRowData.current.advance_proof_path)
      }
      else{
        setViewProof(false)
        toast.error("Doc not uploaded", {
          position: "top-right",
          theme: "colored",
        });
      }
      
     }
     if (itemLabel === "Download Proof") {
      if(selectedRowData.current.advance_proof_path != ''){
        getDowloadProofLink(selectedRowData.current?.advance_proof_path)
      }
      else{
        setViewProof(false)
        toast.error("Doc not uploaded", {
          position: "top-right",
          theme: "colored",
        });
      }
      
     }

     if(itemLabel == 'Send Disbursal Confirmation'){
      if (
        selectedRowData.current.Paymentstatus === "Payment Pending" && (selectedRowData.current.Advancestatus == 'pending' || selectedRowData.current.Advancestatus =='' || selectedRowData.current.Advancestatus =='reject')){
        setSendMailDialog(true)
        setSelectedId(selectedRowData.current.id)  
      }
      else{
        toast.error("Not Allowed", {
          position: "top-right",
          theme: "colored",
        });
      }
     
     }

     if(itemLabel== 'View Invoice'){
      if(selectedRowData.current?.invoice_path != null){
        getInvoiceImageData(selectedRowData.current?.invoice_path)
      }
      else{
        toast.error("Doc not uploaded", {
                  position: "top-right",
                  theme: "colored",
                });
      }
     }

     if (itemLabel === "More info") {

      setDataModal(true)

      const Data = selectedRowData.current || {};
const dsakeys = Object.keys({
                    Grosspayout:'0.00' ,
                    Tdspayout: "0.00",
                    Gstpayout: "0.00",
                    Netpayout: "0.00",
                    Advancegross:"0.00",
                    subvention: "0.00",
                    adjustment_amount: "0.00",
                    system_adjustment: "0.00",
                    Userpayout:"0.00",
                    orenda_dsa_invoicenumber:''
});

const connectorkeys = Object.keys({
  
                    connector_gross_payout: "0.00",
                    connector_tds_amount: "0.00",
                    connector_gst_amount: "0.00",
                    connector_net_amount_payable: "0.00",
                    connector_rate:'0.00',
                    orenda_connector_invoicenumber:'',
});

// Prepare data for table
const tableData = dsakeys.map((key) => {
  const value = (Data[key])  ;
  return {
    key,
    value: value,
  };
})
setDsaInfoData(tableData)

if(selectedRowData.current.is_connector){
  const tableData = connectorkeys.map((key) => {
    const value = (Data[key]) ;
    return {
      key,
      value: value,
    };
  })
  setConnectorInfoData(tableData)
}

       }   
     
  };

  const InfoColumn={
    Grosspayout: "Gross payout",
    Tdspayout: "Tds payout",
    Gstpayout: "Gst payout",
    Netpayout: "Net payout",
    Advancegross: "Advance  gross",
    adjustment_amount: "Adjustment amount",
    subvention: "Subvention",
    system_adjustment: "System adjustment",
    Userpayout: "Grid Payout",
    orenda_dsa_invoicenumber: "Dsa invoice number",
    connector_gross_payout: "Connector Gross payout",
    connector_tds_amount: "Connector Tds payout",
    connector_gst_amount: "Connector Gst payout",
    connector_net_amount_payable: "Connector Net payout",
    connector_rate: "Connector rate",
    orenda_connector_invoicenumber: "Connector invoice number",
  }
  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    const menuItems = [];
    // if (permission[PERMISSION_TYPE.ADMIN]) {
    if(activeTab=='invoice'){
      menuItems.push({
        label: "View Invoice",
        command: () => handleMenuItemClick("View Invoice"),
      });
      
    }
    else{
    menuItems.push({
      label: "Edit",
      command: () => handleMenuItemClick("Edit"),
    });
    // }

    menuItems.push({
      label: "More info",
      command: () => handleMenuItemClick("More info"),
    });
    menuItems.push({
      label: "Advance Approval",
      command: () => handleMenuItemClick("Advance Approval"),
    });
    if (userPayload?.permission?.dsa_disbursement?.delete) {
      menuItems.push({
        label: "Delete",
        command: () => handleMenuItemClick("Delete"),
      });
    }
    if (userPayload?.user?.role=='admin') {

    menuItems.push({
      label: "Add Utr Details",
      command: () => handleMenuItemClick("Add Utr Details"),
    });}

    if (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='staff_operation' ) {

      menuItems.push({
        label: "Advance Proof",
        items: [
          {
            label: "Download Proof",
            command: () => handleMenuItemClick("Download Proof"),
          },
          {
            label: "View Proof",
            command: () => handleMenuItemClick("View Proof"),
          },
        ],
        command: () => handleMenuItemClick("Advance Proof"),
      });}

      if (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='staff_operation' || userPayload?.user?.role=='dsa' || userPayload?.user?.role=='booking_partner') {

        menuItems.push({
          
              label: "Send Disbursal Confirmation",
              command: () => handleMenuItemClick("Send Disbursal Confirmation"),                 
          
        });}
  }
    return menuItems;
  };

  const handleDelete = (data: any) => {
      ApiPut(`disbursement/delete-disbursement?id=${data.id}`).then(
        (res: any) => {
          getData();
          toast.success(res.data, {
            position: "top-right",
            theme: "colored",
          });
        }
      )
     .catch ((error)=> {
      // toast.error(error.error, {
      //   position: "top-right",
      //   theme: "colored"
      // })
    })
    getData();
  };

  const sendMail= ()=>{
        let updatedform={...emailData}
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
  
        if(updatedform.sm_manager_email == ''){
          updatedform = { ...updatedform, sm_manager_email_error: "Please enter a Email id" };
        }
        if (updatedform.sm_manager_email != '' && !emailPattern.test(updatedform.sm_manager_email)) {
          updatedform = { ...updatedform, sm_manager_email_error: "Please enter a valid email" };
      }
        // if(updatedform.asm_manager_email == ''){
        //   updatedform = { ...updatedform, asm_manager_email_error: "Please select Email id " };
        // }
        // if(updatedform.asm_manager_email != '' && !emailPattern.test(updatedform.asm_manager_email)){
        //   updatedform = { ...updatedform, asm_manager_email_error:"Please enter a valid email" };
        // }
        setEmailData(updatedform)
        let payload={
          sm_email:updatedform.sm_manager_email,
          asm_email:updatedform.asm_manager_email,
          case_id:selectedId,
        }
        if(updatedform.sm_manager_email_error == null ){
          ApiPost(`disbursement/send-mail`,payload).then((res: any) => {
           if(res.status == 200){
            toast.success(
              res.data.message,
              {
                position: "top-right",
                theme: "colored",
              }
            );
           }
           setSendMailDialog(false)
            setEmailData(email_data)
            
          }).catch((error)=>{
            //  console.log(error)
          });
        }
        
      }

      
   
  const getConnectorUser = (code: any) => {
    ApiGet(`connector/getConnector/code/${code}`)
      .then((res: any) => {
        const temp: any = [
          {
            id: res.data.result._id,
            // label: res.data.result.email,
            // label: `${res.data.first_name} ${res.data.middle_name || ''} ${res.data.last_name} - ${res.data.email}`,
          },
        ];
        setConnectorList(temp);
      })
      .catch((error) => {
        setConnectorList([]);
      });
  };

  const getConnector = (id) => {
    ApiGet(`connector/option-connector?id=${id}`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            id: item._id,
            label: `${item.first_name} ${item.middle_name || ''} ${item.last_name} - ${item.email}`,
          };
        });
        setConnectorList(temp);
      })
      .catch((error) => {
        setConnectorList([]);
      });
  };

  
  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id,
          };
        });
        const bankData = _.groupBy(temp, function (b) {
          return b.bank;
        });
        setBankData(bankData);
      })
      .catch((error) => {
        setBankData({});
      });
  };

  const handlePage = (page: number) => {
    if (activeTab == "initiated") {
      setState({
        ...state,
        page,
      });
    }
    if (activeTab == "l1l2") {
      setStateLevel({
        ...stateLevel,
        page,
      });
    }
    if (activeTab == "mismatch") {
      setStateMisMatch({
        ...stateMisMatch,
        page,
      });
    }
  };

  const handlePageData = (limit: number, page: number = 1) => {
    if (activeTab == "initiated") {
      setState({
        ...state,
        limit,
        page,
      });
    }
    if (activeTab == "l1l2") {
      setStateLevel({
        ...stateLevel,
        limit,
        page,
      });
    }
    if (activeTab == "mismatch") {
      setStateMisMatch({
        ...stateMisMatch,
        limit,
        page,
      });
    }
  };

  useEffect(()=>{
    setUserPayload(decodeToken(localStorage.getItem('token')));
  },[])

  useEffect(() => {
    Promise.all([
      getData(),
      getLoginCodeList(),
      getUser(),
      getOptionsData(),
    ])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [lazyState,paginator,userPayload]);
  useEffect(() => {
    Promise.all([
   getInvoicedata(),
   getInvoiceOptionsData()

  ])
  .then(() => {
    setTimeout(() => {
      setInvoiceLoader(false);
    }, 250);
  })
  .catch((error) => {
    setInvoiceLoader(false);
  });
}, [invoicelazyState,permission,userPayload]);
  

  
  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addDisbursement = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let mobilePattern = /^\+?[0-9]{10,}$/;

    let alphanumeric=/^[A-Za-z0-9\s]+$/

    if (formData.user_id == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please select DSA name",
      };
    }
    if ((userPayload?.user?.role!='staff_sale') && (userPayload?.user?.role!='admin') && (userPayload?.user?.role!='staff_operation') && (userPayload?.user?.role!='booking_partner')) {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: null,
      };
    }



    else{
      if(formData.user_id == ""){
        updatedForm = {
          ...updatedForm,
          dsa_name_error: 'Please select a DSA',
        };
      }
    }

    if (formData.borrower_name == "") {
      updatedForm = {
        ...updatedForm,
        borrower_name_error: "Please enter a borrower name",
      };
    }

    if (formData.loan_amount == "") {
      updatedForm = {
        ...updatedForm,
        loan_amount_error: "Please enter a loan amount",
      };
    }
    // if (formData.borrower_pincode == "") {
    //   updatedForm = { ...updatedForm, pincode_error: "Please enter a pincode" };
    // }
    if (formData.borrower_pincode == "") {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a Pincode",
      };
    }
    let pincodePatten = /^(\d{6})$/;
    if (
      formData.borrower_pincode != "" &&
      !pincodePatten.test(formData.borrower_pincode)
    ) {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a valid pincode",
      };
    }
    if (formData.financer_name == "") {
      updatedForm = {
        ...updatedForm,
        financer_name_error: "Please enter a financer name",
      };
    }
        if((formData.adjustment_amount == 0 || formData.adjustment_amount == "") && formData.reason == "" ){
      updatedForm = { ...updatedForm, reason_error: null };
    }
    if((formData.adjustment_amount != '' || formData.adjustment_amount != 0) && formData.reason == ''){
      updatedForm = { ...updatedForm, reason_error: "Please enter a reason" };
    }
    if(formData.adjustment_amount != '' && formData.reason != "" ){
      updatedForm = { ...updatedForm, reason_error: null};
    }
    if (formData.financer_name != "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error: "Please select Product" };
    }
    if (formData.financer_name == "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error:null };
    }
    if (formData.disbursement_date == null) {
      updatedForm = {
        ...updatedForm,
        month_of_disbursement_error: "Please enter a Month",
      };
    }
    if ((formData.connector_id == null || formData.connector_id == '') && isconnector ) {
      updatedForm = {
        ...updatedForm,
        connector_error: "Please enter a connector",
      };
    }
    if (isconnector && (formData.connector_rate == '' || formData.connector_rate == null)) {
      updatedForm = {
        ...updatedForm,
        connector_rate_error: "Please enter a connector rate",
      };
    }
    // if (formData.borrower_contact_person_number == "") {
    //   updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile no." };
    // }
    if (
      formData.borrower_contact_person_number != "" &&
      !mobilePattern.test(formData.borrower_contact_person_number)
    ) {
      updatedForm = {
        ...updatedForm,
        mobile_error: "Please enter a valid mobile no.",
      };
    }

    setFormData(updatedForm);
    updatedForm = {
      ...updatedForm,
      adjustment_amount:
        updatedForm.adjustment_amount === ""
          ? "0.00"
          : updatedForm.adjustment_amount,
    };
    if (
      updatedForm.dsa_name_error ==null &&
      updatedForm.borrower_name_error == null &&
      updatedForm.loan_amount_error == null &&
      updatedForm.pincode_error == null &&
      updatedForm.financer_name_error == null &&
      updatedForm.product_error == null &&
      updatedForm.month_of_disbursement_error == null &&
      updatedForm.connector_error == null &&     
      updatedForm.connector_rate_error == null &&
      updatedForm.mobile_error == null &&
      updatedForm.reason_error==null
    ) {
      const {
        borrower_name_error,
        lan_no_error,
        loan_amount_error,
        pincode_error,
        financer_name_error,
        product_error,
        month_of_disbursement_error,
        connector_error,connector_rate_error,
        contact_person_name_error,
        mobile_error,
        reason_error,
        ...payload
      } = updatedForm;
      let finalData = payload;
      if (updatedForm.connector_id == "") {
        let { connector, ...payload } = finalData;
        finalData = payload;
      }
      setLoader(true)
      ApiPost("/disbursement/add-disbursement", finalData)
        .then((res: any) => {
          setFormData(disbursementData);
          getData();
          setAddOpen(false);
          setConnector(false);
          setConnectorList([]);
          if (res?.status == 200) {
            toast.success("Data added successfully!!", {
              position: "top-right",
              theme: "colored",
            });
            setLoader(false)
          }
        })
        .catch((error) => {});
    }
  };

  const addPaymentData = () => {

    let updatedForm = { ...paymentForm };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    // let mobilePattern = /^\+?[0-9]{10,}$/;
    // let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    // if (paymentForm.month == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     month_error: "Please select Month",
    //   };
    // }
    // if (paymentForm.year == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     year_error: "Please select Year",
    //   };
    // }
   
    if (paymentForm.file_sequence_num == "") {
      updatedForm = {
        ...updatedForm,
        file_sequence_num_error: "Please enter file sequrence no.",
      };
    }

    // if (paymentForm.pymt_prod_type_code == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     pymt_prod_type_code_error: "Please enter a Payment prod type code",
    //   };
    // }
    // if (paymentForm.pymt_mode == "") {
    //   updatedForm = { ...updatedForm, pymt_mode_error: "Please enter a Payment mode" };
    // }
    // if (paymentForm.debit_acct_no == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     debit_acct_no_error: "Please enter a Debit Acc. No.",
    //   };
    // }
    // if (paymentForm.beneficiary_name == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     beneficiary_name_error: "Please enter a beneficiary name",
    //   };
    // }
    // if (paymentForm.beneficiary_account_no == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     beneficiary_account_no_error: "Please beneficiary account no.",
    //   };
    // }

    // if (paymentForm.bene_ifsc_code == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     bene_ifsc_code_error: "Please beneficiary account no.",
    //   };
    // }
    if (paymentForm.amount == "") {
      updatedForm = { ...updatedForm, amount_error: "Please enter an amount" };
    }
    // if (paymentForm.debit_narration == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     debit_narration_error: "Please enter a debit narration",
    //   };
    // }
    // if (paymentForm.credit_narration == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     credit_narration_error: "Please enter a debit narration",
    //   };
    // }
    
    // if (paymentForm.email_id == "") {
    //   updatedForm = { ...updatedForm, email_id_error: "Please enter an email" };
    // }

    // if (paymentForm.email_id != "" && !emailPattern.test(paymentForm.email_id)) {
    //   updatedForm = { ...updatedForm, email_id_error: "Please enter an email" };
    // }
    // if (paymentForm.remark == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     remark_error: "Please enter a remark",
    //   };
    // }
    if (paymentForm.pymt_date == "") {
      updatedForm = {
        ...updatedForm,
        pymt_date_error: "Please enter a payment date",
      };
    }
    // if (paymentForm.addl_info1 == "") {
    //   updatedForm = { ...updatedForm, addl_info1_error: "Please enter an Add info." };
    // }
    // if (paymentForm.status == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     status_error: "Please enter a status",
    //   };
    // }
    // if (paymentForm.current_step == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     current_step_error: "Please enter a current step",
    //   };
    // }
    // if (paymentForm.file_name == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     file_name_error: "Please enter a file name",
    //   };
    // }
    if (paymentForm.acct_debit_date == "") {
      updatedForm = { ...updatedForm, acct_debit_date_error: "Please enter an account debit date." };
    }
    if (paymentForm.customer_ref_no == "") {
      updatedForm = {
        ...updatedForm,
        customer_ref_no_error: "Please enter a customer reference no.",
      };
    }
    // if (paymentForm.utr_no == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     utr_no_error: "Please enter a utr no.",
    //   };
    // }
    // if (paymentForm.mobile_number == "") {
    //   updatedForm = {
    //     ...updatedForm,
    //     mobile_number_error: "Please enter a mobile no.",
    //   };
    // }
    // if (
    //   paymentForm.mobile_number != "" &&
    //   !mobilePattern.test(paymentForm.mobile_number)
    // ) {
    //   updatedForm = {
    //     ...updatedForm,
    //     mobile_error: "Please enter a valid mobile no.",
    //   };
    // }

    setPaymentForm(updatedForm);
   
    if (
      // updatedForm.month_error == null &&
      // updatedForm.year_error == null &&
      updatedForm.file_sequence_num_error == null &&
      // updatedForm.pymt_prod_type_code_error == null &&
      // updatedForm.pymt_mode_error == null &&
      // updatedForm.debit_acct_no_error == null &&
      // updatedForm.beneficiary_name_error == null &&
      // updatedForm.beneficiary_account_no_error == null &&
      // updatedForm.bene_ifsc_code_error == null &&
      updatedForm.amount_error == null &&
      // updatedForm.debit_narration_error == null &&
      // updatedForm.credit_narration_error == null  &&
      // updatedForm.mobile_number_error == null &&
      // updatedForm.email_id_error == null &&
      // updatedForm.remark_error == null &&
      updatedForm.pymt_date_error == null &&
      // updatedForm.addl_info1_error == null &&
      // updatedForm.status_error == null &&
      // updatedForm.current_step_error == null &&
      // updatedForm.file_name_error == null &&
      updatedForm.acct_debit_date_error == null &&
      updatedForm.customer_ref_no_error == null 
      // &&  updatedForm.utr_no_error == null
    ) {
      const data={
       
            file_sequence_num: updatedForm.file_sequence_num,            
            amount:updatedForm.amount,            
            pymt_date:moment(new Date(updatedForm.pymt_date)).format('YYYY-MM-DD'),            
            acct_debit_date:moment(new Date(updatedForm.acct_debit_date)).format('YYYY-MM-DD'),
            customer_ref_no:updatedForm.customer_ref_no,
            // utr_no:updatedForm.utr_no,
            dsa_id:selectedRowData.current.dsa_id,
            case_id:selectedRowData.current.id
      }
      
      ApiPost("disbursement/add-orenda-disbursement",data)
        .then((res: any) => {
          
          getData();
         
          if (res?.status == 200) {
            toast.success('Payment Completed', {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {});
    }
  };

  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };
  const handleBorrowerNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s.]/g, '');
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const handleAdjustmentAmountInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9+\-\.]/g, "");
  };
  const handleAlphaNumericInput = (e)=>{
    e.target.value=e.target.value.replace(/[^A-Za-z0-9\s]/g,'');
  }
  const editDisbursement = () => {
    let updatedForm = { ...formData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] == "string") {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    let namePattern = /^[A-Za-z\s]+$/;
    let mobilePattern = /^\+?[0-9]{10,}$/;
    if (formData.user_id == "") {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: "Please select DSA name",
      };
    }
    if ((userPayload?.user?.role!='staff_sale') && (userPayload?.user?.role!='admin') && (userPayload?.user?.role!='staff_operation')) {
      updatedForm = {
        ...updatedForm,
        dsa_name_error: null,
      };
    }
    else{
      if(formData.user_id == ""){
        updatedForm = {
          ...updatedForm,
          dsa_name_error: 'Please select a DSA',
        };
      }
    }
    if (formData.borrower_name == "") {
      updatedForm = {
        ...updatedForm,
        borrower_name_error: "Please enter a borrower name",
      };
    }
       if (formData.loan_amount == "") {
      updatedForm = {
        ...updatedForm,
        loan_amount_error: "Please enter a loan amount",
      };
    }
    // if (formData.borrower_pincode == "") {
    //   updatedForm = { ...updatedForm, pincode_error: "Please enter a pincode" };
    // }
    if (formData.borrower_pincode == "") {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a Pincode",
      };
    }
    let pincodePattern = /^(\d{6})$/;
    if (
      formData.borrower_pincode != "" &&
      !pincodePattern.test(formData.borrower_pincode)
    ) {
      updatedForm = {
        ...updatedForm,
        pincode_error: "Please enter a valid pincode",
      };
    }
    if((formData.adjustment_amount === "0.00" || formData.adjustment_amount == "") && formData.reason == "" ){
      updatedForm = { ...updatedForm, reason_error: null };
    }
    if((formData.adjustment_amount != ''  && formData.adjustment_amount != '0.00')&& formData.reason == "" ){
      updatedForm = { ...updatedForm, reason_error:  "Please enter a reason"};
    }
    if(formData.adjustment_amount != '' && formData.reason != "" ){
      updatedForm = { ...updatedForm, reason_error: null};
    }

    if (formData.financer_name == "") {
      updatedForm = {
        ...updatedForm,
        financer_name_error: "Please enter a financer name",
      };
    }
    if (formData.financer_name == "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error:null };
    }
    if (formData.financer_name != "" && formData.logincodelist_id == "") {
      updatedForm = { ...updatedForm, product_error: "Please select Product" };
    }
    if (formData.disbursement_date == null) {
      updatedForm = {
        ...updatedForm,
        month_of_disbursement_error: "Please enter a Month",
      };
    }
    if ((formData.connector_id == null || formData.connector_id == '') && isconnector ) {
      updatedForm = {
        ...updatedForm,
        connector_error: "Please enter a connector",
      };
    }
    if (isconnector && (formData.connector_rate == '' || formData.connector_rate == null)) {
      updatedForm = {
        ...updatedForm,
        connector_rate_error: "Please enter a connector rate",
      };
    }
    // if (formData.borrower_contact_person_number == "") {
    //   updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile no." };
    // }
    if (
      formData.borrower_contact_person_number != "" &&
      !mobilePattern.test(formData.borrower_contact_person_number)
    ) {
      updatedForm = {
        ...updatedForm,
        mobile_error: "Please enter a valid mobile no.",
      };
    }
    setFormData(updatedForm);
    updatedForm = {
      ...updatedForm,
      adjustment_amount:
        updatedForm.adjustment_amount === ""
          ? "0.00"
          : updatedForm.adjustment_amount,
    };
    if (
      updatedForm.borrower_name_error == null &&
      updatedForm.loan_amount_error == null &&
      updatedForm.pincode_error == null &&
      updatedForm.financer_name_error == null &&
      updatedForm.product_error == null &&
      updatedForm.month_of_disbursement_error == null &&
      updatedForm.connector_error == null &&
      updatedForm.connector_rate_error == null &&
      updatedForm.mobile_error == null &&
      updatedForm.reason_error == null 
    ) {
      const {
        borrower_name_error,
        lan_no_error,
        loan_amount_error,
        pincode_error,
        financer_name_error,
        product_error,
        month_of_disbursement_error,
        connector_error,connector_rate_error,
        contact_person_name_error,
        mobile_error,
        reason_error,
        ...payload
      } = updatedForm;
      let finalData = payload;
      if (updatedForm.connector_id == "") {
        let { connector, ...payload } = updatedForm;
        finalData = payload;
      }
      ApiPut(`disbursement/edit-disbursement`, finalData)
        .then((res: any) => {
          setFormData(disbursementData);
          getData();
          setEditOpen(false);
          if (res?.status == 200) {
            toast.success("Data updated successfully!!", {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {});
    }
    
  };

  const disbursementcsv = async () => {
    let formData = new FormData();

    if (selectedFile) {
      formData.append("filedata", selectedFile);
    }

    await ApiPost(`disbursement/add-utr-csv`, formData)
      .then((res: any) => {
        if (res?.data?.message == "upload successfully") {
          toast.success("upload Successfully.", {
            position: "top-right",
            theme: "colored",
          });
          getData();
          // getConnector();
          getLoginCodeList();
          setDisableField(false);
          setDisableEdit(false);
        }

        setSelectedFile("");
      })
      .catch((error: any) => {
        // toast.error(error?.error, {
        //   position: "top-right",
        //   theme: "colored"
        // })
      });
  };

  useEffect(() => {
    if (selectedFile) {
      disbursementcsv();
    }
  }, [selectedFile]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = getAllData.map((row: any) => row._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleEditClick = (row: any) => {
    setFormData({
      ...formData,
      id: row.id,
      financer_name: row.Financer,
      logincodelist_id: row.logincodelist_id,
      borrower_name: row.BorrowerName,
      borrower_contact_person_name: row.Contact_Person_Name,
      loan_amount: row.Loanamount,
      borrower_pincode: row.borrower_pincode,
      city: row.city,
      state: row.state,
      borrower_contact_person_number: row.borrower_contact_person_number,
      lan_no: row.lan_no,
      connector_id: row.connector_id,
      is_connector: row.is_connector,
      connector_rate: row.connector_rate ?? null,
      disbursement_date: new Date(row.dismonth),
      adjustment_amount: row.adjustment_amount,
      reason: row.reason,
      advanceRequests: row.Advancerequest,
      advance_status: row.Advancestatus,
      status:row.Paymentstatus,
      subvention:row.subvention,
      advance_proof_path:row.advance_proof_path,
      system_adjustment:row.system_adjustment,
      dsa_invoice_id:row.dsa_invoice_id,
      connector_invoice_id:row.connector_invoice_id,
    });
    setEditOpen(true);
    getConnector(row.dsa_id)
    setConnector(row.is_connector)
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // const columns = ["SR.NO.", "Name", "City", "State", "Contact No.", "Total Billing", "TotalLoan Amount"];
  
  const columnsLevel = [
    { id: "SRNO", label: "SR.NO." },
    { id: "borrower_name", label: "Borrower Name" },
    { id: "user", label: "User" },
    { id: "month_of_disbursement", label: "Month Of Disbursement" },
    { id: "l1_dsa", label: "L1 DSA" },
    { id: "l2_dsa", label: "L2 DSA" },
    { id: "product", label: "Product" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "l1_up_expense", label: "L1 Up Expense" },
    { id: "l2_up_expense", label: "L2 Up Expense" },
  ];

  const columnsMisMatch = [
    { id: "SRNO", label: "SR.NO." },
    { id: "bank_name", label: "Bank Name" },
    { id: "customer_name", label: "Customer Name" },
    { id: "lan_Number", label: "Lan Number" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "month_and_year", label: "Month And Year" },
    { id: "payout_in_percetage", label: "Payout In Percetage" },
    { id: "product", label: "product" },
  ];

  const actionYes = async () => {
    await ApiPut(`disbursement/edit-disbursement?id=${selectedId}`, {
      advance_request: true,
      is_visible_icon: true,
      cheker_type: "maker",
    })
      .then((res: any) => {
        setSelectedId("");
        getData();
        setOpen(false);
        if(res){
          toast.success("Request sent successfully", {
          position: "top-right",
          theme: "colored"
        })
        }       
      })
      .catch((error: any) => {
        // toast.error("Something went wrong!", {
        //   position: "top-right",
        //   theme: "colored"
        // })
      });
  };

  const getPaymentMode = (row: any) => {
    if (row?.user?.kyc?.ifsc.startsWith("ICICI")) {
      return "FT";
    }
    if (row?.loan_amount >= 200000) {
      return "RTGS";
    }
    return "NEFT";
  };

  const getaccountnumber = async () => {
    await ApiGet(`basicDetails/list`)
      .then((res: any) => {
        setgetAccNo(res.data.result.account_number);
      })
      .catch((error) => {});
  };

  const getExportData = (data: any) => {
    let finalData: any = [];
    let temp = data?.map((row: any) => {
      if (selected.includes(row._id)) {
        finalData.push({
          PYMT_PROD_TYPE_CODE: "PAB_VENDOR",
          PYMT_MODE: getPaymentMode(row),
          DEBIT_ACC_NO: getAccNo,
          BNF_NAME: row?.user?.name,
          BENE_ACC_NO: row?.user?.kyc?.account_number,
          BENE_IFSC: row?.user?.kyc?.ifsc,
          AMOUNT: row?.net_amount_payable,
          MOBILE_NUM: row?.user?.mobile,
          EMAIL_ID: row?.user?.email,
          PYMT_DATE: moment().format("DD/MM/YYYY").toString(),
        });
      }
      return row;
    });
    return finalData;
  };
  const customStyles = (hasError) => ({
    control: (base, state) => ({
      ...base,
      borderColor: hasError ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: hasError ? 'red' : base.borderColor,
      },
    }),
  });
  // const postget = async (e: any) => {
    
  //   const value = e.target.value;
  //   setFormData({
  //     ...formData,
  //     borrower_pincode: e.target.value,
  //     pincode_error: null,
  //     state_error: null,
  //     city_error: null,
  //   });
  //   if (value.length >= 6 )
  //   ApiGet(`auth/get-post?pincode=${e.target.value}`)
  //   .then((res: any) => {

      
  //     setFormData({
  //       ...formData,
  //       state: res.data?.data?.state ?? '',
  //       city: res.data?.data?.city ?? '',
  //       borrower_pincode:e.target.value ,
  //     });
  //   })
    
  // };


  const postget = async (e: any) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      borrower_pincode: value,
      pincode_error: null,
      state_error: null,
      city_error: null,
    });
    if (value.length >= 6) {
      const options = {
        method: "GET",
        url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
        headers: {
          "X-RapidAPI-Key":
            "b2572becadmshbaf647a375178edp179823jsneae8fa2a8334",
          "X-RapidAPI-Host":
            "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
        },
      };
      await axios.request(options).then((res: any) => {
        setFormData({
          ...formData,
          state: res?.data[0]?.state,
          city: res?.data[0]?.district,
          borrower_pincode: value,
        });
      });
    }
  };
  
const getInvoicedata = async () => {

  if(userPayload?.user?.role=='dsa' || userPayload?.user?.role=='connector'){
    setInvoiceLoader(true);
    const modifiedLazyState = { ...invoicelazyState, limit: invoicelazyState.rows };
    delete modifiedLazyState.rows;
    delete modifiedLazyState['multiSortMeta'];
    delete modifiedLazyState['sortField'];
    delete modifiedLazyState['sortOrder'];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
        for (const key in obj) {
            if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                obj[key] = null;
            } else if (typeof obj[key] === 'object') {
                replaceEmptyValueArrays(obj[key]);
            }
        }
        return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    await ApiPost(`invoiceDetails/get-invoice-list`, finalModifiedLazyState)
        .then((res: any) => {
          setInvoiceTotalRecords(Number(res.data.count[0].count));
            let temp = res.data.result.map((item: any, index: number) => {
                return {
                    id: item._id,
                    SRNO: (invoicelazyState.first) + index + 1,
                    Created_at:format(new Date(item?.created_at), 'dd-MM-yyyy hh:mm:ss') ?? "-",
                    ...item
                }
            })

            setInvoiceDetail(temp)
            setState({
                ...state,
                totalItems: res.data.count[0].count,
              })
        }).catch((error) => {
            setInvoiceDetail([])
        })
        setInvoiceLoader(false)
  }
        
    }
const invoiceData = {
  invoiceNumber: '175-LK',
  invoiceDate: 'Aug 29, 2024',
  hsnCode: '1234',
  partnerCode: 'DSA3273',
  reverseCharge: 'No',
  partnerAddress: 'Ahmedabad, Gujarat - 380015',
  billTo: {
    gstin: '24AABCV2469A1ZP',
    address: 'wasedrfgyhu, Ferm name, anb vgh, dxfcgvhbjnkml',
    panno:'AAFFO6331D',
    state:'20',
    mobile:"1234567890",
    web:"www.com.in"
  },
  items: [
    {
      commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
      description: 'Daily Payout - New Term Loan',
      rate: '2.15%',
      amount: 32895.0,
    },
    {
      commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
      description: 'Daily Payout - New Term Loan',
      rate: '2.15%',
      amount: 32895.0,
    },
    {
      commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
      description: 'Daily Payout - New Term Loan',
      rate: '2.15%',
      amount: 32895.0,
    },
    {
      commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
      description: 'Daily Payout - New Term Loan',
      rate: '2.15%',
      amount: 32895.0,
    },
    {
      commissionId: 'ACENTEUS BUSINESS TECHNOLOGIES PRIVATE LIMITED	',
      description: 'Daily Payout - New Term Loan',
      rate: '2.15%',
      amount: 32895.0,
    },
  ],
  total: 32895.0,
  sgst: 2960.55,
  cgst: 2960.55,
  tds: 1644.75,
  totalInvoiceValue: 37171.35,
  amountInWords: 'Thirty Seven Thousand One Hundred Seventy One And Thirty Five Paise Only',
  bankDetails: {
    beneficiaryName: 'Partner Name',
    accountNumber: '1234567890',
    bankName: 'Example Bank',
    ifscCode: 'IFSC1234',
    accountType: 'Current',
  },
  additionalInstructions:
    'If you have any questions about this invoice, please contact support@example.com or call us at 123-456-7890.',
};

const getBank = async (data) => {

  const options = {
    method: 'GET',
    url: `https://ifsc-lookup-api.p.rapidapi.com/${data}`,
    headers: {
      'x-rapidapi-key': '95a73c289bmshce7f0084828e16ap120c56jsnf7474868d13d',
      'x-rapidapi-host': 'ifsc-lookup-api.p.rapidapi.com'
    }
  };
  
  try {
    const response = await axios.request(options);
    setBankName(response.data.BANK)
  } catch (error) {
    console.error(error);
  }
}
const geneareteInvoiceData = (invoiceData) => {

  let total_pay
  if(userPayload?.user?.role=='connector'){
     total_pay=invoiceData.reduce((total, obj) => total + parseFloat(obj.connector_gross_payout), 0)

  }else{
     total_pay=invoiceData.reduce((total, obj) => total + parseFloat(obj.gross_payout), 0)

  }

  total_pay=total_pay.toFixed(2)
  let sgst_cost=(total_pay)*(9/100);
  let cgst_cost=(total_pay)*(9/100);
  let igst_cost=(total_pay)*(18/100);
  let tds_cost=(total_pay)*(2/100);
  let total_amount=(total_pay+sgst_cost+cgst_cost-tds_cost).toFixed(2);
  let payload;
  payload={
    title:invoiceformData.firm_name,
    invoiceNumber: invoiceformData.invoice_no,
  invoiceDate: new Date(invoiceformData.selectedDate).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }),
  hsnCode: '997159',
  // partnerCode: 'DSA3273',
  Address: invoiceformData.address,
  partner_data: {
    gstin: user.gst_no,
    panno:user.pan_no,
    state:user.gst_no.substring(0,2),
    mobile:user.mobile,
  },
  billTo: {
    gstin: ' 24AAFFO6331D1ZN',
    firmName:'ORENDA FINSERV LLP',
    address: '1226-27, RK World Tower, 150 Feet Ring Road, Near Shital Park, Dharma Society, Rajkot, Rajkot, Gujarat, 360006',
    web:"https://orendafinserv.com"
  },
  items:invoiceData,
  total:`${total_pay}`,
  sgst_cost:`${sgst_cost.toFixed(2)}`,
  cgst_cost:`${cgst_cost.toFixed(2)}`,
  tds_cost:`${tds_cost.toFixed(2)}`,
  igst_cost:`${igst_cost.toFixed(2)}`,
  total_amount:`${total_amount}`,
  amountInWords:toWords.convert(parseFloat(total_amount)),
  bankDetails: {
    beneficiaryName: invoiceformData.firm_name,
    accountNumber: user.account_number,
    bankName: bankName,
    ifscCode: user.ifsc,
  }
  }
  let data=generateInvoicePDF(payload)
  return data
}
const generateInvoicePDF = (invoiceData) => {
  const doc = new jsPDF('p', 'mm', 'a4');
   let width=doc.internal.pageSize.width;
   let height=doc.internal.pageSize.height;

   let padding=10;
   const rectX = 10;
    const rectY = 10;
    const rectWidth = width - 20;
    const rectBaseHeight = 20; // Base height before adding text
    const addressLines = doc.splitTextToSize(invoiceData.Address, 300);
    const lineHeight = 4; // Reduce line spacing for compactness
    const textHeight = addressLines.length * lineHeight;
    let rectHeight=padding*2 + (textHeight-4) 
    doc.setFillColor(245, 245, 245);
    doc.roundedRect(rectX, rectY, rectWidth, rectHeight, 2, 2, 'F');

    doc.setFont('Times-Roman', 'bold');
    doc.setFontSize(18);
    doc.text(invoiceData.title, width / 2, rectY + padding, { align: 'center' });

    // **Address (more horizontal)**
    doc.setFont('Times-Roman', 'normal');
    doc.setFontSize(10);
    
    let currentY = rectY + 15; // Adjusted for better spacing below title

    addressLines.forEach((line) => {
        doc.text(line, width / 2, currentY, { align: 'center' });
        currentY += lineHeight; // Move down for the next line
    });


  //  doc.setFont('Times-Roman', 'bold');
  // doc.setFontSize(20);    
  // doc.setFillColor(245, 245, 245);     
  // doc.roundedRect(10, 15, width-20, 20, 2, 2, 'F'); 
  
  // doc.text(invoiceData.title, 105, 25, { align: 'center' });
  // doc.setFont('Times-Roman', 'normal');
  // doc.setFontSize(10);    
  // doc.text(invoiceData.Address, 105, 32, { align: 'center' });
  
  let yOffset = rectY+rectHeight + 10; 
  let xOffset = 12;
  doc.setFontSize(12);
  doc.setFont('Times-Roman', 'bold');
  doc.setTextColor(145, 145, 145); 
  doc.text('Invoice Details:',xOffset, yOffset);

  const invoiceDetails = [
    [ invoiceData.invoiceNumber,
     invoiceData.hsnCode,
     invoiceData.invoiceDate,

    // invoiceData.partnerCode,
    //  invoiceData.reverseCharge
    ],
  ];

  autoTable(doc, {
    startY: yOffset +3, 
    margin: { left: 11,right:11 }, 
    head: [['Invoice Number','HSN Code', 'Invoice Date']], 
    body: invoiceDetails,
    theme: 'plain',
    headStyles: {
      fillColor: [255, 255, 255], 
      textColor: [155, 155, 155], 
      fontStyle: 'normal',
      font:'Times-Roman'
    },
    bodyStyles: {
      fillColor: [255, 255, 255], 
      textColor: [0, 0, 0], 
      fontSize: 12,
      font:'Times-Roman',
      fontStyle: 'bold',
    },
    // columnStyles: {
    //   0: { halign: 'left', cellWidth: 'auto' },
    //   1: { halign: 'left', cellWidth: 'auto' },
    //   2: { halign: 'left', cellWidth: 'auto' },

    // },
    showHead: 'everyPage', 
    tableWidth: 'auto', 
  });

  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);
  doc.line(xOffset, (doc as any).lastAutoTable.finalY , width-xOffset,(doc as any).lastAutoTable.finalY );

  const startXRight = 107;
  let startY = (doc as any).lastAutoTable.finalY + 12; 
  const verticalLineX = 105; 
  let length=startY + 50
  doc.setLineWidth(0.5);
  doc.line(verticalLineX, startY - 10, verticalLineX,length ); 

    const partnerDetails = [
    ['GSTIN', invoiceData.partner_data.gstin],
    ['Pan No', invoiceData.partner_data.panno],
   
  ];
  const partnerDetails1 = [   
    ['Mobile', invoiceData.partner_data.mobile],
    ['State code', invoiceData.partner_data.state],
  ];
  
  let startX1=xOffset
  partnerDetails.forEach(([label, value], index) => {
   
      doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(145, 145, 145);
      doc.text(label, startX1, startY); 
      startX1 +=50
  });
  let startX2=xOffset

  partnerDetails.forEach(([label, value], index) => {
   
      doc.setFontSize(10);
      doc.setTextColor(0);
      doc.setFont('Times-Roman', 'bold');
      doc.text(value, startX2, startY + 7); 
      startX2 +=50
           
  });
  startY +=20
  let startX21=xOffset
  partnerDetails1.forEach(([label, value], index) => {
      doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(145, 145, 145);
      doc.text(label, startX21, startY); 
      startX21 +=50
  });
  let startX22=xOffset

  partnerDetails1.forEach(([label, value], index) => {
   
      doc.setFontSize(10);
      doc.setTextColor(0);
      doc.setFont('Times-Roman', 'bold');
      doc.text(value, startX22, startY + 7); 
      startX22 +=50
           
  }); 

  startY = (doc as any).lastAutoTable.finalY + 12
  const partnerDetails2 = [
    ['GSTIN', invoiceData.billTo.gstin],
    ['Website',invoiceData.billTo.web],
  ];

      doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(145, 145, 145);
      doc.text('Bill To', startXRight + 8 , startY);
      doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(0);
      doc.text(invoiceData.billTo.firmName, startXRight + 8 , startY + 7);
      doc.text(invoiceData.billTo.address, startXRight + 8 , startY + 12,{ maxWidth: 80 });

      let startX31=startXRight + 8;
      startY = startY + 30 
      partnerDetails2.forEach(([label, value], index) => {
          doc.setFontSize(10);
          doc.setFont('Times-Roman', 'bold');
          doc.setTextColor(145, 145, 145);
          doc.text(label, startX31, startY); 
          startX31 +=40
      });

      let startX32=startXRight + 8
      startY +=7;
      partnerDetails2.forEach(([label, value], index) => {
       
          doc.setFontSize(10);
          doc.setTextColor(0);
          doc.setFont('Times-Roman', 'bold');
          doc.text(value, startX32, startY,{ maxWidth: 45 } ); 
          startX32 +=40
                   
      }); 

  yOffset = length + 7;
  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);
  doc.line(xOffset, length + 3, width-xOffset,length + 3);
  const tableColumnHeaders = ['Sr. No', 'Borrower Name', 'Loan Amount', 'Gross Payout','Net Payout'];
  const tableRows = invoiceData.items.map((item, index) => [
    index + 1,
    item.BorrowerName,
    item.Loanamount,
    userPayload?.user?.role == 'connector' ? item.connector_gross_payout : item.gross_payout,
    userPayload?.user?.role == 'connector' ? item.connector_net_amount_payable : item.net_amount_payable,
  ]);
  autoTable(doc, {
    startY: yOffset,
    margin:{ left: 11,right:11 },
    head: [tableColumnHeaders],
    body: tableRows,
    theme: 'striped',
    headStyles: {
      fillColor: [248,248,255], 
      textColor: [0, 0, 0], 
      fontStyle: 'bold',
      fontSize: 10,
      font:'Times-Roman',
      halign: 'center',
     
    },
    bodyStyles: {
      fontSize: 10,
      cellPadding: 4,
      font:'Times-Roman' ,
      textColor: [0, 0, 0] ,
      fillColor:[255,255,255] 
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255], 
    },
    columnStyles: {
      0: { halign: 'center', cellWidth: 20 },
      1: { halign: 'center', cellWidth: 50 },
      2: { halign: 'center', cellWidth: 35 },
      3: { halign: 'center', cellWidth: 35 },
      4: { halign: 'center', cellWidth: 35 },
      
    },
    didDrawCell: function (data) {
      if (data.section === 'head') {
        const { column, row, cell } = data;
        const { x, y, width, height } = cell;
  
        if (column.index > 0) {
          const lineX = x;
          const lineYStart = y;
          const lineYEnd = y + height;
          doc.setDrawColor(230,230,230);
          doc.setLineWidth(0.5); 
          doc.line(lineX, lineYStart, lineX, lineYEnd); 
        }
      }
    },
  });

  
  let finalY = (doc as any).lastAutoTable.finalY || 90; 
  if (finalY > 245) { 
    doc.addPage();
    finalY=10
  }
  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);
  doc.line(xOffset, finalY + 2, width-xOffset,finalY + 2)    

  yOffset = finalY + 10;
  doc.setFont('Times-Roman', 'bold');
  doc.setFontSize(10);
  doc.text('Total Rs. ', 160, yOffset);
  doc.text(invoiceData.total, 177, yOffset,{ maxWidth: 20 } );

  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);
  doc.line(xOffset, finalY + 16, width-xOffset,finalY + 16);

  yOffset =  finalY + 16 
  let summaryDetails;
  if(invoiceData.partner_data.state == 24){
     summaryDetails = [
      ['Add SGST (9%)', ` ${invoiceData.sgst_cost}`],
      ['Add CGST (9%)', ` ${invoiceData.cgst_cost}`],
      ['Subtract TDS (2%)',` ${invoiceData.tds_cost}`],
    ];
  }
  else{
     summaryDetails = [
      ['Add IGST (18%)', ` ${invoiceData.igst_cost}`],
      ['Subtract TDS (2%)',` ${invoiceData.tds_cost}`],
    ];
  }
 

  if ((yOffset + summaryDetails.length) > 245) { 
    doc.addPage();
    yOffset=5;
  }
  summaryDetails.forEach(([key, value], index) => {
    doc.setFont('Times-Roman', 'bold');
    doc.setTextColor(145, 145, 145);
    doc.text(`${key}:`, 165, yOffset + 10 );
    doc.setFont('Times-Roman', 'normal');
    doc.setTextColor(0);
    yOffset +=7 
    doc.text(value, 165, yOffset + 10 );
    yOffset +=7 

  });
  
  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);
  
  yOffset += summaryDetails.length 
  
  if (yOffset > 255) { 
    doc.addPage();
    yOffset=10;
  }
  

  doc.line(xOffset, yOffset  +=5, width-xOffset,yOffset);

  doc.setFont('Times-Roman', 'italic');
  doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold-italic');
      doc.setTextColor(145, 145, 145);
  doc.text(`In Words:`, xOffset, yOffset += 9);
  doc.setTextColor(0);
  doc.text(invoiceData.amountInWords, xOffset +20, yOffset,{ maxWidth: 110 });

  doc.text(`Total invoice value Rs.`, 210-50, yOffset ,);
  doc.setFont('Times-Roman', 'bold');

  doc.text(invoiceData.total_amount, 210-40, yOffset += doc.getLineHeight()-5);
  doc.setFont('Times-Roman', 'normal');

  yOffset =doc.getLineHeight()+yOffset-5
  doc.line(xOffset, yOffset, width-xOffset,yOffset);

  yOffset += 10;
  doc.setFont('Times-Roman', 'normal');
  doc.setFontSize(12);

  const bankDetails = [
   [ `Beneficiary Name:`,` ${invoiceData.bankDetails.beneficiaryName}`],
   [ `Bank Name:`,` ${invoiceData.bankDetails.bankName}`],
   
  ];

  const bankDetails2 = [
    [ `Account Number:`,` ${invoiceData.bankDetails.accountNumber}`],
    [`IFSC Code:`,` ${invoiceData.bankDetails.ifscCode}`],
  ];

  if (yOffset+bankDetails2.length + bankDetails.length > 255) { 
    doc.addPage();
    yOffset=15;
  }
  doc.text('Partner Bank Details:', xOffset, yOffset);

  yOffset += 10;

  let xstart1=xOffset
  bankDetails.forEach(([label, value], index) => {
          doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(145, 145, 145);
      doc.text(label, xstart1, yOffset); 
      xstart1 +=value.length + 60
  });
  let xstart2=xOffset

  bankDetails.forEach(([label, value], index) => {
    
      doc.setFontSize(10);
      doc.setTextColor(0);
      doc.setFont('Times-Roman', 'bold');
      doc.text(value, xstart2, yOffset + 7); 
      xstart2 +=value.length + 60
    
  });

  yOffset += 15;

  let xstart21=xOffset
  bankDetails2.forEach(([label, value], index) => {
    
      doc.setFontSize(10);
      doc.setFont('Times-Roman', 'bold');
      doc.setTextColor(145, 145, 145);
      doc.text(label, xstart21, yOffset); 
      xstart21 +=value.length + 30
  });
  let xstart22=xOffset

  bankDetails2.forEach(([label, value], index) => {
    
      doc.setFontSize(10);
      doc.setTextColor(0);
      doc.setFont('Times-Roman', 'bold');
      doc.text(value, xstart22, yOffset + 7); 
      xstart22 +=value.length + 30
    
  });

  yOffset += 20;

  if (yOffset > 255) { 
    doc.addPage();
    yOffset=15;
  }
  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);
  doc.line(xOffset, yOffset, width-xOffset,yOffset);
  doc.setDrawColor(230,230,230);
  doc.setLineWidth(0.7);

  doc.setFontSize(10);
  doc.setFont('Times-Roman', 'bold');
  doc.setTextColor(145, 145, 145);
  doc.text('Additional Instructions:', xOffset, yOffset +=7);
  doc.setTextColor(0);
  doc.text('This is a computer generated invoice and does not require signature.', xOffset, yOffset +=6);

  doc.setFontSize(10);
  doc.setFont('Times-Roman', 'bold');
  doc.setTextColor(145, 145, 145);
  doc.text('If you have any questions about this invoice, please contact:', xOffset, yOffset += 6);
  doc.setTextColor(0);

  const firmnameline = doc.splitTextToSize(`“ ${invoiceformData.firm_name} / Mobile: ${user.mobile} / Email ID: ${user.email}”`, 190);
  const firmnameheight = 4; // Reduce line spacing for compactness
  const firmheight = firmnameline.length * (firmnameheight-2);
  doc.text(firmnameline, xOffset, yOffset += 6);

  yOffset=yOffset+firmheight+5
  doc.line(xOffset, yOffset  , width-xOffset,yOffset);
  
  return doc
      // doc.save('Invoice.pdf');
};

const previewPDF = () => {

  let error = { ...invoiceformData };
  if (invoiceformData.selectedDate == null) {
    error = { ...error, selected_date_error: "Please choose a Date" };
}

if (invoiceformData.invoice_no == '') {
    error = { ...error, invoice_no_error: "Please enter invoice no." };
}
if (invoiceformData.address == '') {
  error = { ...error, address_error: "Please enter Address." };
}

if (invoiceformData.firm_name == '') {
  error = { ...error, firm_name_error: "Please enter Firm name." };
}
let alphanumeric=/^[A-Za-z0-9\s]+$/
if(invoiceformData.firm_name && !alphanumeric.test(invoiceformData.firm_name)){
  error = {...error,firm_name_error:'Please enter valid Firm name'}
}
setInvoiceFormData({
    ...error
});
if ( error.selected_date_error == null  && error.invoice_no_error == null && error.firm_name_error == null && error.address_error == null) {   
  
  if(selectedUsers == null ){

    setInvoiceDialog('table')

    toast.error("Please select Disbursement!!!", {
      position: "top-right",
      theme: "colored",
    });
  }
  else{

    const generatedPDF = geneareteInvoiceData(selectedUsers);
  const pdfDataUrl = generatedPDF.output("bloburl"); // Generate a URL for the blob to preview
  setPdfData(pdfDataUrl); // Set the URL in state for preview
  setInvoiceDialog('preview')
  }
  
}
  };

// Download PDF
const downloadPDF = async () => {
  const generatedPDF = geneareteInvoiceData(selectedUsers);
  generatedPDF.save(`Invoice_${newdate}.pdf`)// Trigger the download
  const pdfDataUrl = generatedPDF.output("blob"); 
  
  const formData = new FormData();
  formData.append("invoice_doc", pdfDataUrl, "document.pdf");
  formData.append("invoice_no", invoiceformData.invoice_no);
  formData.append("cases", JSON.stringify(selectedUsers.map(item => item.id)));
  formData.append("user_id", userPayload?.user?._id);

  ApiPost("/invoiceDetails/add-invoice",formData )
        .then((res: any) => {
          setAddInvoice(false);
          setInvoiceFormData(bankDisbursementFormData)
          getInvoicedata()
          setSelectedUsers(null)
          setOtp(null)
          setBankName('')
          setInvoiceDisbursementDetail([])
          if (res?.status == 200) {
            toast.success("Data added successfully!!", {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {});

};

const sendOtpVerificationMail=()=>{

  ApiPost("user/send-otp-verification-email",{email:userPayload?.user?.email} )
        .then((res: any) => {
          if (res?.status == 200) {
            toast.success(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
            setInvoiceDialog('otp')
          }
        })
        .catch((error) => {});

}
const verifyOtp=()=>{

  ApiPost("/invoiceDetails/verify-otp",{otp:otp} )
        .then((res: any) => {
          if (res?.status == 200) {
            toast.success(res.data.message, {
              position: "top-right",
              theme: "colored",
            });
            setInvoiceDialog('download')
          }
        })
        .catch((error) => {});

}

const CreatedmonthFilterTemplate = (options) => {
      
      const years=[];
      let currentYear;
      let currentMonth = new Date().getMonth();
                if(currentMonth <= 6){
                   currentYear = new Date().getFullYear();
                }
                else{
                   currentYear = new Date().getFullYear()+1;
                }  
                for (let i = currentYear; i >= 2018; i--) {
                  years.push({ label: i.toString(), value: i });
                }  
  
      const onApplyFilter = () => {
        options.filterApplyCallback({ month: selectedInvoiceMonth, year: selectedInvoiceYear });
      };
  
      const onClearFilter = () => {
        setSelectedInvoiceMonth(null);
        setSelectedInvoiceYear(null);
        options.filterApplyCallback({ month: null, year: null });
      };
  
      const isBothSelected = selectedInvoiceMonth && selectedInvoiceYear;
      return (
        <div style={{ minWidth: "14rem" }}>
          <div className="mb-2">
            <Dropdown
              optionLabel="label"
              optionValue="value"
              value={selectedInvoiceMonth}
              options={months}
              onChange={(e) =>{ 
                
                setSelectedInvoiceMonth(e.value)}}
              placeholder="Select Month"
              className="p-column-filter"
              filter
            />
          </div>
          <div className="mb-2">
            <Dropdown
              optionLabel="label"
              optionValue="value"
              value={selectedInvoiceYear}
              options={years}
              onChange={(e) => setSelectedInvoiceYear(e.value)}
              placeholder="Select Year"
              className="p-column-filter"
              filter
            />
          </div>
          <div className="p-column-filter-buttonbar" style={{ padding: "0" }}>
            <Button
              label="Clear"
              onClick={onClearFilter}
              className="p-button p-component p-button-outlined p-button-sm"
            />
            <Button
              label="Apply"
              onClick={onApplyFilter}
              className="p-button p-component p-button-sm"
              disabled={!isBothSelected}
            />
          </div>
        </div>
      );
    };


    const renderTable = (data,type) => {
    
      return (
<div>
 <span > {type} Values :</span>
<table style={{ width: "100%", borderCollapse: "collapse",marginTop:"5px" }}>
  <thead>
    <tr>
      <th style={{ border: "1px solid black", padding: "8px", width: "50%" }}>Field</th>
      <th style={{ border: "1px solid black", padding: "8px", width: "50%" }}>Value</th>
    </tr>
  </thead>
  <tbody>
    {data?.map((row, index) => (
      <tr key={index}>
        <td style={{ border: "1px solid black", padding: "8px", width: "50%" }}>{InfoColumn[row.key]}</td>
        <td style={{ border: "1px solid black", padding: "8px", width: "50%" }}>{row.value}</td>
      </tr>
    ))}
  </tbody>
</table>
                            </div>
      );
    };    
  return (
    <div className="overflow-x-hidden">
      {/* <Sidebar />
<Header /> */}
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between space-y-5">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
              <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                                <li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "disbursement" && "bg-white rounded-[18px] text-primary"}`} 
                                    onClick={
                                      () =>{
                                        setActiveTab("disbursement")
                                    }}>
                                      Disbursement Data
                                      </button>
                                </li>
                               { ( (userPayload?.user?.role=='dsa' || userPayload?.user?.role=='connector')&& (userPayload?.user?.gst_no != null)&&(userPayload?.user?.gst_no != "") ) &&<li>
                                <button className={`min-w-[170px] py-2 px-7 text-xs font-medium ${activeTab === "invoice" && "bg-white rounded-[18px] text-primary"}`} 
                                    onClick={() => {
                                        setActiveTab("invoice")
                                    }}>Invoice Data</button>
                                </li>}
                            </ul>      
                {/* 
                    if(tab active) 
                        Add class "bg-white rounded-[18px] text-primary"
                    else 
                        Remove class "bg-white rounded-[18px] text-primary"
                */}
                {/* for-user */}
              {activeTab== 'disbursement' && <> 
              {/* {(permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.BOOKING_PARTNER]) && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpen(true);
                        }}
                      >
                        Add Disbursement Data
                      </button>
                    </li>
                  </ul>
                )}

                {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] ) && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpen(true);
                        }}
                      >
                        Add Disbursement Data
                      </button>
                    </li>
                  </ul>
                )} */}
                {userPayload?.permission?.dsa_disbursement?.create && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          userPayload?.user?.role=='dsa' && setFormData(disbursementData);
                          setAddOpen(true);
                        }}
                      >
                        Add Disbursement Data
                      </button>
                    </li>
                  </ul>
                )} </>}

                  {activeTab== 'invoice' && ((userPayload?.user?.role=='dsa' || userPayload?.user?.role=='connector')&& (userPayload?.user?.gst_no != null)&&(userPayload?.user?.gst_no != "")) && <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`py-2 px-7 text-xs font-medium`}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddInvoice(true);
                          setInvoiceDialog('dataform')
                          setInvoiceFormData(bankDisbursementFormData)
                          setSelectedUsers(null)
                          setOtp(null)
                          setInvoiceDisbursementDetail([])

                        }}
                      >
                        Add Invoice
                      </button>
                    </li>
                  </ul> }
              </div>

              {/* START::EXPORT */}
             
            </div>
         

            {activeTab== 'disbursement' && <>
            <DataTable
              ref={dt}
              value={disbursementDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator={paginator}
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              header={header}
              emptyMessage="No records found."
              
            >
              <Column field="SRNO" header="SR.NO." />
              {userPayload?.user?.role=='admin' ? (
                <Column
                  field="sales_name"
                  header="SM/BP Name"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={salesNameFilterTemplate}
                />
              ) : null}
              {userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation' ||
              userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner' ? (
                <Column
                  field="DsaName"
                  header="DSA Name"
                  showFilterMatchModes={false}
                  filterMenuStyle={{ minWidth: "14rem" }}
                  filter
                  filterElement={dsaNameFilterTemplate}
                />
              ) : null}
              <Column field="BorrowerName" header="Borrower Name" 
                                body={dsaNameBodyTemplate}
/>
              {/* <Column field="Contact_Person_Name" header="Contact Person" /> */}
              <Column field="Loanamount" header="Loan Amount" />
              <Column
                field="Financer"
                header="Financer"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={financersFilterTemplate}
              />
              <Column
                field="Product"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productsFilterTemplate}
              />
              <Column
                field="dismonth"
                header="Disbursement Month"
                showFilterMatchModes={false}
                showApplyButton={false}
                showClearButton={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={DismonthFilterTemplate}
              />
              {/* <Column field="Connectorname" header="Connector Name" /> */}
              <Column field={userPayload?.user?.role=='connector' ? 'connector_rate': "Userpayout"} header="Grid Payout %" />
              {(userPayload?.user?.role!='connector' || userPayload?.user?.role!='dsa') &&
              <Column field="connector_rate" header="Connector Payout %"
                body={(rowData)=>{
                  if(!rowData.connector_rate ){
                    return "NA"
                  }
                }}
              />}
              {userPayload?.user?.role!='connector' &&
              <Column field="adjusted_payout_per" header="Adjusted Payout % " /> 
              }
              {/* {userPayload?.user?.role!='connector' &&
              <Column field="adjustment_amount" header="Adjustment Amount" />
              } */}
              <Column field="subvention_per" header="Subvention %" />
              {/* <Column field='subvention' header='Subvention'></Column> */}
              {/* <Column field='system_adjustment' header='System Adjustment'></Column> */}
              <Column field={userPayload?.user?.role=='connector' ? 'connector_gross_payout': "Grosspayout"} header="Gross Payout" />
              {/* <Column  field={userPayload?.user?.role=='connector' ? 'connector_tds_amount': "Tdspayout"} header="TDS Payout" /> */}
              {/* <Column  field={userPayload?.user?.role=='connector' ? 'connector_gst_amount': "Gstpayout"} header="GST Payout" /> */}
              {/* <Column  field={userPayload?.user?.role=='connector' ? 'connector_net_amount_payable': "Netpayout"} header="Net Payout" /> */}
              <Column
                field="Paymentstatus"
                header="Payment Status"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={paymentStatusesFilterTemplate}
                body={(rowData) => {
                  let color;
                  
                  switch(rowData.Paymentstatus){
                    case "Completed":
                      color = "green";
                      break
                    case "Advance Completed":
                        if (rowData.co_status==1) {
                            color = "red";
                          }else{
                          color = "purple";
                        }
                      break;
                    case "Billing Received":
                      color = "blue";
                      break;
                    case "Mismatch Case":
                      color = "#E65100";
                      break
                    case "Payment Pending":
                      color = "#FBC02D";
                      break
                  }
                  return <span style={{ color }}>{rowData.Paymentstatus}</span>;
                }}
              />
              {/* <Column
                field="Advancerequest"
                header="Advance Request"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={advanceRequestFilterTemplate}
              /> */}
              {/* <Column
                field="Advancestatus"
                header="Advance Status"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={advanceStatusesFilterTemplate}
              /> */}
              {/* {permission[PERMISSION_TYPE.ADMIN] ? ( */}
              {userPayload?.permission?.dsa_disbursement?.update &&<Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />}
              {/* ) : null} */}
            </DataTable> </>}
            {activeTab== 'invoice'&& ( userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation'  || ((userPayload?.user?.role=='dsa' || userPayload?.user?.role=='connector' )&& (userPayload?.user?.gst_no != null)&&(userPayload?.user?.gst_no != "")) ) && <>
            <DataTable
              ref={dt}
              value={invoiceDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator
              first={invoicelazyState.first}
              filters={invoicelazyState.filters}
              onFilter={onInvoiceFilter}
              rows={invoicelazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={invoicetotalRecords}
              onPage={onInvoicePage}
              loading={invoiceloader}
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              header={invoiceHeader}
              emptyMessage="No records found."
              
            >
              <Column field="SRNO" header="SR.NO." />              
              <Column field="userinvoicenumber" header="User Invoice No." />
              <Column field="Created_at" header="Generation Date"
                showFilterMatchModes={false}
                showApplyButton={false}
                showClearButton={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={CreatedmonthFilterTemplate}
                />             
                <Column field="status" header="Invoice status" 
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={invoiceStatusFilterTemplate}
                />              
                <Column
                field="Action"
                header="Action"
                body={actionBodyTemplate}
              />
              {/* ) : null} */}
            </DataTable> </>}

          </div>
          {/* </main> */}

          {/* Start::Add Disbursement Data Modal */}
          {activeTab== 'disbursement' && <>  
          <div
            className={`tw-modal ${addOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Disbursement Data</div>
                <button
                  onClick={() => {
                    setAddOpen(false);
                    setConnector(false);
                    setDisableField(false);
                    setDisableEdit(false);
                    setFormData(disbursementData);
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                {(userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner') && (
                    <div className="space-y-1">
                      <label htmlFor="connector" className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}>
                        Select DSA<span style={{color:'red'}}>*</span>
                      </label>
                      <div id="connector" className={`input-wrap ${formData.dsa_name_error ?  'border-red-500' : ''}`}>
                        <img src="assets/connector.svg" alt="" />
                        <select
                          name="connector"
                          value={formData.user_id}
                          onChange={(e) => {
                            getConnector(e.target.value)
                            setFormData({
                              ...formData,
                              user_id: e.target.value,
                              dsa_name_error:null
                              });
                            // getConnectorUser(userList[0].referral_code)
                          }}
                          className={`w-full border text-sm text-[#808080]`}
                        >
                          <option value="">Select</option>
                          {userList.map((y: any) => (
                            <option value={y.value}>
                              {y.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                    </div>
                  )}
                 {(userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation' ) && (
                    <div className="space-y-1">
                      <label htmlFor="connector" className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}>
                        Select DSA<span style={{color:'red'}}>*</span>
                      </label>
                      <div id="connector" className={`input-wrap ${formData.dsa_name_error ?  'border-red-500' : ''}`}>
                        <img src="assets/connector.svg" alt="" />
                        <select
                          name="connector"
                          value={formData.user_id}
                          onChange={(e) => {
                            getConnector(e.target.value)
                            setFormData({
                              ...formData,
                              user_id: e.target.value,
                              dsa_name_error:null
                              });
                            // getConnectorUser(userList[0].referral_code)
                          }}
                          className={`w-full border text-sm text-[#808080]`}
                        >
                          <option value="">Select</option>
                          {userList.map((y: any) => (
                            <option value={y.value}>
                            {y.label}
                          </option>
                          ))}
                        </select>
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                    </div>
                  )}
                  {userPayload?.user?.role=='staff_operation' && (
                    <div className="space-y-1">
                      <label htmlFor="connector" className={`input-label ${
                        formData.dsa_name_error ? "text-red-500" : ""
                      }`}>
                        Select DSA<span style={{color:'red'}}>*</span>
                      </label>
                      <div id="connector" className={`input-wrap ${formData.dsa_name_error ?  'border-red-500' : ''}`}>
                        <img src="assets/connector.svg" alt="" />
                        <select
                          name="connector"
                          value={formData.user_id}
                          onChange={(e) => {
                            getConnector(e.target.value)
                            setFormData({
                              ...formData,
                              user_id: e.target.value,
                              dsa_name_error:null
                              });
                            // getConnectorUser(userList[0].referral_code)
                          }}
                          className={`w-full border text-sm text-[#808080]`}
                        >
                          <option value="">Select</option>
                          {userList.map((y: any) => (
                            <option value={y.value}>
                            {y.label}
                          </option>
                          ))}
                        </select>
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.dsa_name_error}
                    </p>
                    </div>
                  )}
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_name"
                      className={`input-label ${
                        formData.borrower_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Name of Borrower<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_name"
                      className={`input-wrap ${
                        formData.borrower_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_name"
                        value={formData.borrower_name}
                        onInput={handleBorrowerNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_name: e.target.value,
                            borrower_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Name of Borrower"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.borrower_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="lan_no" className="input-label">
                      LAN No.
                    </label>
                    <div id="lan_no" className="input-wrap">
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="lan_no"
                        value={formData.lan_no}
                        onChange={(e: any) => {
                          setFormData({ ...formData, lan_no: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter LAN No."
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="contact_person_name"
                      className="input-label"
                    >
                      Contact Person Name
                    </label>
                    <div id="contact_person_name" className="input-wrap ">
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="contact_person_name"
                        onInput={handleNameInput}
                        value={formData.borrower_contact_person_name}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_name: e.target.value,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Contact Person Name"
                      />
                    </div>
                  </div>

                  {(userPayload?.user?.role!='dsa' && userPayload?.user?.role!='connector') && (
                    <div className="space-y-1">
                      <label
                        htmlFor="subvention"
                        className="input-label"
                      >
                        Subvention
                      </label>
                      <div id="subvention" className="input-wrap">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="subvention"
                          onInput = {handleNumericInput}
                          value={formData.subvention}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              subvention: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Subvention"
                          disabled={disableEdit || disableField}
                        />
                      </div>
                    </div>
                  )}

                  {(userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner') && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label"
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap ">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="adjustment_amount"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Adjusment Amount"
                        />
                      </div>
                    </div>
                  )}
                  {(userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation') && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label "
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap ">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="contaadjustment_amountct_person_name"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Adjusment Amount"
                        />
                      </div>
                    </div>
                  )}
                  {(userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner')&& (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder=" Enter Reason"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.reason_error}
                      </p>
                    </div>
                  )}
                  {(userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation') && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Enter Reason"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.reason_error}
                      </p>
                    </div>
                  )}
                  {/* <div className="space-y-1">
                  <label htmlFor="lan_no" className={`input-label ${formData.pan_no_error ? "text-red-500" : ""}`}>Pan No.</label>
                  <div id="pan_no" className={`input-wrap ${formData.pan_no_error ? "border-red-500" : ""}`}>
                    <img src="assets/firm.svg" alt="" />
                    <input type="text" name="lan_no" value={formData.pan_no} onChange={(e: any) => {
                      setFormData({ ...formData, pan_no: e.target.value, pan_no_error: null });
                    }} className="w-full text-sm" placeholder="Enter LAN No." />
                  </div>
                  <p className="text-red-500 text-sm">{formData.pan_no_error}</p>
                </div> */}
                  <div className="space-y-1">
                    <label
                      htmlFor="loan_amount"
                      className={`input-label ${
                        formData.loan_amount_error ? "text-red-500" : ""
                      }`}
                    >
                      Loan Amount<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="loan_amount"
                      className={`input-wrap ${
                        formData.loan_amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="loan_amount"
                        onInput={handleNumericInput}
                        value={formData.loan_amount}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            loan_amount: e.target.value,
                            loan_amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Loan Amount"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.loan_amount_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_pincode"
                      className={`input-label ${
                        formData.pincode_error ? "text-red-500" : ""
                      }`}
                    >
                      Borrower’s City Pincode<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_pincode"
                      className={`input-wrap ${
                        formData.pincode_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_pincode"
                        onInput={handleNumericInput}
                        maxLength={6}
                        value={formData.borrower_pincode}
                        onChange={(e: any) => {
                          postget(e);
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Borrower’s City Pincode"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.pincode_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="financer_name"
                      className={`input-label ${
                        formData.financer_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Financer Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="financer_name"
                      className={`input-wrap ${
                        formData.financer_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/bank.svg" alt="" />
                      <select
                        name="financer_name"
                        value={formData.financer_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            financer_name: e.target.value,
                            financer_name_error: null,
                            product: "",
                          })
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Bank/NBFC Name
                        </option>
                        {Object.keys(bankData)?.map((bank: any, id: any) => (
                          <option value={bank}>{bank}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.financer_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="city" className="input-label">
                      City
                    </label>
                    <div id="city" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={(e: any) => {
                          setFormData({ ...formData, city: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter City"
                        disabled
                      />
                      {/* }} className="w-full text-sm" placeholder="Enter City" disabled /> */}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="state" className="input-label">
                      State
                    </label>
                    <div id="state" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={(e: any) => {
                          setFormData({ ...formData, state: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter State"
                        disabled
                      />
                      {/* }} className="w-full text-sm" placeholder="Enter State" disabled /> */}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="product"
                      className={`input-label ${
                        formData.product_error ? "text-red-500" : ""
                      }`}
                    >
                      Product<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="product"
                      className={`input-wrap ${
                        formData.product_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/product.svg" alt="" />
                      <select
                        name="product"
                        value={formData.logincodelist_id}
                        onChange={(e) =>{
                          setFormData({
                            ...formData,
                            logincodelist_id: e.target.value,
                            product_error: null,
                          })
                        }
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Product
                        </option>
                        {bankData?.[formData.financer_name]?.map(
                          (item: any) => (
                            <option value={item.id}>{item.product}</option>
                          )
                        )}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.product_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="month_of_disbursement"
                      className={`input-label ${
                        formData.month_of_disbursement_error
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      Month of Disbursement<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="month_of_disbursement"
                      className={`input-wrap ${
                        formData.month_of_disbursement_error
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={formData.disbursement_date}
                        onChange={(date: any) =>
                          setFormData({
                            ...formData,
                            disbursement_date: date,
                            month_of_disbursement_error: null,
                          })
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.month_of_disbursement_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <br className="hidden sm:block" />
                    <label className="input-label">
                      Whether case sourced by Connector?
                    </label>
                    <div className="flex space-x-4">
                      <div className="flex items-center space-x-1">
                      <RadioButton id="yesConnector" name="pizza" value="yes" onChange={() => {
                            setConnector(true);
                            if(userPayload?.user?.role=='dsa'){
                              getConnector(userPayload?.user?._id)
                            }
                            setConnector(true);
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                              connector_rate_error:null,
                              connector_rate: null,
                            connector_id:null,
                            });} }checked={formData?.is_connector === true} />
                        {/* <input
                          type="radio"
                          id="yesConnector"
                          value="yes"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                            });
                            setConnector(true);
                          }}
                          name="yesConnector"
                        /> */}
                        <label htmlFor="yesConnector">Yes</label>
                      </div>
                      <div className="flex items-center space-x-1">
                      <RadioButton inputId="ingredient1" name="pizza" value="no" onChange={() => {
                            setConnector(false);
                            setFormData({
                              ...formData,
                              is_connector: false,
                              connector_error: null,                              
                              connector_rate: null,
                            connector_id:null,
                            connector_rate_error:null
                            })
                          }} checked={formData?.is_connector === false} />
                        {/* <input
                          type="radio"
                          id="noConnector"
                          value="no"
                          name="yesConnector"
                          onChange={() => {
                            setConnector(false);
                            setFormData({
                              ...formData,
                              is_connector: false,
                              connector_error: null,
                            });
                          }}
                          defaultChecked
                        /> */}
                        <label htmlFor="noConnector">No</label>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="mobile"
                      className={`input-label ${
                        formData.mobile_error ? "text-red-500" : ""
                      }`}
                    >
                      Mobile No.
                    </label>
                    <div
                      id="mobile"
                      className={`input-wrap ${
                        formData.mobile_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/phone.svg" alt="" />
                      <input
                        type="text"
                        name="mobile"
                        maxLength={13}
                        value={formData.borrower_contact_person_number}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_number: e.target.value,
                            mobile_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                    {/* <p className="text-red-500 text-sm">{formData.mobile_error}</p> */}
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />
                      <select
                        name="connector"
                        value={formData.connector_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_id: e.target.value,
                            connector_error: null,
                          })
                        }
                        disabled={!isconnector}
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="">Select</option>
                        {connectorList.map((y: any) => (
                          <option value={y.id}>{y.label}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_error}
                    </p>
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_rate_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector Rate
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_rate_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />

                      <input
                        type="text"
                        name="state"
                        value={formData.connector_rate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_rate: e.target.value,
                            connector_rate_error: null,
                          })
                        }
                        disabled={!isconnector}
                        className="w-full text-sm text-[#808080]"
                        placeholder="Enter Connector Rate"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_rate_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={addDisbursement}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setAddOpen(false);
                    setFormData(disbursementData);
                    setConnector(false);
                    setDisableField(false);
                    setDisableEdit(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className={`tw-modal ${open ? "flex" : "hidden"} !items-center`}>
          <div className="tw-modal-content !max-w-[500px]">
              <div className="tw-modal-title  ">
              <div>Advance Approve</div>
                <button onClick={() => setOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body leading-[24px] py-8">
                <span className="font-medium text-black">Please Note : </span>
                Advance payout grid for {item.bankname} & {item.product} is{" "}
                {item.payoutpercentage - 0.25}% .Your Gross Payout will be Rs.{" "}
                {item.gross_payout}. You cannot opt for NORMAL GRID after
                requesting advance. Please confirm?
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => setOpen(false)}
                >
                  No
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={(e) => {
                    actionYes();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
          {/* End::Add Disbursement Data Modal */}
          <div
            className={`tw-modal ${editOpen ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Edit Disbursement Data</div>
                <button
                  onClick={() => {
                    setEditOpen(false);
                    setDisableField(false);
                    setDisableEdit(false);
                    setFormData(disbursementData);
                    setConnector(false);
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_name"
                      className={`input-label ${
                        formData.borrower_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Name of Borrower<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_name"
                      className={`input-wrap ${
                        formData.borrower_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_name"
                        value={formData.borrower_name}
                        onInput={handleBorrowerNameInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_name: e.target.value,
                            borrower_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Name of Borrower"
                        disabled={disableField || disableEdit}                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.borrower_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="lan_no"
                      className={`input-label ${
                        formData.lan_no_error ? "text-red-500" : ""
                      }`}
                    >
                      LAN No.
                    </label>
                    <div
                      id="lan_no"
                      className={`input-wrap ${
                        formData.lan_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="lan_no"
                        value={formData.lan_no}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            lan_no: e.target.value,
                            lan_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter LAN No."
                        disabled={disableField || disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.lan_no_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="contact_person_name"
                      className={`input-label ${
                        formData.contact_person_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Contact Person Name
                    </label>
                    <div
                      id="contact_person_name"
                      className={`input-wrap ${
                        formData.contact_person_name_error
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <img src="assets/firm.svg" alt="" />
                      <input
                        type="text"
                        name="contact_person_name"
                        onInput={handleNameInput}
                        value={formData.borrower_contact_person_name}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_name: e.target.value,
                            contact_person_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Contact Person Name"
                        disabled={disableField || disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.contact_person_name_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="loan_amount"
                      className={`input-label ${
                        formData.loan_amount_error ? "text-red-500" : ""
                      }`}
                    >
                      Loan Amount<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="loan_amount"
                      className={`input-wrap ${
                        formData.loan_amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/email.svg" alt="" />
                      <input
                        type="text"
                        name="loan_amount"
                        value={formData.loan_amount}
                        onInput={handleNumericInput}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            loan_amount: e.target.value,
                            loan_amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Loan Amount"
                        disabled={disableField || disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.loan_amount_error}
                    </p>
                  </div>
                  {(userPayload?.user?.role!='dsa' && userPayload?.user?.role!='connector') && (
                    <div className="space-y-1">
                      <label
                        htmlFor="subvention"
                        className="input-label"
                      >
                        Subvention
                      </label>
                      <div id="subvention" className="input-wrap">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="subvention"
                          value={formData.subvention}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              subvention: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Adjusment Rate"
                          disabled={disableEdit || disableField}
                        />
                      </div>
                    </div>
                  )}
                  {(userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner') && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label"
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="adjustment_amount"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Adjusment Rate"
                          disabled={disableEdit}
                        />
                      </div>
                    </div>
                  )}
                  {(userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation') && (
                    <div className="space-y-1">
                      <label
                        htmlFor="adjustment_amount"
                        className="input-label"
                      >
                        Adjustment Amount
                      </label>
                      <div id="adjustment_amount" className="input-wrap">
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="adjustment_amount"
                          onInput={handleAdjustmentAmountInput}
                          value={formData.adjustment_amount}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              adjustment_amount: e.target.value,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Adjusment Rate"
                          disabled={disableEdit}
                        />
                      </div>
                    </div>
                  )}
                  {(userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner') && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error:null
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Reason"
                          disabled={disableEdit}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.reason_error}
                    </p>
                    </div>
                  )}
                  {(userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation') && (
                    <div className="space-y-1">
                      <label htmlFor="reason" className={`input-label ${
                        formData.reason_error ? "text-red-500" : ""
                      }`}>
                        Reason
                      </label>
                      <div id="reason" className={`input-wrap ${
                        formData.reason_error ? "border-red-500" : ""
                      }`}>
                        <img src="assets/firm.svg" alt="" />
                        <input
                          type="text"
                          name="reason"
                          value={formData.reason}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              reason: e.target.value,
                              reason_error:null
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Reason"
                          disabled={disableEdit}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                      {formData.reason_error}
                    </p>
                    </div>
                  )}
                  <div className="space-y-1">
                    <label
                      htmlFor="borrower_pincode"
                      className={`input-label ${
                        formData.pincode_error ? "text-red-500" : ""
                      }`}
                    >
                      Borrower’s City Pincode<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_pincode"
                      className={`input-wrap ${
                        formData.pincode_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_pincode"
                        onInput={handleNumericInput}
                        maxLength={6}
                        value={formData.borrower_pincode}
                        onChange={(e: any) => {
                          postget(e);
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Borrower’s City Pincode"
                        disabled={disableEdit || disableField}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.pincode_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="financer_name"
                      className={`input-label ${
                        formData.financer_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Financer Name<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="financer_name"
                      className={`input-wrap ${
                        formData.financer_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/bank.svg" alt="" />
                      <select
                        name="financer_name"
                        value={formData.financer_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            financer_name: e.target.value,
                            financer_name_error: null,
                            product: "",
                          })
                        }
                        className="w-full text-sm text-[#808080]"
                        disabled={disableField || disableEdit}
                      >
                        <option value="" selected>
                          Select Bank/NBFC Name
                        </option>
                        {Object.keys(bankData)?.map((bank: any, id: any) => (
                          <option value={bank}>{bank}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.financer_name_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className="input-label">
                      City
                    </label>
                    <div id="pincode" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="pincode"
                        value={formData.city}
                        onChange={(e: any) => {
                          setFormData({ ...formData, city: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter City"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label htmlFor="pincode" className="input-label">
                      State
                    </label>
                    <div id="pincode" className="input-wrap">
                      <img src="assets/location.svg" alt="" />
                      <input
                        type="text"
                        name="pincode"
                        value={formData.state}
                        onChange={(e: any) => {
                          setFormData({ ...formData, state: e.target.value });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter State"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="product"
                      className={`input-label ${
                        formData.product_error ? "text-red-500" : ""
                      }`}
                    >
                      Product<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="product"
                      className={`input-wrap ${
                        formData.product_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/product.svg" alt="" />
                      <select
                        name="product"
                        value={formData.logincodelist_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            logincodelist_id: e.target.value,
                            product_error: null,
                          })
                        }
                        className="w-full text-sm text-[#808080]"
                        disabled={disableField || disableEdit}
                      >
                        <option value="" selected>
                          Select Product
                        </option>
                        {bankData?.[formData.financer_name]?.map(
                          (item: any) => (
                            <option value={item.id}>{item.product}</option>
                          )
                        )}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.product_error}
                    </p>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="month_of_disbursement"
                      className={`input-label ${
                        formData.month_of_disbursement_error
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      Month of Disbursement<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="month_of_disbursement"
                      className={`input-wrap ${
                        formData.month_of_disbursement_error
                          ? "border-red-500"
                          : ""
                      }`}
                    >
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={formData.disbursement_date}
                        onChange={(date: any) =>{
                          setFormData({
                            ...formData,
                            disbursement_date: date,
                            month_of_disbursement_error: null,
                          })}
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        disabled={disableField || disableEdit}
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.month_of_disbursement_error}
                    </p>
                  </div>
                  <div className="space-y-1">
                    <br className="hidden sm:block" />
                    <label className="input-label">
                      Whether case sourced by Connector?
                    </label>
                    <div className="flex space-x-4">
                      <div className="flex items-center space-x-1">
                        {/* <input
                          type="radio"
                          id="yesConnector"
                          value="yes"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                            });
                            setConnector(true);
                          }}
                          name="yesConnector"
                          disabled={disableEdit}
                        /> */}
                        <RadioButton id="yesConnector" value="yes" onChange={() => {
                            setConnector(true);
                            setFormData({
                              ...formData,
                              is_connector: true,
                              connector_error: null,
                              connector_rate_error:null,
                              connector_rate: null,
                            connector_id:null,
                            });} }checked={formData.is_connector === true}  disabled={disableEdit || (formData.status === 'Advance Completed' ? true :false) }/>
                        <label htmlFor="yesConnector">Yes</label>
                      </div>
                      <div className="flex items-center space-x-1">
                      <RadioButton inputId="ingredient1"  value="no" onChange={() => {
                            setConnector(false);                          
                              setFormData({
                                ...formData,
                                is_connector: false,
                                connector_error: null,                              
                                connector_rate: null,
                              connector_id:null,
                              connector_rate_error:null
                              })
                              }
                            
                          } checked={formData.is_connector === false} disabled={disableEdit  || (formData.status === 'Advance Completed' ? true :false)  }/>
                        {/* <input
                          type="radio"
                          id="noConnector"
                          value="no"
                          name="yesConnector"
                          onChange={() => {
                            setConnector(false);
                            setFormData({
                              ...formData,
                              is_connector: false,
                              connector_error: null,
                            });
                          }}
                          disabled={disableEdit}
                        /> */}
                        <label htmlFor="noConnector">No</label>
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="mobile"
                      className={`input-label ${
                        formData.mobile_error ? "text-red-500" : ""
                      }`}
                    >
                      Mobile No.
                    </label>
                    <div
                      id="mobile"
                      className={`input-wrap ${
                        formData.mobile_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/phone.svg" alt="" />
                      <input
                        type="text"
                        name="mobile"
                        maxLength={13}
                        disabled={disableEdit || disableField}
                        value={formData.borrower_contact_person_number}
                        onChange={(e: any) => {
                          setFormData({
                            ...formData,
                            borrower_contact_person_number: e.target.value,
                            mobile_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                    {/* <p className="text-red-500 text-sm">{formData.mobile_error}</p> */}
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />
                      <select
                        name="connector"
                        value={formData.connector_id}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_id: e.target.value,
                            connector_error: null,
                          })
                        }
                        disabled={ disableEdit }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="">Select</option>
                        {connectorList.map((y: any) => (
                          <option value={y.id}>{y.label}</option>
                        ))}
                      </select>
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_error}
                    </p>
                  </div>
                  <div className={`space-y-1 ${formData.is_connector ? "" : "hidden"}`}>
                    <label
                      htmlFor="connector"
                      className={`input-label ${
                        formData.connector_rate_error ? "text-red-500" : ""
                      }`}
                    >
                      Connector Rate
                    </label>
                    <div
                      id="connector"
                      className={`input-wrap ${
                        formData.connector_rate_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/connector.svg" alt="" />
                      <input
                        type="text"
                        name="state"
                        value={formData.connector_rate}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            connector_rate: e.target.value,
                            connector_rate_error: null,
                          })
                        }
                        // max={formData.rate}
                        disabled={disableEdit }
                        className="w-full text-sm text-[#808080]"
                      />
                    </div>
                    <p className="text-red-500 text-sm">
                      {formData.connector_rate_error}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={editDisbursement}
                  disabled={disableEdit}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setEditOpen(false);
                    setDisableField(false);
                    setDisableEdit(false);
                    setFormData(disbursementData);
                    setConnector(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div
            className={`tw-modal ${approvePayment ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Add Payment Data</div>
                <button
                  onClick={() => {
                    setApprovePayment(false);
                    setPaymentForm(data)
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                {/* <div className="space-y-1">
                                        <label htmlFor="selectedMonth" className={`input-label ${paymentForm.month_error ? "text-red-500" : ""}`}>Month*</label>
                                        <div id="selectedMonth" className={`${paymentForm.month_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={paymentForm.month}
                                                options={months}
                                                value={months.find((month) => month.value === paymentForm.month)}
                                                onChange={handleMonthChange}
                                                placeholder="Select Month"
                                                styles={customStyles(paymentForm.month_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{paymentForm.month_error}</p>
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="selectedYear" className={`input-label ${paymentForm.year_error ? "text-red-500" : ""}`}>Year*</label>
                                        <div id="selectedYear" className={`${paymentForm.year_error ? "border-red-500" : ""}`}>
                                            <Select
                                                key={paymentForm.year}
                                                options={years}
                                                value={years.find((year) => year.value === paymentForm.year)}
                                                onChange={handleYearChange}
                                                placeholder="Select Year"
                                                styles={customStyles(paymentForm.year_error)}
                                            />
                                        </div>
                                        <p className="text-red-500 text-sm">{paymentForm.year_error}</p>
                                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="file_sequence" className={`input-label ${
                        paymentForm.file_sequence_num_error ? "text-red-500" : ""
                      }`}>
                        File Sequence No.<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                      id="file_sequence"
                      className={`input-wrap ${
                        paymentForm.file_sequence_num_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="file_sequence"
                        value={paymentForm.file_sequence_num}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            file_sequence_num: e.target.value,
                            file_sequence_num_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter File sequence no."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.file_sequence_num_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="pymt_prod_type_code" className={`input-label ${
                        paymentForm.pymt_prod_type_code_error ? "text-red-500" : ""
                      }`}>
                        Payment Prod Type code*
                      </label>
                      <div
                      id="pymt_prod_type_code"
                      className={`input-wrap ${
                        paymentForm.pymt_prod_type_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="pymt_prod_type_code"
                        value={paymentForm.pymt_prod_type_code}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            pymt_prod_type_code: e.target.value,
                            pymt_prod_type_code_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Payment Prod type code"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.pymt_prod_type_code_error}
                    </p>
                    </div> */}
                    {/* <div className="space-y-1">
                      <label htmlFor="pymt_mode" className={`input-label ${
                        paymentForm.pymt_mode_error ? "text-red-500" : ""
                      }`}>
                        Payment mode*
                      </label>
                      <div
                      id="pymt_mode"
                      className={`input-wrap ${
                        paymentForm.pymt_mode_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="pymt_mode"
                        value={paymentForm.pymt_mode}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            pymt_mode: e.target.value,
                            pymt_mode_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Payment mode"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.pymt_mode_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="debit_acct_no" className={`input-label ${
                        paymentForm.debit_acct_no_error ? "text-red-500" : ""
                      }`}>
                        Debit Account No.*
                      </label>
                      <div
                      id="debit_acct_no"
                      className={`input-wrap ${
                        paymentForm.debit_acct_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="debit_acct_no"
                        value={paymentForm.debit_acct_no_error}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            debit_acct_no: e.target.value,
                            debit_acct_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Debit Account no"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.debit_acct_no_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="beneficiary_name" className={`input-label ${
                        paymentForm.beneficiary_name_error ? "text-red-500" : ""
                      }`}>
                        Beneficiary Name*
                      </label>
                      <div
                      id="beneficiary_name"
                      className={`input-wrap ${
                        paymentForm.beneficiary_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="beneficiary_name"
                        value={paymentForm.beneficiary_name}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            beneficiary_name: e.target.value,
                            beneficiary_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Beneficiary Name"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.beneficiary_name_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="beneficiary_account_no" className={`input-label ${
                        paymentForm.beneficiary_account_no_error ? "text-red-500" : ""
                      }`}>
                        Beneficiary Account No.*
                      </label>
                      <div
                      id="beneficiary_account_no"
                      className={`input-wrap ${
                        paymentForm.beneficiary_account_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="beneficiary_account_no"
                        value={paymentForm.beneficiary_account_no}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            beneficiary_account_no: e.target.value,
                            beneficiary_account_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Account No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.beneficiary_account_no_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="bene_ifsc_code" className={`input-label ${
                        paymentForm.bene_ifsc_code_error ? "text-red-500" : ""
                      }`}>
                        Beneficiary IFSC Code*
                      </label>
                      <div
                      id="bene_ifsc_code"
                      className={`input-wrap ${
                        paymentForm.bene_ifsc_code_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="bene_ifsc_code"
                        value={paymentForm.bene_ifsc_code}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            bene_ifsc_code: e.target.value,
                            bene_ifsc_code_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter IFSC Code"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.bene_ifsc_code_error}
                    </p>
                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="amount" className={`input-label ${
                        paymentForm.amount_error ? "text-red-500" : ""
                      }`}>
                        Amount<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                      id="amount"
                      className={`input-wrap ${
                        paymentForm.amount_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="amount"
                        value={paymentForm.amount}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            amount: e.target.value,
                            amount_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Amount"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.amount_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="debit_narration" className={`input-label ${
                        paymentForm.debit_narration_error ? "text-red-500" : ""
                      }`}>
                        Debit Narration*
                      </label>
                      <div
                      id="debit_narration"
                      className={`input-wrap ${
                        paymentForm.debit_narration_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="borrower_name"
                        value={paymentForm.debit_narration}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            debit_narration: e.target.value,
                            debit_narration_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Debit Narration"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.debit_narration_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="credit_narration" className={`input-label ${
                        paymentForm.credit_narration_error ? "text-red-500" : ""
                      }`}>
                        Credit Narration*
                      </label>
                      <div
                      id="credit_narration"
                      className={`input-wrap ${
                        paymentForm.credit_narration_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="credit_narration"
                        value={paymentForm.credit_narration}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            credit_narration: e.target.value,
                            credit_narration_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Credit Narration"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.credit_narration_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="mobile_number" className={`input-label ${
                        paymentForm.mobile_number_error ? "text-red-500" : ""
                      }`}>
                        Mobile No.
                      </label>
                      <div
                      id="mobile_number"
                      className={`input-wrap ${
                        paymentForm.mobile_number_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="mobile_number"
                        value={paymentForm.mobile_number}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            mobile_number: e.target.value,
                            mobile_number_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Mobile No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.mobile_number_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="email_id" className={`input-label ${
                        paymentForm.email_id_error ? "text-red-500" : ""
                      }`}>
                      Email*
                      </label>
                      <div
                      id="email_id"
                      className={`input-wrap ${
                        paymentForm.email_id_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="email_id"
                        value={paymentForm.email_id}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            email_id: e.target.value,
                            email_id_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="johndue@xyz.com"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.email_id_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="remark" className={`input-label ${
                        paymentForm.remark_error ? "text-red-500" : ""
                      }`}>
                        Remark*
                      </label>
                      <div
                      id="remark"
                      className={`input-wrap ${
                        paymentForm.remark_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="remark"
                        value={paymentForm.remark}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            remark: e.target.value,
                            remark_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Remark"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.remark_error}
                    </p>
                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="pymt_date" className={`input-label ${
                        paymentForm.pymt_date_error ? "text-red-500" : ""
                      }`}>
                        Payment Date<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                      id="pymt_date"
                      className={`input-wrap ${
                        paymentForm.pymt_date_error ? "border-red-500" : ""
                      }`}
                    ><img src="assets/date.svg" alt="" />
                    <DatePicker
                      selected={paymentForm.pymt_date}
                      onChange={(e: any) => {
                        setPaymentForm({
                          ...paymentForm,
                          pymt_date: e,
                          pymt_date_error: null,
                        });
                      }}
                      dateFormat="dd/MM/yyyy"                
                      
                    />                      
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.pymt_date_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="addl_info1" className={`input-label ${
                        paymentForm.addl_info1_error ? "text-red-500" : ""
                      }`}>
                        Add info*
                      </label>
                      <div
                      id="addl_info1"
                      className={`input-wrap ${
                        paymentForm.addl_info1_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="addl_info1"
                        value={paymentForm.addl_info1}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            addl_info1: e.target.value,
                            addl_info1_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Add info"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.addl_info1_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="status" className={`input-label ${
                        paymentForm.status_error ? "text-red-500" : ""
                      }`}>
                        Payment Status*
                      </label>
                      <div
                      id="status"
                      className={`input-wrap ${
                        paymentForm.status_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="status"
                        value={paymentForm.status}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            status: e.target.value,
                            status_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Payment Status"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.status_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="current_step" className={`input-label ${
                        paymentForm.current_step_error ? "text-red-500" : ""
                      }`}>
                        Current Step*
                      </label>
                      <div
                      id="current_step"
                      className={`input-wrap ${
                        paymentForm.current_step_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="current_step"
                        value={paymentForm.current_step}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            current_step: e.target.value,
                            current_step_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Current step"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.current_step_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="file_name" className={`input-label ${
                        paymentForm.file_name_error ? "text-red-500" : ""
                      }`}>
                        File Name*
                      </label>
                      <div
                      id="file_name"
                      className={`input-wrap ${
                        paymentForm.file_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="file_name"
                        value={paymentForm.file_name}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            file_name: e.target.value,
                            file_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter File Name"
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.file_name_error}
                    </p>
                    </div> */}
                    <div className="space-y-1">
                      <label htmlFor="acct_debit_date" className={`input-label ${
                        paymentForm.acct_debit_date_error ? "text-red-500" : ""
                      }`}>
                        Account Debit Date<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                      id="acct_debit_date"
                      className={`input-wrap ${
                        paymentForm.acct_debit_date_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/date.svg" alt="" />
                      <DatePicker
                        selected={paymentForm.acct_debit_date}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            acct_debit_date: e,
                            acct_debit_date_error: null,
                          });
                        }}
                        dateFormat="dd/MM/yyyy"
                        
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.acct_debit_date_error}
                    </p>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="customer_ref_no" className={`input-label ${
                        paymentForm.customer_ref_no_error ? "text-red-500" : ""
                      }`}>
                        Customer Reference No.<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                      id="customer_ref_no"
                      className={`input-wrap ${
                        paymentForm.customer_ref_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="customer_ref_no"
                        value={paymentForm.customer_ref_no}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            customer_ref_no: e.target.value,
                            customer_ref_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Customer Ref. No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.remark_error}
                    </p>
                    </div>
                    {/* <div className="space-y-1">
                      <label htmlFor="utr_no" className={`input-label ${
                        paymentForm.utr_no_error ? "text-red-500" : ""
                      }`}>
                        UTR No.<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                      id="utr_no"
                      className={`input-wrap ${
                        paymentForm.utr_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/user.svg" alt="" />
                      <input
                        type="text"
                        name="utr_no"
                        value={paymentForm.utr_no}
                        onChange={(e: any) => {
                          setPaymentForm({
                            ...paymentForm,
                            utr_no: e.target.value,
                            utr_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter UTR No."
                      />
                    </div>
                      <p className="text-red-500 text-sm">
                      {paymentForm.utr_no_error}
                    </p>
                    </div> */}
                </div>
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={()=>{
                    addPaymentData();
                      setApprovePayment(false);
                      setPaymentForm(data)
                    
                  }}
                >
                  Submit
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setApprovePayment(false);
                    setPaymentForm(data)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div
            className={`tw-modal ${viewProof ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Advance Request Proof</div>
                <button
                  onClick={() => {
                    setViewProof(false)
                    setProofPath('')
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                
               {selectedRowData.current?.advance_proof_path?.split(".").pop() == "pdf" ?
                       
                        <iframe
                          src={`data:application/pdf;base64,${proofPath}`}
                          title="Preview PDF"
                          width="100%"
                          height="1000px"
                        />
                       : 
                        <img
                          style={{ height: "auto", width: "auto" }}
                          src={`data:image/jpeg;base64,${proofPath}`}
                          className="ml-5 object-center object-cover"
                        />          

               }
              </div>

             </div>
          </div>

          <div className={`tw-modal ${sendMailDialog ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Send Mail for Disbursal confirmation</div>
                  <button onClick={() => {setSendMailDialog(false)
                    setEmailData(email_data)
                  }}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-6">
                  <div className="d-flex">
                    <div className="space-y-1">
                      <label
                        htmlFor="normal_payout"
                        className={`input-label ${emailData.sm_manager_email_error ? "text-red-500" : ""}`}
                      >SM Email ID<span style={{color:'red'}}>*</span></label>
                      <div
                        id="normal_payout"
                        className={`input-wrap ${emailData.sm_manager_email_error ? "border-red-500" : ""}`}
                      >
                        <input
                          type="text"
                          name="normal_payout"
                          value={emailData.sm_manager_email}
                          className="w-full text-sm"
                          placeholder="Enter Mail"
                          onChange={(e:any)=>{
                           setEmailData({...emailData,sm_manager_email:e.target.value,sm_manager_email_error:null})
                          }}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {emailData.sm_manager_email_error}
                      </p>
                    </div>
                    <div className="space-y-1">
                      <label
                        htmlFor="normal_payout"
                        className={`input-label ${emailData.asm_manager_email_error ? "text-red-500" : ""}`}
                      >ASM / Reporting Manager Email ID(optional)</label>
                      <div
                        id="normal_payout"
                        className={`input-wrap ${emailData.asm_manager_email_error ? "border-red-500" : ""}`}
                      >
                        <input
                          type="text"
                          name="normal_payout"
                          value={emailData.asm_manager_email}
                          className="w-full text-sm"
                          placeholder="Enter Mail"
                          onChange={(e:any) => {
                            setEmailData({...emailData,asm_manager_email:e.target.value,asm_manager_email_error:null})}
                          }
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {emailData.asm_manager_email_error}
                      </p>
                    </div>
                    
                  </div>
                </div>
               
                <div className="tw-modal-footer">
                <Button
                  icon="pi pi-check"
                  raised
                  severity="info"
                  onClick={() => {
                    sendMail()
                  }}
                >
                  <span style={{ marginLeft: "8px" }}> Submit</span>
                </Button>
                <Button
                  icon="pi pi-times"
                  raised
                  severity="danger"
                  onClick={() => {
                    setSendMailDialog(false)
                    setEmailData(email_data)
                  }}
                >
                  {" "}
                  <span style={{ marginLeft: "8px" }}> Cancel</span>
                </Button>
              </div>
                
              </div>
            </div>

            <Dialog header="Case Information" visible={dataModal} style={{ width: '50vw' }} onHide={() => {if (!dataModal) return; setDataModal(false); }}>
                <p className="m-0">
                {dsainfodata && renderTable(dsainfodata,'Dsa')}
                <br></br>
                {connectorinfodata &&                 
                renderTable(connectorinfodata,'Connector')}

                      </p>
            </Dialog>
          <ConfirmDialog
            // group="declarative"
            visible={deleteConfirmVisible}
            onHide={() => setDeleteConfirmVisible(false)}
            message="Are you sure you want to delete?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
       </>}
       
       {activeTab== 'invoice' && <>
       <div
            className={`tw-modal ${addInvoice ? "flex" : "hidden"} `}
            style={{
                overflowX: 'auto'
              }}
          >
            <div className="tw-modal-content"
            style={{
                // width: "1865px",
                maxWidth:'1865px',
                minWidth:'900px',
                height: "900px",
                overflowX: 'auto'
              }}
             >
              <div className="tw-modal-title "
              style={{
                height:'50px',
                maxWidth:'1865px',
                minWidth:'900px',              }}>
                <div> Invoice Generation</div>
                <button
                  onClick={() => {
                    setAddInvoice(false);
                    setInvoiceDialog('dataform')
                    setInvoiceFormData(bankDisbursementFormData)
                    setOtp(null)
                    setSelectedUsers(null)
                    setBankName('')
                    setInvoiceDisbursementDetail([])

                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div  style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' ,height:'750px' }}>
              {invoicDialog == 'dataform' &&
               <div className="tw-modal-body w-full h-full p-10"
              
              >
                <div className=" "
                 style={{
                  maxWidth:'18px',
                  minWidth:'900px',
                  height:"700px"
                }}>
                 <div className="grid grid-rows gap-[22px] flex-grow ">
                                   
                 <div className="flex items-start ">
                                        <label htmlFor="selectedYear" className={`input-label mt-3 mr-7 ${invoiceformData.selected_year_error ? "text-red-500" : ""}`}>Month<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedYear" className={` w-1/3 ${invoiceformData.selected_year_error ? "border-red-500" : ""}`}>
                                       <Select
                                                key={invoiceformData.selectedMonth}
                                                options={months}
                                                value={months.find((month) => month.value === invoiceformData.selectedMonth)}
                                                onChange={handleMonthChange}
                                                placeholder="Select Month"
                                                styles={customStyles(invoiceformData.selected_month_error)}
                                                className="w-full text-sm py-2"
                                            /></div>
                     <p className="text-red-500 text-sm ml-2 mt-5">
                      {invoiceformData.selected_month_error}
                    </p>
                                    
                  </div>

                  <div className="flex items-start ">
                                        <label htmlFor="selectedYear" className={`input-label mt-3 mr-10 ${invoiceformData.selected_year_error ? "text-red-500" : ""}`}>Year<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedYear" className={` w-1/3 ${invoiceformData.selected_year_error ? "border-red-500" : ""}`}>
                                        <Select
                                                key={invoiceformData.selectedYear}
                                                options={years}
                                                value={years.find((year) => year.value === invoiceformData.selectedYear)}
                                                onChange={handleYearChange}
                                                placeholder="Select Year"
                                                styles={customStyles(invoiceformData.selected_year_error)}

                                            />

                                        </div><p className=" text-red-500 text-sm ml-2 mt-5">{invoiceformData.selected_year_error}</p>

                                    </div>
                                    </div></div>
                                    <div className="flex w-full items-end justify-end space-x-4"
>

  <Button 
    iconPos="right" 
    icon="pi pi-arrow-circle-right" 
    label="Next" 
    onClick={() =>{ 
     getInvoiceCasesData()
    }} 
  />
</div>

               </div>
               
      }  
              {invoicDialog == 'table' &&<div className="tw-modal-body w-full h-full p-10">
                <div className=""
                 style={{
                  maxWidth:"1800px",
                  minWidth:'900px',
                  height:"700px"
                }}>
<div className="space-y-6">

<span>Please select cases to generate in invoice.
</span><br></br>
<span className="font-bold">
Note:- Once case is assigned to this invoice it won't be editable or will not be assigned to any other invoice.</span>
</div>
              <div className="gap-5 space-y-5 w-full h-full mt-5">
              <DataTable
              ref={dt}
              value={invoicedisbursementDetail}
              lazy
              dataKey="id"
              // showGridlines
              // paginator
              // first={lazyState.first}
              // filters={lazyState.filters}
              // onFilter={onFilter}
              // rows={lazyState.rows}
              // rowsPerPageOptions={[10, 20, 30, 40, 50]}
              // onPage={onPage}
              loading={loader}
              size={"small"}
              scrollable
              selection={selectedUsers}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
              scrollHeight="600px"
              className="custom-datatable"
              emptyMessage="No records found."
              // height={'600px'}
            >
              <Column selectionMode="multiple" frozen />
              <Column field="SRNO" header="SR.NO." />
              
              <Column field="BorrowerName" header="Borrower Name" />
              <Column field="Loanamount" header="Loan Amount" />
              <Column
                field="Financer"
                header="Financer"
                
              />
              <Column
                field="Product"
                header="Product"
                
              />
              
              {/* ) : null} */}
            </DataTable>
              </div>
              </div>
              {/* <div className="flex w-full lg:w-[unset] items-center lg:justify-start md:space-x-9 space-y-5 md:space-y-0">
          <Button iconPos="right" icon='pi pi-arrow-circle-right' label="Back"
          onClick={()=>{setInvoiceDialog('form')}}
          /></div> 
          <div className="flex w-full lg:w-[unset] items-center lg:justify-end md:space-x-9 space-y-5 md:space-y-0">
          <Button iconPos="right" icon='pi pi-arrow-circle-right' label="Next"
          onClick={()=>{setInvoiceDialog('preview')}}
          /></div>  */}
<div className="flex justify-between items-end "style={{
                  // width:"1800px",
                }}>
              <Button iconPos="right" icon='pi pi-arrow-circle-left' label="Back"
          onClick={()=>{
            setInvoiceDialog('dataform')
          }}
          />
          <Button iconPos="right" icon='pi pi-arrow-circle-right' label="Next"
          onClick={()=>{
            setInvoiceDialog('form')
          }}
          /></div>
              </div>}
              {invoicDialog == 'form' &&
               <div className="tw-modal-body w-full h-full p-10"
              
              >
                <div className=" "
                 style={{
                  maxWidth:'1865px',
                  minWidth:'900px',
                  height:"700px"
                }}>
                  <div className="space-y-6">

                <span>Please enter required information.</span><br></br>
               <span className="font-bold">
Note:- This invoice number provided will be assigned to generated invoice.</span>
                </div>

                 <div className="grid grid-rows gap-[22px] flex-grow mt-10">
                                   
                                    <div className="flex items-start ">
                      <label
                      htmlFor="borrower_name"
                      className={`input-label mt-3 w-1/5 ${
                        invoiceformData.invoice_no_error ? "text-red-500" : ""
                      }`}
                    >
                      Invoice No.<span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_name"
                      className={`input-wrap w-1/3 ${
                        invoiceformData.invoice_no_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="borrower_name"
                        value={invoiceformData.invoice_no}
                        // onInput={handleNumericInput}
                        onChange={(e: any) => {
                          setInvoiceFormData({
                            ...invoiceformData,
                            invoice_no: e.target.value,
                            invoice_no_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Invoice No."
                      />
                    </div> <p className="text-red-500 text-sm ml-2 mt-5">
                      {invoiceformData.invoice_no_error}
                    </p>
                                    
                  </div>

                  <div className="flex items-start ">
                                        <label htmlFor="selectedYear" className={`input-label mt-3 w-1/5 ${invoiceformData.selected_date_error ? "text-red-500" : ""}`}>Invoice Date<span style={{color:'red'}}>*</span></label>
                                        <div id="selectedYear" className={`input-wrap w-1/3 ${invoiceformData.selected_date_error ? "border-red-500" : ""}`}>
                                        <DatePicker
                                                key={invoiceformData.selectedDate}
                                                selected={invoiceformData.selectedDate}
                                                onChange={(date) => setInvoiceFormData({...invoiceformData,selectedDate:date,selected_date_error:null})}
                                                dateFormat="dd-MM-yyyy"
                                                showFullMonthYearPicker
                                            />
                  </div>                                    
                                    <p className=" text-red-500 text-sm ml-2 mt-5">{invoiceformData.selected_date_error}</p>

                </div>

                 <div className="flex items-start ">
                      <label
                      htmlFor="borrower_name"
                      className={`input-label mt-3 w-1/5 ${
                        invoiceformData.firm_name_error ? "text-red-500" : ""
                      }`}
                    >
                      Firm name(GST Registered ) <span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_name"
                      className={`input-wrap w-1/3 ${
                        invoiceformData.firm_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <input
                        type="text"
                        name="borrower_name"
                        value={invoiceformData.firm_name}
                        onInput={handleAlphaNumericInput}
                        onChange={(e: any) => {
                          setInvoiceFormData({
                            ...invoiceformData,
                            firm_name: e.target.value,
                            firm_name_error: null,
                          });
                        }}
                        className="w-full text-sm"
                        placeholder="Enter Firm Name"
                      />
                    </div> <p className="text-red-500 text-sm ml-2 mt-5">
                      {invoiceformData.firm_name_error}
                    </p>
                                    
                  </div>
                  <div className="flex items-start ">
                      <label
                      htmlFor="borrower_name"
                      className={`input-label mt-3 w-1/5 ${
                        invoiceformData.address_error ? "text-red-500" : ""
                      }`}
                    >
                     Address(GST Registered ) <span style={{color:'red'}}>*</span>
                    </label>
                    <div
                      id="borrower_name"
                      className={` w-1/3 ${
                        invoiceformData.address_error ? "border-red-500" : ""
                      }`}
                    >
                      <InputTextarea autoResize
                        name="borrower_name"
                        value={invoiceformData.address}
                        // onInput={handleNumericInput}
                        onChange={(e: any) => {
                          setInvoiceFormData({
                            ...invoiceformData,
                            address: e.target.value,
                            address_error: null,
                          });
                        }}
                        className={`w-full text-sm ${
                        invoiceformData.address_error ? "border-red-500" : ""
                      }`}
                        placeholder="Enter Address"

                      />
                    </div> <p className="text-red-500 text-sm ml-2 mt-5">
                      {invoiceformData.address_error}
                    </p>
                                    
                  </div>
                                    </div>
                              </div>
                                    <div className="flex justify-between items-end "style={{
                  // width:"1800px",
                }}>
              <Button iconPos="right" icon='pi pi-arrow-circle-left' label="Back"
          onClick={()=>{
            setInvoiceDialog('table')
          }}
          />
          <Button iconPos="right" icon='pi pi-arrow-circle-right' label="Next"
          onClick={()=>{
            previewPDF()
          }}
          />
          

              </div>                
               </div>
               
      }  
                      
              { invoicDialog == 'preview' && <div className="tw-modal-body w-full h-full p-10">

              <div className=""
              style={{
                maxWidth:'1865px',
                minWidth:'900px',
                height:"700px"

              }}>
              {pdfData && 
        <div>
          <h3>PDF Preview:</h3>
          <iframe
            src={pdfData}
            title="PDF Preview"
            style={{
              maxWidth:'1865px',
              minWidth:'900px',
              height:"700px",
              width:'100%'
            }}
          /></div>}
              </div>
              <div className="flex w-full items-end justify-between "  style={{
               }}>
  <Button 
    iconPos="left" 
    icon="pi pi-arrow-circle-left" 
    label="Back" 
    onClick={() => setInvoiceDialog('form')} 
  />
  <Button 
    iconPos="right" 
    icon="pi pi-arrow-circle-right" 
    label="Verify Invoice" 
    onClick={() =>{ 
      sendOtpVerificationMail()
    }} 
  />
</div>
              </div>
              
}
{ invoicDialog == 'otp' &&

<div
    className="tw-modal-body w-full h-full p-10"
   
  >
      <div style={{ fontSize: "18px", fontWeight: "bold" }}>OTP Verification</div>
      
      {/* START::HEADER */}
      <div className="gap-5 space-y-5 w-1/2">
        <div className="space-y-1">
        <label htmlFor="otp" className="input-label flex items-center justify-center"> Your otp has been send to your registered Email </label>

        </div>
        <div className="space-y-1">
          <div
            id="otp"
            className="p-[18px] border border-[#DEDEDE] flex items-center space-x-2 rounded-lg"
          >
            <img src="assets/email.svg" alt="Email Icon" />
            <input
              type="text"
              className="w-full text-sm"
              placeholder="Enter OTP"
              name="otp"
              value={otp}
              onChange={(e)=>{
                setOtp(e.target.value)
              }}
            />
          </div>
        </div>
        <div className="flex justify-between space-x-8 ">
        <Button label="Re-Generate Otp"
            onClick={()=>{
              sendOtpVerificationMail()
            }}
          />
            
          <Button label="Verify Otp"
            onClick={()=>{
              verifyOtp()
            }}
          />
        </div>
      
      </div>
      {/* END::HEADER */}
  </div>

}

{ invoicDialog == 'download' &&
  <div
    className="tw-modal-body w-full h-full p-10"
   
  >
      <div style={{ fontSize: "18px",}}>Your invoice is succesfully generated. 
      </div>
  Please click here to download the invoice:{" "}
  <button
    onClick={() => {
    downloadPDF()
      
    }}
    style={{
      color: "blue",
      textDecoration: "underline",
      background: "none",
      border: "none",
      cursor: "pointer",
      padding: 0,
    }}
  >
    Download
  </button>
      

  </div>


}
              </div>

                        {/* {invoicDialog == 'table' && <div className="m-5">
                          <div className="flex w-full items-center justify-between space-x-4">
  <Button 
    iconPos="left" 
    icon="pi pi-arrow-circle-left" 
    label="Back" 
    onClick={() => setInvoiceDialog('form')} 
  />
  <Button 
    iconPos="right" 
    icon="pi pi-arrow-circle-right" 
    label="Next" 
    onClick={() =>{ setInvoiceDialog('preview')
          previewPDF()    }} 
  />
</div></div>
} */}
           
              
            </div>
          </div>
          <div
            className={`tw-modal ${viewInvoice ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Invoice</div>
                <button
                  onClick={() => {
                    setViewInvoice(false)
                    setInvoicePath('')
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">                
               
              {selectedRowData.current?.invoice_path ?  selectedRowData.current?.invoice_path?.split(".").pop() == "pdf" ?
 
 <iframe
   src={`data:application/pdf;base64,${invoicePath}`}
   title="Preview PDF"
   width="100%"
   height="1000px"
 />
 :
 <img
  style={{ height: "500px", width: "500px" }}
  src={`data:image/jpeg;base64,${invoicePath}`}
  className="ml-5 object-center object-cover"
/>   
 : null}    
                       
              </div>

             </div>
          </div>

          </>
          }
        </>


      )}
    </div>
  );
};

export default DisbursementData;
