import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useRef, useState } from "react";
import './DisbursementDetailsModal.css'
import { useLocation, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import axios from "axios";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import _ from "lodash";
import { Fieldset } from "primereact/fieldset";
import { InputSwitch } from "primereact/inputswitch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Accordion, AccordionTab } from "primereact/accordion";
import { SplitButton } from "primereact/splitbutton";
import { SpeedDial } from "primereact/speeddial";
import permissionData from '../../permission.json'
import { decodeToken } from "../../helper/util";
const DisbursementDetailsModal = () => {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  let { id } = useParams();
  const disbursementData = {

    dsa_name_error: null,
    BorrowerName_error: null,
    lan_no_error: null,
    Loanamount_error: null,
    pincode_error: null,
    Financer_error: null,
    product_error: null,
    // pan_no_error: null,
    contact_person_name_error: null,
    month_of_disbursement_error: null,
    mobile_error: null,
    is_connector: false,
    rate: 0,
    connector_error: null,
    connector_rate_error: null,
    adjustment_amount: 0,
    reason_error: null,
    state_error: null,
    city_error: null,

  };
  const [userPayload,setUserPayload] = useState(null)
  const [formData, setFormData] = useState<any>({ disbursementData });
  const [oldData,setOldData]=useState<any>({ disbursementData });
  const [BankMisData, setBankMisData] = useState(null);
  const [ReverseMisData, setReverseMisData] = useState(null);
  const [ReverseMisConnectorData, setReverseMisConnectorData] = useState(null);
  const [connectorList, setConnectorList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [bankData, setBankData] = useState<any>({});
  const [isEdit, setIsEdit] = useState(true)
  const [isconnector, setConnector] = useState(false);
  const [permission, setPermission] = useState<any>({});
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
    const [disableField, setDisableField] = useState(false);
    const [isSmallScreen, setIsSmallScreen]  = useState(window.innerWidth <= 1300);
    const [open, setOpen] = useState(false);
   const [item, setItem] = useState<any>({
    gross_payout: 0,
    product: "",
    bankname: "",
    payoutpercentage: 0,
    });
      const [approvePayment,setApprovePayment]=useState(false)
      const [viewProof,setViewProof]=useState(false)
      const [proofPath,setProofPath]=useState('')
      const paymentdata={
        // month:'',
        // year: '',
                file_sequence_num:'',
                // pymt_prod_type_code: '',
                // pymt_mode: '',
                // debit_acct_no: '',
                // beneficiary_name: '',
                // beneficiary_account_no: '',
                // bene_ifsc_code: '',
                amount: '',
                // debit_narration: '',
                // credit_narration: '' ,
                // mobile_number: '',
                // email_id: '',
                // remark: '',
                pymt_date: new Date(),
                // addl_info1: '',
                // status: '',
                // current_step: '',
                // file_name: '',
                acct_debit_date: new Date(),
                customer_ref_no: '',
                // utr_no: '',
                // month_error:null,
                // year_error:null,
                file_sequence_num_error:null,
                // pymt_prod_type_code_error:null,
                // pymt_mode_error:null,
                // debit_acct_no_error:null,
                // beneficiary_name_error:null,
                // beneficiary_account_no_error:null,
                // bene_ifsc_code_error:null,
                amount_error:null,
                // debit_narration_error:null,
                // credit_narration_error:null ,
                // mobile_number_error:null,
                // email_id_error:null,
                // remark_error:null,
                pymt_date_error:null,
                // addl_info1_error:null,
                // status_error:null,
                // current_step_error:null,
                // file_name_error:null,
                acct_debit_date_error:null,
                customer_ref_no_error:null,
                // utr_no_error:null
    
      };
      const [paymentForm,setPaymentForm]=useState<any>(paymentdata);
    const [sendMailDialog, setSendMailDialog] = useState(false);
      let email_data={
        sm_manager_email:'',
        asm_manager_email:'',
        sm_manager_email_error:null,
        asm_manager_email_error:null,
      }
      const [emailData,setEmailData]=useState<any>(email_data)
  const [isCollapsed, setIsCollapsed] = useState({
    personal_info: true,
    dsa_personal_info: true,
    connector_personal_info: true,
    dsa_calculation: true,
    connector_calculation: true,
    calculation: true,
    bank_details: true,
    orenda_details: true,
    gen_info: true
  }); // Initially closed


  useEffect(()=>{
    setUserPayload(decodeToken(localStorage.getItem('token')));
  },[])

   useEffect(() => {
     
      const tempPermission = {
        [PERMISSION_TYPE.ADMIN]: userPermission(userData, PERMISSION_TYPE.ADMIN),
        [PERMISSION_TYPE.DSA]: userPermission(userData, PERMISSION_TYPE.DSA),
        [PERMISSION_TYPE.STAFF]: userPermission(userData, PERMISSION_TYPE.STAFF),
        [PERMISSION_TYPE.CONNECTOR]: userPermission(userData, PERMISSION_TYPE.CONNECTOR),
        [PERMISSION_TYPE.STAFF_OPS]: userPermission(userData, PERMISSION_TYPE.STAFF_OPS),
        [PERMISSION_TYPE.BOOKING_PARTNER]: userPermission(userData, PERMISSION_TYPE.BOOKING_PARTNER),
  
      };
      setPermission(tempPermission);
      getBankMisData();
      getDisbursementData()
      getLoginCodeList();
       getUser();
    }, [userData]);
    useEffect(() => {
      const handleResize = () => {
          setIsSmallScreen(window.innerWidth <= 1300);
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  
    
    const actions = [
      
      { label: "View Proof", command: () => {

          setViewProof(true)
          getImageData(formData?.advance_proof_path)
        
      } },
      { label: "Download Proof", command: () => {
        
                getDowloadProofLink(formData?.advance_proof_path)
              
      } 
      
      }
    ];
    useEffect(() => {
      //  getBankMisData()
      //  getLoginCodeList();
      //  getUser();
 
      },[BankMisData,formData]);

    const items =()=> {
      const menuItems = [];
      if((formData?.advance_request == false && (formData?.status != 'Completed' && formData?.status != 'Advance Completed'))){
        menuItems.push({
          icon:"pi pi-file-check",          
              label: "Advance Approval",
              command: () => handleMenuItemClick("Advance Approval"),
            
        })
      }

      
      if((userPayload?.user?.role=='admin' ) && (formData?.status != 'Completed') && ( (formData?.dsa_gst_no == null ||((formData?.dsa_gst_no != null || formData?.dsa_gst_no != "" || formData?.dsa_gst_no != " ") && formData?.dsa_invoice_id != null)) && (formData?.is_connector == false || ((formData?.is_connector == true) && ( formData?.connector_gst_no == null  || (formData?.connector_gst_no != null || formData?.connector_gst_no != '' || formData?.connector_gst_no != ' ') && formData?.connector_invoice_id != null))) ))
      {menuItems.push({
          label: 'Add Utr Details',
          icon: 'pi pi-building-columns',
          command: () => {
            handleMenuItemClick("Add Utr Details")          }
      })}
      if((userPayload?.user?.role=='admin'|| userPayload?.user?.role=='staff_sale' ||userPayload?.user?.role=='staff_operation' )&& ( formData?.advance_proof_path != "")){menuItems.push({
          label: 'View Proof',
          icon: 'pi pi-verified',
          command: () => { handleMenuItemClick("View Proof")
          }
      })}
      if((userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_sale' ||userPayload?.user?.role=='staff_operation' )&& ( formData?.advance_proof_path != "")){menuItems.push({
        label: 'Download Proof',
        icon: 'pi pi-verified',
        command: () => { handleMenuItemClick("Download Proof")
        }
    })}
      if((userPayload?.user?.role=='admin'  ||  userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='staff_operation' || userPayload?.user?.role=='dsa' || userPayload?.user?.role=='booking_partner') && (
        formData?.status === "Payment Pending" && (formData?.advance_status == 'pending' || formData?.advance_status =='' || formData?.advance_status =='reject'))){menuItems.push({
        label: 'Send Disbursal Confirmation',
        icon: 'pi pi-envelope',
        command: () => {
          handleMenuItemClick("Send Disbursal Confirmation")
        }
    })}
    if( (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation') &&(
      (formData?.status === "Payment Pending" ||
      formData?.status === "Mismatch Case") && (formData?.dsa_invoice_id ==null && formData?.connetor_invoice_id ==null)
    ) ){
      menuItems.push({
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {handleMenuItemClick("Delete")
        }
    })
    }

    return menuItems
    }
  
        const handleMenuItemClick = (itemLabel) => {
          
          if (itemLabel === "Advance Approval") {
            setItem({
              ...item,
              product: formData?.product,
              gross_payout: formData?.advance_gross,
              bankname: formData?.bank_name,
              payoutpercentage: formData?.rate,
            });
            setOpen(true);
          }
          if (itemLabel === "Delete") {
            setDeleteConfirmVisible(true)
          }
          if (itemLabel === "Add Utr Details") {
            setApprovePayment(true)
            setPaymentForm({...paymentForm,amount:formData?.net_amount_payable})
                  }
          if (itemLabel === "View Proof") {
              setViewProof(true)
              getImageData(formData?.advance_proof_path)

            
           }
           if (itemLabel === "Download Proof") {
              getDowloadProofLink(formData?.advance_proof_path)
            
            
           }
      
           if(itemLabel == 'Send Disbursal Confirmation'){
      
            setSendMailDialog(true)
            // setSelectedId(data?.id)
      
           }
        };
const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const accept = () => {
    // handleDelete(data?);
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };

  const handleNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
  };
  const handleBorrowerNameInput = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z\s.]/g, '');
  };
  const handleNumericInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const handleAdjustmentAmountInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9+\-\.]/g, "");
  };
  // useEffect(() => {
  //     getUser(), getLoginCodeList(),getBankMisData()
   

  // });
  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key]?.toString(),
      value: key,
    }));
  };

  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data?.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id,
          };
        });
        const bankData = _.groupBy(temp, function (b) {
          return b.bank;
        });
        setBankData(bankData);
      })
      .catch((error) => {
        setBankData({});
      });
  };

  const getBankMisData = () => {
    setLoader(true)
    ApiGet(`disbursement/get-BankMis?id=${id}`)
      .then((res: any) => {
        let finalData = res.data?.bankMis.length > 0 ? res.data?.bankMis[0] : null
        let dsadata= res.data?.reverseMis.length > 0 ? res.data?.reverseMis[0].addl_info3 == 'dsa' ? res.data?.reverseMis[0] : res.data?.reverseMis[1] : null
        let connectordata=res.data?.reverseMis.length > 0 ? res.data?.reverseMis[0].addl_info3 =='connector' ? res.data?.reverseMis[0] : res.data?.reverseMis[1] : null

        setBankMisData(finalData);
        setReverseMisData(dsadata)
        setReverseMisConnectorData(connectordata)
        setLoader(false)

      })
      .catch((error) => {
        setBankMisData([]);
        setLoader(false)

      });
  }

  const ConvertDate=(date)=>{
    let ndate=new Date(date)
    return ndate.toISOString() ;
  }
  const getDisbursementData = () => {
    setLoader(true)
    ApiGet(`disbursement/get-disbursement-by-id?id=${id}`)
          .then((res: any) => {
        let finalData = (res.data?.result).map((item)=>{
          return {
            id:item._id,
            dis_date:new Date(ConvertDate(item.disbursement_date)),
            ...item
          };
        })
        getConnector(finalData[0].dsa_id)
        setFormData(finalData[0])
        setOldData(finalData[0])
        setLoader(false)

      })
      .catch((error) => {
        setBankMisData([]);
        setLoader(false)

      });
  }

  const getConnector = (id) => {
    ApiGet(`connector/option-connector?id=${id}`)
      .then((res: any) => {
        let temp = res.data?.result.map((item: any, index: number) => {
          return {
            id: item._id,
            label: `${item.first_name} ${item.middle_name || ''} ${item.last_name} - ${item.email}`,
          };
        });
        setConnectorList(temp);
      })
      .catch((error) => {
        setConnectorList([]);
      });
  };
  const getUser = () => {
    ApiGet(`user/option-users`)
      .then((res: any) => {
        const users = convertToObjectArray(res.data?.users);

        setUserList(users);
      })
      .catch((error) => {
        setUserList([]);
      });
  };
  const editDisbursement = () => {
      let updatedForm = { ...formData,disbursement_date:formData.dis_date };

      for (let key in updatedForm) {
        if (typeof updatedForm[key] == "string") {
          updatedForm[key] = updatedForm[key].trim();
        }
      }
      let namePattern = /^[A-Za-z\s]+$/;
      let mobilePattern = /^\+?[0-9]{10,}$/;
      if (formData.dsa_id == "") {
        updatedForm = {
          ...updatedForm,
          dsa_name_error: "Please select DSA name",
        };
      }
      if ((userPayload?.user?.role!='staff_sale') && (userPayload?.user?.role!='admin') && (userPayload?.user?.role!='staff_operation')) {
        updatedForm = {
          ...updatedForm,
          dsa_name_error: null,
        };
      }
      else{
        if(formData.dsa_id == ""){
          updatedForm = {
            ...updatedForm,
            dsa_name_error: 'Please select a DSA',
          };
        }
      }
      if (formData.borrower_name == "") {
        updatedForm = {
          ...updatedForm,
          BorrowerName_error: "Please enter borrower name",
        };
      }
         if (formData.loan_amount == "") {
        updatedForm = {
          ...updatedForm,
          Loanamount_error: "Please enter a loan amount",
        };
      }
      // if (formData.borrower_pincode == "") {
      //   updatedForm = { ...updatedForm, pincode_error: "Please enter a pincode" };
      // }
      if (formData.borrower_pincode == "") {
        updatedForm = {
          ...updatedForm,
          pincode_error: "Please enter a Pincode",
        };
      }
      let pincodePattern = /^(\d{6})$/;
      if (
        formData.borrower_pincode != "" &&
        !pincodePattern.test(formData.borrower_pincode)
      ) {
        updatedForm = {
          ...updatedForm,
          pincode_error: "Please enter a valid pincode",
        };
      }
      if((formData.adjustment_amount === "0.00" || formData.adjustment_amount == "") && formData.reason == "" ){
        updatedForm = { ...updatedForm, reason_error: null };
      }
      if((formData.adjustment_amount != ''  && formData.adjustment_amount != '0.00')&& formData.reason == "" ){
        updatedForm = { ...updatedForm, reason_error:  "Please enter a reason"};
      }
      if(formData.adjustment_amount != '' && formData.reason != "" ){
        updatedForm = { ...updatedForm, reason_error: null};
      }
  
      if (formData.bank_name == "") {
        updatedForm = {
          ...updatedForm,
          Financer_error: "Please select  Bank",
        };
      }
      if (formData.bank_name == "" && formData.logincodelist_id == "") {
        updatedForm = { ...updatedForm, product_error:null };
      }
      if (formData.bank_name != "" && formData.logincodelist_id == "") {
        updatedForm = { ...updatedForm, product_error: "Please select product" };
      }
      if (formData.disbursement_date == null) {
        updatedForm = {
          ...updatedForm,
          month_of_disbursement_error: "Please enter a Month",
        };
      }
      if ((formData.connector_id == null || formData.connector_id == '') && formData.is_connector ) {
        updatedForm = {
          ...updatedForm,
          connector_error: "Please enter a connector",
        };
      }
      if (formData.is_connector && (formData.connector_rate == '' || formData.connector_rate == null)) {
        updatedForm = {
          ...updatedForm,
          connector_rate_error: "Please enter a connector rate",
        };
      }
      // if (formData.borrower_contact_person_number == "") {
      //   updatedForm = { ...updatedForm, mobile_error: "Please enter a mobile no." };
      // }
      if (
        formData.borrower_contact_person_number != "" &&
        !mobilePattern.test(formData.borrower_contact_person_number)
      ) {
        updatedForm = {
          ...updatedForm,
          mobile_error: "Please enter a valid mobile no.",
        };
      }
      setFormData(updatedForm);
      updatedForm = {
        ...updatedForm,
        adjustment_amount:
          updatedForm.adjustment_amount === ""
            ? "0.00"
            : updatedForm.adjustment_amount,
      };
      if (
        updatedForm.BorrowerName_error == null &&
        updatedForm.Loanamount_error == null &&
        updatedForm.pincode_error == null &&
        updatedForm.Financer_error == null &&
        updatedForm.product_error == null &&
        updatedForm.month_of_disbursement_error == null &&
        updatedForm.connector_error == null &&
        updatedForm.connector_rate_error == null &&
        updatedForm.mobile_error == null &&
        updatedForm.reason_error == null 
      ) {
        const {
          BorrowerName_error,
          lan_no_error,
          Loanamount_error,
          pincode_error,
          Financer_error,
          product_error,
          month_of_disbursement_error,
          connector_error,connector_rate_error,
          contact_person_name_error,
          mobile_error,
          reason_error,
          ...payload
        } = updatedForm;
        let finalData = payload;
        
        ApiPut(`disbursement/edit-disbursement`, finalData)
          .then((res: any) => {
            getDisbursementData()
            if (res?.status == 200) {
              toast.success("Data updated successfully!!", {
                position: "top-right",
                theme: "colored",
              });
            }
          })
          .catch((error) => {});
      }
      
    };
  
  const postget = async (e: any) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      borrower_pincode: e.target.value,
      pincode_error: null,
      state_error: null,
      city_error: null,
    });
    if (value.length >= 6) {
      const options = {
        method: "GET",
        url: `https://india-pincode-with-latitude-and-longitude.p.rapidapi.com/api/v1/pincode/${value}`,
        headers: {
          "X-RapidAPI-Key":
            "b2572becadmshbaf647a375178edp179823jsneae8fa2a8334",
          "X-RapidAPI-Host":
            "india-pincode-with-latitude-and-longitude.p.rapidapi.com",
        },
      };
      await axios.request(options).then((res: any) => {
        setFormData({
          ...formData,
          state: res?.data[0]?.state,
          city: res?.data[0]?.district,
          borrower_pincode: value,
        });
      });
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Payment Pending":
        return " text-blue-500"; // Valid Tailwind class
      case "Mismatch Case":
        return " text-red-500"; // Valid Tailwind class
      case "Advance Completed":
        return " text-red-500"; // Valid Tailwind class
      case "Billing Received":
        return " text-blue-500"; // Updated to a valid Tailwind class
      case "Completed":
        return " text-[#4caf50]";
    }
  };

  const actionYes = async () => {
      await ApiPut(`disbursement/edit-disbursement?id=${id}`, {
        advance_request: true,
        is_visible_icon: true,
        cheker_type: "maker",
      })
        .then((res: any) => {
          setOpen(false);
          if(res){
            toast.success("Request sent successfully", {
            position: "top-right",
            theme: "colored"
          })
          }       
        })
        .catch((error: any) => {
          // toast.error("Something went wrong!", {
          //   position: "top-right",
          //   theme: "colored"
          // })
        });
    };

     const getImageData = async (path: any) => {
        if (path != undefined) {
          await ApiPost(`disbursement/get-proof-path`, {
            path,
          }).then((res: any) => {
            setViewProof(true)
              setProofPath(res.data?.result);
          });
        } else {
          toast.error("Doc not uploaded", {
            position: "top-right",
            theme: "colored",
          });
        }
      };
      
      const sendMail= ()=>{
              let updatedform={...emailData}
              let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        
              if(updatedform.sm_manager_email == ''){
                updatedform = { ...updatedform, sm_manager_email_error: "Please enter a Email id" };
              }
              if (updatedform.sm_manager_email != '' && !emailPattern.test(updatedform.sm_manager_email)) {
                updatedform = { ...updatedform, sm_manager_email_error: "Please enter a valid email" };
            }
              // if(updatedform.asm_manager_email == ''){
              //   updatedform = { ...updatedform, asm_manager_email_error: "Please select Email id " };
              // }
              // if(updatedform.asm_manager_email != '' && !emailPattern.test(updatedform.asm_manager_email)){
              //   updatedform = { ...updatedform, asm_manager_email_error:"Please enter a valid email" };
              // }
              setEmailData(updatedform)
              let payload={
                sm_email:updatedform.sm_manager_email,
                asm_email:updatedform.asm_manager_email,
                case_id:id,
              }
              if(updatedform.sm_manager_email_error == null ){
                ApiPost(`disbursement/send-mail`,payload).then((res: any) => {
                 if(res.status == 200){
                  toast.success(
                    res.data?.message,
                    {
                      position: "top-right",
                      theme: "colored",
                    }
                  );
                 }
                 setSendMailDialog(false)
                  setEmailData(email_data)
                  
                }).catch((error)=>{
                  //  console.log(error)
                });
              }
              
            }
      const getDowloadProofLink = async (path: any) => {
              if (path != undefined) {
                await ApiPost(`invoiceDetails/download-invoice`, {
                  path,
                }).then((res: any) => {
                  const linkElement = document.createElement("a");
                  linkElement.href = res.data?.result;
                  linkElement.click();
                }).catch((error)=>{
                  //  console.log(error)
                });
              } else {
                toast.error("Doc not uploaded", {
                  position: "top-right",
                  theme: "colored",
                });
              }
            };
   
            return (
    <div className="overflow-x-hidden ">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>


          <div className="p-6 space-y-6 bg-gray-100 overflow-y-auto ">
            <div className="border rounded-lg shadow-md bg-white w-full max-w-full">
              {
              userPayload?.user?.role!='connector' && <>
           
              <div className="flex items-center flex-wrap justify-between pt-5 pr-5 pl-5 sm:gap-3 md:gap-3 ">
                <div className="flex items-center gap-3 ">
                {( ( userPayload?.user?.role=='dsa' && ( (formData?.status === "Payment Pending" && formData?.advance_request === false )))               
                || (  userPayload?.user?.role!='dsa'  && ((formData?.status === "Billing Received"  || formData?.status === "Mismatch Case"|| formData?.status === "Payment Pending" )  && (formData?.dsa_invoice_id == null && formData?.connector_invoice_id == null) && formData?.system_adjustment == 0.0 )) )
                &&  <><Button label="Edit" icon="pi pi-pen-to-square" className=" text-white py-2 px-6 rounded-lg "
                    onClick={() =>{
                      
                      if ((formData?.status === "Billing Received") || (formData?.status === "Payment Pending" && formData?.advance_status === "approved" ) ) {
                        setDisableField(true);
                      }
                      setIsEdit(false) 
                     }                    
                    }
                    /></>}  
                    
                 { !isEdit && <><Button label="Save" icon="pi pi-bookmark" className="  text-white py-2 px-6 rounded-lg "
                      onClick={() => editDisbursement()} />
                      <Button label="Cancel" icon="pi pi-times" severity="secondary" outlined className="py-2 px-6 rounded-lg"
                        onClick={() =>{ setIsEdit(true)
                          setFormData(oldData)}
                        } /></>
}
                  
                </div>
                <div className="card flex justify-center lg:hidden"
                               style={{ display: isSmallScreen ? "flex" : "none" }}
                >
            <SplitButton label='Actions' model={items()}  />
        </div>
        {/* <div className=" card flex justify-center sm:flex md:flex lg:hidden xl:hidden" style={{ position: 'relative'}}>
        <SpeedDial model={items()} radius={140} type="quarter-circle" direction="down-left" style={{  left: '-60px', top: '-24px',bottom:'5px' }} />

</div> */}
                <div className="hidden lg:flex items-center gap-3 justify-end"
                                style={{ display: isSmallScreen ? "none" : "flex" }}
                >
                  { (formData?.advance_request == false && (formData?.status != 'Completed' && formData?.status != 'Advance Completed'))  &&  <Button label="Advance Approval" icon="pi pi-file-check" className=" text-white py-2 px-4 rounded-lg "
                    onClick={() => {
                      setItem({
                        ...item,
                        product: formData?.product,
                        gross_payout: formData?.advance_gross,
                        bankname: formData?.bank_name,
                        payoutpercentage: formData?.rate,
                      });
                      setOpen(true);
                
                    }                    
                    }
                   
                    />}

{ (userPayload?.user?.role=='admin' ) && (formData?.status != 'Completed') && ( (formData?.dsa_gst_no == null ||((formData?.dsa_gst_no != null || formData?.dsa_gst_no != "" || formData?.dsa_gst_no != " ") && formData?.dsa_invoice_id != null)) && (formData?.is_connector == false || ((formData?.is_connector == true) && ( formData?.connector_gst_no == null  || (formData?.connector_gst_no != null || formData?.connector_gst_no != '' || formData?.connector_gst_no != ' ') && formData?.connector_invoice_id != null))) ) &&
                  <Button label="Add Utr Details" icon="pi pi-building-columns"  className="py-2 px-4 rounded-lg"
                   onClick={() => {
                    setApprovePayment(true)
        setPaymentForm({...paymentForm,amount:formData?.net_amount_payable})
    
                   }                    
                   }/>}
                   { (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_sale' ||userPayload?.user?.role=='staff_operation' )&& ( formData?.advance_proof_path != "") &&
                  // <Button label="Advance Proof" icon="pi pi-verified"   className="py-2 px-4 rounded-lg"
                  //  onChange={() => setIsEdit(false)                      
                  //  }/>
                   <SplitButton
                   icon="pi pi-verified"
                   label="Advance Proof"
                   model={actions}
                   className="p-button-primary"
                 />
                   }
{ (userPayload?.user?.role=='admin'  ||  userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='staff_operation' || userPayload?.user?.role=='dsa' || userPayload?.user?.role=='booking_partner') && (
        formData?.status === "Payment Pending" && (formData?.advance_status == 'pending' || formData?.advance_status =='' || formData?.advance_status =='reject'))  &&
                  <Button label="Send Dis. Mail" icon="pi pi-envelope" className="  text-white py-2 px-4 rounded-lg "
                    onClick={() =>         setSendMailDialog(true)
                    }
                  />
}
{ (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation') &&(
        (formData?.status === "Payment Pending" ||
          formData?.status === "Mismatch Case") && (formData?.dsa_invoice_id ==null && formData?.connector_invoice_id ==null)
      ) &&
                  <Button label="Delete" severity='danger' icon="pi pi-trash" className="  text-white py-2 px-4 rounded-lg "
                    onClick={() =>        setDeleteConfirmVisible(true)
                    }
                  />
}
                </div>
                
                
        </div>      
        </>}
              
              <div className="p-10 border-b border-gray-200">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-row gap-1 p-10 bg-[#F5F5F5] border border-gray-300 rounded-lg shadow-md">
                  {/** DSA Selection */}

                  {(userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation' ||
                                userPayload?.user?.role=='staff_sale' || userPayload?.user?.role=='booking_partner') && <div className="flex sm:flex-row items-center ">
                    <label
                      htmlFor="connector"
                      className={`text-lg font-semibold ${formData.dsa_name_error ? " text-red-500" : " text-gray-800 "} w-1/3`}
                    >
                      DSA <span className="text-red-500">*</span>
                    </label>
                    <select
                      id="connector"
                      name="connector"
                      value={formData.dsa_id}
                      onChange={(e) => {
                        getConnector(e.target.value);
                        setFormData({ ...formData, dsa_id: e.target.value, dsa_name_error: null });
                      }}
                      className={`border rounded-2xl px-3 py-2  shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3  ${formData.dsa_name_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                      } ${isEdit || disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                      disabled={isEdit ||disableField} 
                    >
                      <option value="">Select</option>
                      {userList.map((y: any) => (
                        <option key={y.value} value={y.value}>
                          {y.label}
                        </option>
                      ))}
                    </select>
                    <p className="text-red-500 text-sm ">{formData.dsa_name_error}</p>
                  </div>}

                  {/** Borrower Name */}
                  <div className="flex sm:flex-row items-center ">
                    <label
                      htmlFor="borrower_name"
                      className={`text-lg font-semibold ${formData.BorrowerName_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Borrower Name<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="borrower_name"
                      value={formData.borrower_name}
                      onChange={(e) =>
                        setFormData({ ...formData, borrower_name: e.target.value, BorrowerName_error: null })
                      }
                      placeholder="Enter Borrower Name"
                      className={`border rounded-2xl px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 ${formData.BorrowerName_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                        } ${(isEdit || disableField) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                      disabled={isEdit || disableField}
                    />
                      <p className="text-red-500 text-sm ">{formData.BorrowerName_error}</p>
                  </div>

                  {/** Month of Disbursement */}
                  <div className="flex sm:flex-row items-center ">
                    <label
                      htmlFor="month_of_disbursement"
                      className={`text-lg font-semibold ${formData.month_of_disbursement_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Disbursement<span className="text-red-500">*</span>
                    </label>
                    <DatePicker
                                            selected={formData.dis_date}
                                            onChange={(date: any) =>{
                                              setFormData({
                                                ...formData,
                                                dis_date: date,
                                                month_of_disbursement_error: null,
                                              })}
                                            }
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                            className={`border rounded-2xl px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 w-full sm:w-9/10  ${formData.month_of_disbursement_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                                            } ${(isEdit || disableField) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}                     
                                            disabled={disableField || disableField}
                                          />
                      <p className="text-red-500 text-sm ">{formData.month_of_disbursement_error}</p>
                  </div>

                  {/** bank_name */}
                  <div className="flex sm:flex-row items-center ">
                    <label
                      htmlFor="bank_name"
                      className={`text-lg font-semibold ${formData.Financer_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      bank_name<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="bank_name"
                      value={formData.bank_name}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          bank_name: e.target.value,
                          Financer_error: null,
                          product: "",
                        })
                      }
                      className={`border rounded-2xl px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 ${formData.Financer_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                      } ${(isEdit || disableField) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                      disabled={isEdit || disableField}
                    >
                      <option value="">Select Bank/NBFC</option>
                      {Object.keys(bankData).map((bank, id) => (
                        <option key={id} value={bank}>
                          {bank}
                        </option>
                      ))}
                    </select>                    
                      <p className="text-red-500 text-sm ">{formData.Financer_error}</p>
                  </div>

                  {/** product */}
                  <div className="flex sm:flex-row items-center ">
                    <label
                      htmlFor="product"
                      className={`text-lg font-semibold ${formData.product_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      product<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="product"
                      value={formData.logincodelist_id}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          logincodelist_id: e.target.value,
                          product_error: null,
                        })
                      }
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 ${formData.product_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
                      } ${(isEdit || disableField) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                      disabled={isEdit || disableField}
                    >
                      <option value="">Select product</option>
                      {bankData?.[formData.bank_name]?.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.product}
                        </option>
                      ))}
                    </select>                    
                      <p className="text-red-500 text-sm ">{formData.product_error}</p>
                  </div>

                  {/** Loan Amount */}
                  <div className="flex sm:flex-row items-center ">
                    <label
                      htmlFor="loan_amount"
                      className={`text-lg font-semibold ${formData.Loanamount_error ? "text-red-500" : "text-gray-800"} w-1/3`}
                    >
                      Loan Amount<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="loan_amount"
                      value={formData.loan_amount}
                      onChange={(e) =>
                        setFormData({ ...formData, loan_amount: e.target.value, Loanamount_error: null })
                      }
                      placeholder="Enter Loan Amount"
                      className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 ${formData.Loanamount_error ? " border-red-500 focus:ring-red-500" : " border-gray-300 focus:ring-blue-500 "
                      } ${(isEdit || disableField) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                      disabled={isEdit || disableField}
                    />                    
                      <p className="text-red-500 text-sm ">{formData.Loanamount_error}</p>
                  </div>

                  {/** Payment Status */}
                  <div className="flex sm:flex-row items-center ">
                    <h3 className={`text-lg font-semibold  text-gray-800 w-1/3` }>Payment Status  : </h3>
                    <input disabled className={` border font-semibold rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 border-gray-300 focus:ring-blue-500 bg-gray-50  ${getStatusColor(formData?.status)}`} value={`${formData?.status} ${formData?.co_status == 1 ? ' - Mismatch' : ''}`} />
                  </div>                  

                  <div className="flex sm:flex-row items-center ">
                    <h3 className={`text-lg font-semibold  text-gray-800 w-1/3 `}>Advance Request  :   </h3>
                    <input disabled className={`border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 border-gray-300 focus:ring-blue-500 bg-gray-50 text-gray-500`} value={formData?.advance_request} />
                  </div>

                  <div className="flex sm:flex-row items-center  ">
                    <h3 className={`text-lg font-semibold  text-gray-800 w-1/3 `}>Advance Status  :   </h3>
                    <input disabled className={` border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 border-gray-300 focus:ring-blue-500 bg-gray-50 text-gray-500  `} value={formData?.advance_status} />
                  </div>

                </div>

                {/* <div className="flex justify-end">
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md">
          save 
        </button>
      </div> */}
              </div>

              <div className="p-6">
                <TabView>
                  <TabPanel
                    header="Details"
                    rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                    
                  >
                    
                  <Accordion>
                  <AccordionTab
                    header="Personal Info"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >           
                  <Accordion>
                   { (userPayload?.user?.role!='connector') && <AccordionTab
                    header="Borrower's Personal Info"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg "
                  >            
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-row gap-4 p-10">

<div className="flex sm:flex-row items-center space-x-4">
  <label
    htmlFor="lan_no"
    className="text-lg font-semibold text-black w-1/3 sm:w-1/3 md:w-1/3"
  >
    Lan No.
  </label>
    {/* <img src="assets/firm.svg" alt="Firm Icon" className="h-5 w-5 mr-3" /> */}
    <input
      type="text"
      name="lan_no"
      value={formData.lan_no}
      onChange={(e: any) => {
        setFormData({ ...formData, lan_no: e.target.value });
      }}
      className={`border rounded-2xl w-full px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 border-gray-300 focus:ring-blue-500 ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}      placeholder="Enter Lan No."
      disabled={isEdit || disableField }
    />
</div>

{/* Contact Person Name */}
<div className="flex sm:flex-row items-center space-x-4">
  <label
    htmlFor="contact_person_name"
    className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3"
  >
    Contact Person Name
  </label>
    <input
      type="text"
      name="contact_person_name"
      onInput={handleNameInput}
      value={formData.borrower_contact_person_name}
      onChange={(e: any) => {
        setFormData({
          ...formData,
          borrower_contact_person_name: e.target.value,
        });
      }}
      className={`border rounded-2xl w-full px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 border-gray-300 focus:ring-blue-500 ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}  
          placeholder="Enter Contact Person Name"
      disabled={isEdit || disableField}
    />
</div>
<div className="flex sm:flex-row items-center space-x-4">
  <label
    htmlFor="contact_person_name"
    className={`text-lg font-semibold w-1/4 ${formData.mobile_error ? "text-red-500" : "text-black"
      } `}
  >
    Mobile No.
  </label>
    <input
      type="text"
      name="contact_person_name"
      maxLength={13}
      value={formData.borrower_contact_person_number}
      onChange={(e: any) => {
        setFormData({
          ...formData,
          borrower_contact_person_number: e.target.value,
          mobile_error: null,
        });
      }}
      className={`border rounded-2xl w-full px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 ${formData.mobile_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
      } ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
      placeholder="Enter Mobile No."
      disabled={isEdit || disableField}
    />
  <p className="text-red-500 text-sm ">{formData.mobile_error}</p>

</div>

  <div className="flex sm:flex-row items-center space-x-4">
    <label
      htmlFor="borrower_pincode"
      className={`text-lg font-semibold w-1/5 sm:w-1/3 md:w-1/3 ${formData.pincode_error ? "text-red-500" : "text-black"
      } `}
    >
      Borrower’s City Pincode<span className="text-red-500">*</span>
    </label>
      <input
        type="text"
        name="borrower_pincode"
        onInput={handleNumericInput}
        maxLength={6}
        value={formData.borrower_pincode}
        onChange={(e: any) => {
          postget(e);
        }}
        className={`border rounded-2xl w-full px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 ${formData.pincode_error ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-blue-500"
        } ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
               placeholder="Enter Borrower’s City Pincode"
        disabled={isEdit || disableField}
      />
    {formData.pincode_error && (
      <p className=" text-sm text-red-500">{formData.pincode_error}</p>
    )}
  </div>

  <div className="flex sm:flex-row items-center space-x-4">
    <label
      htmlFor="city"
      className="text-lg font-semibold text-black w-1/3"
    >
      City
    </label>
    
      <input
        type="text"
        name="city"
        value={formData.city}
        onChange={(e: any) => {
          setFormData({ ...formData, city: e.target.value });
        }}
        className={`border rounded-2xl w-full px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 border-gray-300 focus:ring-blue-500 ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}  
        placeholder="Enter City"
        disabled
      />
  </div>

  <div className="flex sm:flex-row items-center space-x-4">
    <label
      htmlFor="state"
      className="text-lg font-semibold text-black w-1/4"
    >
      State
    </label>
     <input
        type="text"
        name="state"
        value={formData.state}
        onChange={(e: any) => {
          setFormData({ ...formData, state: e.target.value });
        }}
        className={`border rounded-2xl w-full px-3 py-2 text-lg shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 border-gray-300 focus:ring-blue-500 ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}  
        placeholder="Enter State"
        disabled
      />
  </div>

  </div>


     </AccordionTab>}
                  </Accordion>
                  {(formData?.is_connector || (!isEdit && ((formData?.status === "Billing Received") || (formData?.status === "Payment Pending" && formData?.advance_status === "approved" ) || (formData?.dsa_invoice_id == null || formData?.connector_invoice_id == null))))  && <Accordion>
                  <AccordionTab
                    header="Connector Personal Info"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >          
                <div className="p-10 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-row gap-4 ">
<div className="flex sm:flex-row items-center space-x-4">
  <label
    htmlFor="lan_no"
    className={`text-lg font-semibold w-1/3 ${formData.connector_error ? "text-red-500" : "text-black"
    } `}  >
    Connector
  </label>
    <select
      name="connector"
      value={formData.connector_id}
      onChange={(e) =>
        setFormData({
          ...formData,
          connector_id: e.target.value,
          connector_error: null,
        })
      }
      disabled={isEdit }
      className={`border rounded-2xl w-full px-3 py-2 text-xl shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 ${formData.connector_error ? " border-red-500 focus:ring-red-500" : " border-gray-300 focus:ring-blue-500"
      } ${isEdit ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
         >
      <option value="">Select</option>
      {connectorList.map((y: any) => (
        <option value={y.id}>{y.label}</option>
      ))}
    </select>
</div>

{/* Contact Person Name */}
<div className="flex sm:flex-row items-center space-x-4">
  <label
    htmlFor="contact_person_name"
    className={`text-lg font-semibold w-1/3 ${formData.connector_rate_error ? " text-red-500" : " text-black"
    } `}  >
    Connector Rate
  </label>
    <input
      type="text"
      name="state"
      value={formData.connector_rate}
      onChange={(e) =>
        setFormData({
          ...formData,
          connector_rate: e.target.value,
          connector_rate_error: null,
        })
      }
      // max={formData.rate}
      disabled={isEdit}
      className={`border rounded-2xl w-full px-3 py-2 text-xl shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 ${formData.connector_rate_error ? " border-red-500 focus:ring-red-500" : " border-gray-300 focus:ring-blue-500"
      } ${isEdit ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
      />
</div>

</div>
                          </AccordionTab>
                  </Accordion> }               
                  
                         </AccordionTab>
                  </Accordion>
                  <Accordion>
                  <AccordionTab
                    header="Calculations"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >      
                  <Accordion>
                  {userPayload?.user?.role!='connector' &&<AccordionTab
                    header="Dsa"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >            
                                          <div className="grid grid-cols-1 gap-6">
                          <div className="p-10 grid lg:grid-cols-3 [800px]:grid-cols-1 sm:grid-cols-1 sm:grid-row gap-4  ">
                            {[
                              { label: "Gross payout", value: formData?.gross_payout },
                              { label: "Tds payout", value: formData?.tds_amount },
                              { label: "Net payout", value: formData?.net_amount_payable },
                              { label: "Grid Payout %", value: formData?.rate },
                              { label: "Subvention %", value: formData?.subvention_per },
                              { label: "Adjusted Payout %", value: formData?.adjusted_payout_per },
                              { label: "Gst payout", value: formData?.gst_amount },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className="flex sm:flex-row items-center space-x-4"
                              >
                                <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">{item.label}:</strong>
                                <input disabled className="border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 md:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500" value={item.value} />
                              </div>
                            ))}


                          <div className="flex sm:flex-row items-center space-x-4">
                            <label
                              htmlFor="lan_no"
                              className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3"
                            >
                              Subvention Amount
                            </label>
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.subvention}
                                onChange={(e: any) => {
                                  setFormData({ ...formData, subvention: e.target.value });
                                }}
                                className={`border rounded-2xl w-full px-3 py-2 text-xl shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 border-gray-300 focus:ring-blue-500 ${isEdit ||disableField ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                                placeholder="Enter Lan No."
                                disabled={((isEdit ) || (disableField)
                                )}
                              />
                          </div>
                          <div className="flex sm:flex-row items-center space-x-4">
                    <h3 className={`text-lg font-semibold  text-black w-1/3 `}>System Adjustment   :   </h3>
                    <input disabled className={` border rounded-2xl px-3 py-2 text-lg bg-white shadow-sm focus:outline-none focus:ring-2 w-full sm:w-1/3 border-gray-300 focus:ring-blue-500 bg-gray-50 text-gray-800  `} value={formData?.system_adjustment} />
                  </div>
                          <div className="flex sm:flex-row items-center space-x-4">
                            <label
                              htmlFor="lan_no"
                              className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3"
                            >
                              Adjustement Amount
                            </label>
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.adjustment_amount}
                                onChange={(e: any) => {
                                  setFormData({ ...formData, adjustment_amount: e.target.value });
                                }}
                                className={`border rounded-2xl w-full px-3 py-2 text-xl shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 border-gray-300 focus:ring-blue-500 ${(isEdit ) || (userPayload?.user?.role=='dsa' ) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                                placeholder="Enter Lan No."
                                disabled={(isEdit ) || (userPayload?.user?.role=='dsa' )}
                              />
                          </div>
                          <div className="flex flex-row sm:flex-row items-center space-x-4">
                            <label
                              htmlFor="lan_no"
                              className={`text-lg font-semibold text-black sm:w-1/3 md:w-1/3 ${formData.reason_error ? "text-red-500" : "text-gray-800"} w-1/5`}
                    >                            Reason
                            </label>
                            
                              <input
                                type="text"
                                name="lan_no"
                                value={formData.reason}
                                style={{
                                  width: "1000px", // Set desired width
                                }}
                              
                                onChange={(e: any) => {
                                  setFormData({ ...formData, reason: e.target.value,reason_error:null });
                                }}
                                className={`border rounded-2xl px-3 py-2 text-xl shadow-sm focus:outline-none focus:ring-2  ${formData.reason_error ? " border-red-500 focus:ring-red-500" : " border-gray-300 focus:ring-blue-500"
                                } ${(isEdit ) || (userPayload?.user?.role=='dsa' ) ? ' bg-gray-50 text-gray-500' : ' text-black bg-white'}`}
                                placeholder="Enter Reason"
                                disabled={(isEdit  || (userPayload?.user?.role=='dsa' )) }
                                />
                            
                              <p className="text-red-500 text-sm ">{formData.reason_error}</p>
                            
                          </div>
                          
                        </div>
                        </div>

                        </AccordionTab>}
                  </Accordion>
                  {formData?.is_connector &&<Accordion>
                  <AccordionTab
                    header="Connector"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >         
                  <div className="grid grid-cols-1 gap-6">
                          <div className="pr-10 pl-10 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-row gap-4  ">
                            {[
                              { label: "Gross payout", value: formData?.connector_gross_payout },
                              { label: "Tds payout", value: formData?.connector_tds_amount },
                              { label: "Net payout", value: formData?.connector_net_amount_payable },
                              { label: "Gst payout", value: formData?.connector_gst_amount },
                              { label: "Payout %", value: formData?.connector_rate },

                            ].map((item, index) => (
                              <div
                                key={index}
                                className="flex sm:flex-row items-center space-x-4"
                              >
                                <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">{item.label}:</strong>
                                <input disabled className="border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm focus:outline-none focus:ring-2 sm:w-1/3 md:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500" value={item.value}/>
                              </div>
                            ))}
                          </div>
                        </div>
                           </AccordionTab>
                  </Accordion>}
                              </AccordionTab>
                  </Accordion>
                  {(formData?.dsa_invoice_id || formData?.connector_invoice_id)  && <Accordion >
                  <AccordionTab
                    header="Invoice Info"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >
                         <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-row gap-4 p-10 ">
                         { formData?.dsa_invoice_id && userPayload?.user?.role!='connector' &&<><div
                              className="flex sm:flex-row items-center space-x-4"
                            >
                              <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">DSA Orenda Invoice No:</strong>
                              <input disabled className={`border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500`}
                                value={formData?.orenda_dsa_invoicenumber} />
                            </div><div
                              className="flex sm:flex-row items-center space-x-4"
                            >
                                <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">DSA Invoice No:</strong>
                                <input disabled className="border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500" value={formData?.dsa_invoicenumber} />

                                {formData?.dsa_invoice_path && <button
                                  onClick={() => {
                                    getDowloadProofLink(formData?.dsa_invoice_path);

                                  } }
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    padding: 0,
                                  }}
                                >
                                  Download
                                </button>}      </div></>}   
               { formData?.connector_invoice_id && <><div
                              className="flex sm:flex-row items-center space-x-4"
                            >
                              <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">Connector Orenda Invoice No:</strong>
                              <input disabled className={`border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500`}
                                value={formData?.orenda_connector_invoicenumber} />
                            </div><div
                              className="flex sm:flex-row items-center space-x-4"
                            >
                                <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">Connector Invoice No:</strong>
                                <input disabled className={`border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500`}
                                  value={formData?.connector_invoicenumber} />

                                {formData?.connector_invoice_path && <button
                                  onClick={() => {
                                    getDowloadProofLink(formData?.connector_invoice_path);

                                  } }
                                  style={{
                                    color: "blue",
                                    textDecoration: "underline",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    padding: 0,
                                  }}
                                >
                                  Download
                                </button>}
                              </div></>   }
                          
                        </div>
                  </AccordionTab>
                  </Accordion>}
                  </TabPanel>

                  { BankMisData  && (userPayload?.user?.role=='admin' || userPayload?.user?.role=='staff_operation'  || userPayload?.user?.role=='staff_sale') &&<TabPanel
                    header="Bank Details"
                    rightIcon="pi pi-building-columns ml-2"
                    headerClassName="border-b border-gray-200 text-lg bg-[#F5F5F5]"

                  >
                   
                                <div className="border border-gray-300 rounded-lg p-10 shadow-md mb-5 bg-[#F5F5F5]">
                        <div className="grid grid-cols-1 gap-6 bg-[#F5F5F5]">
                          <div className="pr-10 pl-10 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-row gap-4 bg-[#F5F5F5] ">
                            {[
                              { label: "Bank Name", value: BankMisData?.bank_name } ,
                             { label: "product", value: BankMisData?.product } ,
                              { label: "Customer name", value: BankMisData?.customer_name },
                              { label: "Loan amount", value: BankMisData?.loan_amount } ,
                              { label: "Month", value: BankMisData?.month },
                              { label: "Year", value: BankMisData?.year },
                              userPayload?.user?.role=='admin' ? { label: " Payout ", value: BankMisData?.payout } :null,
                              { label: "Subvention ", value: BankMisData?.subvention } ,
                            ].map((item, index) => item ? (
                              <div
                                key={index}
                                className="flex sm:flex-row items-center space-x-4"
                              >
                                <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">{item.label}:</strong>
                                <input disabled className="border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm w-full sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500" value={item.value}/>
                              </div>
                            ) : null)}


                          </div>


                        </div>
                      </div>
                      
                    
                  </TabPanel>}

                  {(ReverseMisData || ReverseMisConnectorData) &&<TabPanel
                    header="Orenda Dis. Details"
                    rightIcon="pi pi-home ml-2"
                    headerClassName="border-b border-gray-200 text-lg bg-[#F5F5F5]"
                  >
                    {ReverseMisData && userPayload?.user?.role!='connector' && <Accordion>
                  <AccordionTab
                    header=" Dsa Details"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >                                  { ReverseMisData && 
                  <div className="grid grid-cols-1 gap-6 bg-[#F5F5F5]">
                    <div className="pr-10 pl-10 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-row gap-4  ">
                      {[
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.file_sequence_num, label: 'File Sequence Number' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.pymt_prod_type_code, label: 'product Type Code' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.pymt_mode, label: 'Payment Mode' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.debit_acct_no, label: 'Debit Account Number' } : null,
        { value: ReverseMisData?.beneficiary_name, label: 'Beneficiary Name' },
        { value: ReverseMisData?.beneficiary_account_no, label: 'Beneficiary Account Number' },
        { value: ReverseMisData?.bene_ifsc_code, label: 'Beneficiary IFSC Code' },
        { value: ReverseMisData?.amount, label: 'Amount' },
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.debit_narration, label: 'Debit Narration' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.credit_narration, label: 'Credit Narration' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.mobile_number, label: 'Mobile Number' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.email_id, label: 'Email Id' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.remark, label: 'Remark' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.pymt_date, label: 'Payment Date' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.reference_no, label: 'Reference Number' } : null,
        // { value: ReverseMisData?.addl_info1, label: 'Additional Info1' },
        // { value: ReverseMisData?.addl_info2, label: 'Additional Info2' },
        // { value: ReverseMisData?.addl_info3, label: 'Additional Info3' },
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.addl_info4, label: 'Additional Info4' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.addl_info5, label: 'Additional Info5' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.status, label: 'Status' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.current_step, label: 'Step' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.file_name, label: 'File Name' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.rejected_by, label: 'Rejected By' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisData?.rejection_reason, label: 'Rejection Reason' } : null,
        { value: ReverseMisData?.acct_debit_date, label: 'Account Debit Date' },
        { value: ReverseMisData?.customer_ref_no, label: 'Customer Reference Number' },
        { value: ReverseMisData?.utr_no, label: 'UTR Number' },
    ].map((item, index) => item ? (
                        <div
                          key={index}
                          className="flex sm:flex-row items-center space-x-4"
                        >
                          <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">{item.label}:</strong>
                          <input disabled className="border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500" value={item.value}/>
                        </div>
                      ) : null)}


                    </div>


                </div> }    </AccordionTab>
                  </Accordion>}
                 {ReverseMisConnectorData && <Accordion>
                  <AccordionTab
                    header=" Connector Details"
                    // rightIcon="pi pi-list ml-2"
                    headerClassName="border-b border-gray-200 text-lg"
                  >                                  { ReverseMisConnectorData && 
                  <div className="grid grid-cols-1 gap-6 bg-[#F5F5F5] ">
<div className="pr-10 pl-10 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-row gap-4  ">
                      {[
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.file_sequence_num, label: 'File Sequence Number' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.pymt_prod_type_code, label: 'product Type Code' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.pymt_mode, label: 'Payment Mode' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.debit_acct_no, label: 'Debit Account Number' } : null,
        { value: ReverseMisConnectorData?.beneficiary_name, label: 'Beneficiary Name' },
        { value: ReverseMisConnectorData?.beneficiary_account_no, label: 'Beneficiary Account Number' },
        { value: ReverseMisConnectorData?.bene_ifsc_code, label: 'Beneficiary IFSC Code' },
        { value: ReverseMisConnectorData?.amount, label: 'Amount' },
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.debit_narration, label: 'Debit Narration' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.credit_narration, label: 'Credit Narration' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.mobile_number, label: 'Mobile Number' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.email_id, label: 'Email Id' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.remark, label: 'Remark' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.pymt_date, label: 'Payment Date' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.reference_no, label: 'Reference Number' } : null,
        // { value: ReverseMisData?.addl_info1, label: 'Additional Info1' },
        // { value: ReverseMisData?.addl_info2, label: 'Additional Info2' },
        // { value: ReverseMisData?.addl_info3, label: 'Additional Info3' },
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.addl_info4, label: 'Additional Info4' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.addl_info5, label: 'Additional Info5' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.status, label: 'Status' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.current_step, label: 'Step' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.file_name, label: 'File Name' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.rejected_by, label: 'Rejected By' } : null,
        userPayload?.user?.role=='admin' ? { value: ReverseMisConnectorData?.rejection_reason, label: 'Rejection Reason' } : null,
        { value: ReverseMisConnectorData?.acct_debit_date, label: 'Account Debit Date' },
        { value: ReverseMisConnectorData?.customer_ref_no, label: 'Customer Reference Number' },
        { value: ReverseMisConnectorData?.utr_no, label: 'UTR Number' },
    ].map((item, index) => item ? (
                        <div
                          key={index}
                          className="flex sm:flex-row items-center space-x-4"
                        >
                          <strong className="text-lg font-semibold text-black w-1/5 sm:w-1/3 md:w-1/3">{item.label}:</strong>
                          <input disabled className="border rounded-2xl px-3 py-2 text-xl bg-gray-50 shadow-sm sm:w-1/3 md:w-1/3 focus:outline-none focus:ring-2 sm:w-1/3 text-gray-500 border-gray-300 focus:ring-blue-500" value={item.value}/>
                        </div>
                      ) : null)}


                    </div>


                </div> }    </AccordionTab>
                  </Accordion>}
                  </TabPanel>}
                </TabView>
              </div>
              <div className="flex items-center gap-3 pt-5 pr-5 pl-5 pb-5">
              {
              userPayload?.user?.role!='connector' && <>
              {( ( userPayload?.user?.role=='dsa' && ( (formData?.status === "Payment Pending" && formData?.advance_request === false )))               
                || (  userPayload?.user?.role!='dsa'  && ((formData?.status === "Billing Received"  || formData?.status === "Mismatch Case"|| formData?.status === "Payment Pending" )  && (formData?.dsa_invoice_id == null && formData?.connector_invoice_id == null) && formData?.system_adjustment == 0.0 )) )
                && <> <Button label="Edit" icon="pi pi-pen-to-square" className=" text-white py-2 px-6 rounded-lg "
                    onClick={() =>{
                      
                      if ((formData?.status === "Billing Received") || (formData?.status === "Payment Pending" && formData?.advance_status === "approved" ) ) {
                        setDisableField(true);
                      }
                      setIsEdit(false) 
                     }                    
                    }
                    /></>}        
        </>}
                    
                 { !isEdit && <><Button label="Save" icon="pi pi-bookmark" className="  text-white py-2 px-6 rounded-lg "
                      onClick={() => editDisbursement()} />
                      <Button label="Cancel" icon="pi pi-times" severity="secondary" outlined className="py-2 px-6 rounded-lg"
                        onClick={() =>{ setIsEdit(true)
                          setFormData(oldData)}
                        } /></>
}
                  
                </div>
            </div>

          </div>

          <div className={`tw-modal ${open ? "flex" : "hidden"} !items-center`}>
            <div className="tw-modal-content !max-w-[500px]">
              <div className="tw-modal-title  ">
              <div>Advance Approve</div>
                <button onClick={() => setOpen(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>
              <div className="tw-modal-body leading-[24px] py-8">
                <span className="font-medium text-black">Please Note : </span>
                Advance payout grid for {item.bankname} & {item.product} is{" "}
                {item.payoutpercentage - 0.25}% .Your Gross Payout will be Rs.{" "}
                {item.gross_payout}. You cannot opt for NORMAL GRID after
                requesting advance. Please confirm?
              </div>

              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => setOpen(false)}
                >
                  No
                </button>
                <button
                  className="tw-modal-footer-button bg-secondary text-white"
                  onClick={(e) => {
                    actionYes();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
          <div
                      className={`tw-modal ${approvePayment ? "flex" : "hidden"} !items-start`}
                    >
                      <div className="tw-modal-content">
                        <div className="tw-modal-title">
                          <div>Add Payment Data</div>
                          <button
                            onClick={() => {
                              setApprovePayment(false);
                              setPaymentForm(oldData)
                            }}
                          >
                  <img src="assets/close-outline.svg" alt="" />
                  </button>
                        </div>
          
                        <div className="tw-modal-body">
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-[22px]">
                          
                              <div className="space-y-1">
                                <label htmlFor="file_sequence" className={`input-label ${
                                  paymentForm.file_sequence_num_error ? "text-red-500" : ""
                                }`}>
                                  File Sequence No.<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="file_sequence"
                                className={`input-wrap ${
                                  paymentForm.file_sequence_num_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="file_sequence"
                                  value={paymentForm.file_sequence_num}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      file_sequence_num: e.target.value,
                                      file_sequence_num_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter File sequence no."
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.file_sequence_num_error}
                              </p>
                              </div>
                              
                              <div className="space-y-1">
                                <label htmlFor="amount" className={`input-label ${
                                  paymentForm.amount_error ? "text-red-500" : ""
                                }`}>
                                  Amount<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="amount"
                                className={`input-wrap ${
                                  paymentForm.amount_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="amount"
                                  value={paymentForm.amount}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      amount: e.target.value,
                                      amount_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter Amount"
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.amount_error}
                              </p>
                              </div>
                              
                              <div className="space-y-1">
                                <label htmlFor="pymt_date" className={`input-label ${
                                  paymentForm.pymt_date_error ? "text-red-500" : ""
                                }`}>
                                  Payment Date<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="pymt_date"
                                className={`input-wrap ${
                                  paymentForm.pymt_date_error ? "border-red-500" : ""
                                }`}
                              ><img src="assets/date.svg" alt="" />
                              <DatePicker
                                selected={paymentForm.pymt_date}
                                onChange={(e: any) => {
                                  setPaymentForm({
                                    ...paymentForm,
                                    pymt_date: e,
                                    pymt_date_error: null,
                                  });
                                }}
                                dateFormat="dd/MM/yyyy"                
                                
                              />                      
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.pymt_date_error}
                              </p>
                              </div>
                              
                              <div className="space-y-1">
                                <label htmlFor="acct_debit_date" className={`input-label ${
                                  paymentForm.acct_debit_date_error ? "text-red-500" : ""
                                }`}>
                                  Account Debit Date<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="acct_debit_date"
                                className={`input-wrap ${
                                  paymentForm.acct_debit_date_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/date.svg" alt="" />
                                <DatePicker
                                  selected={paymentForm.acct_debit_date}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      acct_debit_date: e,
                                      acct_debit_date_error: null,
                                    });
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                  
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.acct_debit_date_error}
                              </p>
                              </div>
                              <div className="space-y-1">
                                <label htmlFor="customer_ref_no" className={`input-label ${
                                  paymentForm.customer_ref_no_error ? "text-red-500" : ""
                                }`}>
                                  Customer Reference No.<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="customer_ref_no"
                                className={`input-wrap ${
                                  paymentForm.customer_ref_no_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="customer_ref_no"
                                  value={paymentForm.customer_ref_no}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      customer_ref_no: e.target.value,
                                      customer_ref_no_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter Customer Ref. No."
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.remark_error}
                              </p>
                              </div>
                              <div className="space-y-1">
                                <label htmlFor="utr_no" className={`input-label ${
                                  paymentForm.utr_no_error ? "text-red-500" : ""
                                }`}>
                                  UTR No.<span style={{color:'red'}}>*</span>
                                </label>
                                <div
                                id="utr_no"
                                className={`input-wrap ${
                                  paymentForm.utr_no_error ? "border-red-500" : ""
                                }`}
                              >
                                <img src="assets/user.svg" alt="" />
                                <input
                                  type="text"
                                  name="utr_no"
                                  value={paymentForm.utr_no}
                                  onChange={(e: any) => {
                                    setPaymentForm({
                                      ...paymentForm,
                                      utr_no: e.target.value,
                                      utr_no_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="Enter UTR No."
                                />
                              </div>
                                <p className="text-red-500 text-sm">
                                {paymentForm.utr_no_error}
                              </p>
                              </div>
                          </div>
                        </div>
          
                        <div className="tw-modal-footer">
                          <button
                            className="tw-modal-footer-button bg-secondary text-white"
                            onClick={()=>{
                              // addPaymentData();
                                setApprovePayment(false);
                                setPaymentForm(oldData)
                              
                            }}
                          >
                            Submit
                          </button>
                          <button
                            className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                            onClick={() => {
                              setApprovePayment(false);
                              setPaymentForm(oldData)
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                   
                    <div
            className={`tw-modal ${viewProof ? "flex" : "hidden"} !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Advance Request Proof</div>
                <button
                  onClick={() => {
                    setViewProof(false)
                    setProofPath('')
                  }}
                >
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                
               {formData?.advance_proof_path?.split(".").pop() == "pdf" ?
                       
                        <iframe
                          src={`data:application/pdf;base64,${proofPath}`}
                          title="Preview PDF"
                          width="100%"
                          height="1000px"
                        />
                       : 
                        <img
                          style={{ height: "500px", width: "500px" }}
                          src={`data:image/jpeg;base64,${proofPath}`}
                          className="ml-5 object-center object-cover"
                        />          

               }
              </div>
              <div className="tw-modal-footer">
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setViewProof(false)
                    setProofPath('')
                  }}
                >
                  Close
                </button>

              </div>
             </div>
          </div>
                    <div className={`tw-modal ${sendMailDialog ? "flex" : "hidden"}`}>
                        <div className="tw-modal-content">
                          <div className="tw-modal-title">
                            <div>Send Mail for Disbursal confirmation</div>
                            <button onClick={() => {setSendMailDialog(false)
                              setEmailData(email_data)
                            }}>
                              <img src="assets/close-outline.svg" alt="" />
                            </button>
                          </div>
          
                          <div className="tw-modal-body py-6">
                            <div className="d-flex">
                              <div className="space-y-1">
                                <label
                                  htmlFor="normal_payout"
                                  className={`input-label ${emailData.sm_manager_email_error ? "text-red-500" : ""}`}
                                >SM Email ID<span style={{color:'red'}}>*</span></label>
                                <div
                                  id="normal_payout"
                                  className={`input-wrap ${emailData.sm_manager_email_error ? "border-red-500" : ""}`}
                                >
                                  <input
                                    type="text"
                                    name="normal_payout"
                                    value={emailData.sm_manager_email}
                                    className="w-full text-sm"
                                    placeholder="Enter Mail"
                                    onChange={(e:any)=>{
                                     setEmailData({...emailData,sm_manager_email:e.target.value,sm_manager_email_error:null})
                                    }}
                                  />
                                </div>
                                <p className="text-red-500 text-sm">
                                  {emailData.sm_manager_email_error}
                                </p>
                              </div>
                              <div className="space-y-1">
                                <label
                                  htmlFor="normal_payout"
                                  className={`input-label ${emailData.asm_manager_email_error ? "text-red-500" : ""}`}
                                >ASM / Reporting Manager Email ID(optional)</label>
                                <div
                                  id="normal_payout"
                                  className={`input-wrap ${emailData.asm_manager_email_error ? "border-red-500" : ""}`}
                                >
                                  <input
                                    type="text"
                                    name="normal_payout"
                                    value={emailData.asm_manager_email}
                                    className="w-full text-sm"
                                    placeholder="Enter Mail"
                                    onChange={(e:any) => {
                                      setEmailData({...emailData,asm_manager_email:e.target.value,asm_manager_email_error:null})}
                                    }
                                  />
                                </div>
                                <p className="text-red-500 text-sm">
                                  {emailData.asm_manager_email_error}
                                </p>
                              </div>
                              
                            </div>
                          </div>
                         
                          <div className="tw-modal-footer">
                          <Button
                            icon="pi pi-check"
                            raised
                            severity="info"
                            onClick={() => {
                              sendMail()
                            }}
                          >
                            <span style={{ marginLeft: "8px" }}> Submit</span>
                          </Button>
                          <Button
                            icon="pi pi-times"
                            raised
                            severity="danger"
                            onClick={() => {
                              setSendMailDialog(false)
                              setEmailData(email_data)
                            }}
                          >
                            {" "}
                            <span style={{ marginLeft: "8px" }}> Cancel</span>
                          </Button>
                        </div>
                          
                        </div>
                      </div>
                    <ConfirmDialog
                      // group="declarative"
                      visible={deleteConfirmVisible}
                      onHide={() => setDeleteConfirmVisible(false)}
                      message="Are you sure you want to delete?"
                      header="Confirmation"
                      icon="pi pi-exclamation-triangle"
                      accept={accept}
                      reject={reject}
                      style={{ width: "50vw" }}
                      breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
                    />
        </>
      )}</div>)
}

export default DisbursementDetailsModal
