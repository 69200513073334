import { isArray } from "lodash";
import { API } from "../config/API/api.config"
import { jwtDecode } from "jwt-decode";

export const isEmpty = (value: any) => {
    if (value == null) {
        return true;
    }
    if (typeof value == "object") {
        return Object.keys(value).length == 0;
    }
    return (
        (isArray(value) && value.length == 0)
        || ((value == undefined || value == null || value == ''))
    )
}

export const getImageUrl = (url: string) => {
    return isEmpty(url) ? "" : API.hostUrl + url
}

export const getImageUrl2 = (url: string) => {
    return isEmpty(url) ? "" : API.hostUrl2 + url
}

export const getSlabValue = (row: any, type: string) => {
    if (isEmpty(row?.select)) {
        return "0";
    }
    if (row?.select == "variable_slab") {
        return type == "normal" ? row?.payout_grid?.[0]?.normal_payout_grid : row?.payout_grid?.[0]?.advance_payout_grid
    }

    if (row?.select == "flat_slab") {
        return type == "normal" ? row?.normal_payout_slab : row?.advance_payout_slab
    }
}

export const numberFormat = (number: any, currency: string = 'INR') => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
    }).format(number);
}

export const decodeToken = (token :string):Object=>{
    //my code here 
    let c:object = {token};
    type UserPayload = {
        user: {
          _id: string,
          email: string,
          mobile: string,
          profile_completed: boolean,
          referral_code: string,
          department: string | null,
          relationship_manager: string | null,
          role: string
        },
        expires: number,
        iat : number
      }
      token = token?.replace("Bearer","");
      token = token?.trim()
      let decodedToken
      if(token){
         decodedToken = jwtDecode<UserPayload>(token);
      }
    return decodedToken;
}